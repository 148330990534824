import Home from "../Header/Home";
// import Join from "../Join";
// import Join2 from "../Join2";
// import Join3 from "../Join3";
// import Join4 from "../Join4";
// import Join5 from "../Join5";
// import Join6 from "../Join6";
// import Join7 from "../Join7";
// import Join8 from "../Join8";
// import Join9 from "../Join9";
// import JoinFinish from "../JoinFinish";
import Signup from "../Registration/Signup";
// import Companies from "../Companies";
import marketing from "../Registration/marketing";
import Platform from "../Registration/Platform";
import Industry from "../Registration/Industry";
import Services from "../Registration/Services";
import Lifestyle from "../Registration/Lifestyle";
import Agegroup from "../Registration/Agegroup";
import Range from "../Registration/Range";
import Completed from "../Registration/Completed";
// import Companies2 from "../companies2";
import Landingscreen from "../Landingscreen";
import Questionary from "../Questionary/Home";
import Internetmarketing from '../Registration/Internetmarketing';
import Privacypolicy from "../Fotter/Privacy policy";
import TermsofServices from "../Fotter/Terms of services";
import Area from "../Registration/Area";
import Disclaimer from "../Fotter/Disclaimer";
import Media from "../Header/Media";
import About from "../Header/About";
import Leadlogin from "../Leads/Leadlogin";
// import Business from "../Business";
import Contact from "../Header/Contact";
import ForgotPassword from "../Forgotpassword/ForgotPassword";
import CofirmForgotPassword from "../Forgotpassword/CofirmForgotPassword";
import Myleads from "../Leads/Myleads";
import Leadspayment from "../Leads/Leadspayment";
import Leadscontact from "../Leads/Leadscontact";
import Blog1 from "../Header/Blog1";
import Blog2 from "../Header/Blog2";
import Blog3 from "../Header/Blog3";

const AppRoutes = [
  {
    key: "001",
    exact: true,
    path: "/",
    name: "Home",
    component: Home,
  },
  // {
  //   key: "002",
  //   exact: true,
  //   path: "/app/Join",
  //   name: "Join",
  //   component: Join,
  // },
  // {
  //   key: "004",
  //   exact: true,
  //   path: "/app/Join2",
  //   name: "Join2",
  //   component: Join2,
  // },
  // {
  //   key: "005",
  //   exact: true,
  //   path: "/app/Join3",
  //   name: "Join3",
  //   component: Join3,
  // },
  // {
  //   key: "006",
  //   exact: true,
  //   path: "/app/Join4",
  //   name: "Join4",
  //   component: Join4,
  // },
  // {
  //   key: "007",
  //   exact: true,
  //   path: "/app/Join5",
  //   name: "Join5",
  //   component: Join5,
  // },
  // {
  //   key: "008",
  //   exact: true,
  //   path: "/app/Join6",
  //   name: "Join6",
  //   component: Join6,
  // },
  // {
  //   key: "009",
  //   exact: true,
  //   path: "/app/Join7",
  //   name: "Join7",
  //   component: Join7,
  // },
  // {
  //   key: "0010",
  //   exact: true,
  //   path: "/app/Join8",
  //   name: "Join8",
  //   component: Join8,
  // },
  // {
  //   key: "0011",
  //   exact: true,
  //   path: "/app/Join9",
  //   name: "Join9",
  //   component: Join9,
  // },
  // {
  //   key: "0012",
  //   exact: true,
  //   path: "/app/JoinFinish",
  //   name: "JoinFinish",
  //   component: JoinFinish,
  // },
  {
    key: "0013",
    exact: true,
    path: "/app/register",
    name: "Signup",
    component: Landingscreen,
  },{
    key: "0024",
    exact: true,
    path: "/app/questionary",
    name: "Signup",
    component: Questionary,
  },
  // {
  //   key: "0014",
  //   exact: true,
  //   path: "/app/Companies",
  //   name: "Companies",
  //   component: Companies,
  // },
  {
    key: "0015",
    exact: true,
    path: "/app/Marketing",
    name: "Marketing",
    component: marketing,
  },
  {
    key: "0016",
    exact: true,
    path: "/app/Platform",
    name: "Platform",
    component: Platform,
  },
  {
    key: "0017",
    exact: true,
    path: "/app/Industry",
    name: "Industry",
    component: Industry,
  },
  {
    key: "0018",
    exact: true,
    path: "/app/Services",
    name: "Services",
    component: Services,
  },
  {
    key: "0019",
    exact: true,
    path: "/app/Lifestyle",
    name: "Lifestyle",
    component: Lifestyle,
  },
  {
    key: "0020",
    exact: true,
    path: "/app/Agegroup",
    name: "Agegroup",
    component: Agegroup,
  },
  {
    key: "0021",
    exact: true,
    path: "/app/Range",
    name: "Range",
    component: Range,
  },
  {
    key: "0022",
    exact: true,
    path: "/app/Completed",
    name: "Range",
    component: Completed,
  },
  // {
  //   key: "0023",
  //   exact: true,
  //   path: "/app/Companies2",
  //   name: "Companies2",
  //   component: Companies2,
  // },
  {
    key: "0024",
    exact: true,
    path: "/app/Internetmarketing",
    name: "Internetmarketing",
    component: Internetmarketing,
    
  },
  {
    key: "0025",
    exact: true,
    path: "/app/Area",
    name: "Area",
    component: Area,
    
  },

  {
    key: "0026",
    exact: true,
    path: "/app/Privacypolicy",
    name: "Privacypolicy",
    component: Privacypolicy,
  },
  {
    key: "0027",
    exact: true,
    path: "/app/TermsofServices",
    name: "TermsofServices",
    component: TermsofServices,
  },
  {
    key: "0028",
    exact: true,
    path: "/app/Disclaimer",
    name: "Disclaimer",
    component: Disclaimer,
  },
  {
    key: "0029",
    exact: true,
    path: "/app/About",
    name: "About",
    component: About,
  },
  {
    key: "0030",
    exact: true,
    path: "/app/Contact",
    name: "Contact",
    component: Contact,
  },
  {
    key: "0031",
    exact: true,
    path: "/app/Media",
    name: "Media",
    component: Media,
  },
  {
    key: "0032",
    exact: true,
    path: "/app/Leadlogin",
    name: "Leadlogin",
    component: Leadlogin,
  },
  {
    key: "0033",
    exact: true,
    path: "/app/ForgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
{
  key: "0034",
    exact: true,
    path: "/app/CofirmForgotPassword",
    name: "CofirmForgotPassword",
    component: CofirmForgotPassword,
},
{
  key:"0035",
  exact: true,
    path: "/app/Myleads",
    name: "Myleads",
    component: Myleads,
},
{
  key:"0036",
  exact: true,
    path: "/app/Leadspayment/:id",
    name: "Leadspayment",
    component: Leadspayment,
},
{
  key:"0037",
  exact: true,
    path: "/app/Leadscontact/:id",
    name: "Leadscontact",
    component: Leadscontact,
},
{
  key:"0038",
  exact: true,
    path: "/app/ConnecticutSmallBussiness",
    name: "Blog1",
    component: Blog1,
},
{
  key:"0039",
  exact: true,
    path: "/app/MediaMarketing",
    name: "Blog2",
    component: Blog2,
},
{
  key:"0040",
  exact: true,
    path: "/app/Score",
    name: "Blog3",
    component: Blog3,
}
  // {
  //   key: "0032",
  //   exact: true,
  //   path: "/app/Business",
  //   name: "Business",
  //   component: Business,
  // },
];
export default AppRoutes;
