import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Select,  Checkbox } from "antd";


class Step8 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteRedio: true,
      isdeselect: false,
      checked: false,
      selected: "",
      // step3: [
      //  "yes",
      //  "no"
      // ],
    };
  }

  // onSelectChange = (e,data) => {
  //   let selected = this.state.selected.length > 0 ? this.state.selected : [];
  //   selected.push(data.target.value);
  //   // this.props.setFieldsData(8, "referralContact", e.target.value);
  //   this.setState({
  //     selected: e.target.value,
  //   });
  //   console.log(selected);
  // };

  componentDidMount() {
    const { formData } = this.props;

    this.setState({
      checked: formData.information,
    });
  }

  handleSubmit = (event) => {  
  // <Col span={12}>
  // <Form.Item className="Formitem">
  //   {/* <Link to="/app/Companies"> */}
  //   <Button
  //     key="1"
  //     loading={this.state.loading}
  //     htmlType="submit"
  //     type="primary"
  //     style={{
  //       borderRadius: "50px",
  //       backgroundColor: "#dce7f0",
  //       color: "#004b84",
  //       width: "150px",
  //       border: "none",
  //       // marginLeft: "4%",
  //       fontWeight: "bold",
  //       height: "40px",
  //     }}
  //   >
  //     Finishhhhhhhhh
  //   </Button>
  //   {/* </Link> */}
  // </Form.Item>
  //  </Col>
    // const { formData } = this.props;
    const { checked } = this.state;
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          agreement: values.agreement,
        });
        // console.log(this.state.checked);

        if (!checked) {
          alert("To continue please check mark the permission box.");
          return;
        }
        let data = {
          preferredContact: values["preferredContact"],
          referralContact: this.state.selected,
          information: checked,
         // information: values["information"]
        };

        if (this.props.formData.id) {
          localStorage.setItem("Step8", JSON.stringify(this.props.formData.id));
          this.props.updateData(data);
        } else {
          this.props.doRegister(data);
        }

        // this.addUser(values["email"], data);
      }
    });

    // if (this.props.formData.marketing === undefined || this.props.formData.marketing.length === 0) {
    //   alert("Please select your options!");
    //   return;
    // }
    // this.props.updateData({"referralContact": this.props.formData.referralContact});
  };

  onItemCheck = (e) => {
    // console.log(e);
    const { checked } = e.target;
    this.setState({
      checked,
    });
  };

  render() {
    const { Option } = Select;
    const { getFieldDecorator } = this.props.form;
    // const { TextArea } = Input;
    const { formData } = this.props;
    const { checked } = this.state;

    return (
      <Row>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col span={24}>
            <div style={{ textAlign: "center", marginTop: "100px" }}>
              <h1 style={{ fontWeight: "bold" }}>
                What is your preferred method of contact
              </h1>
            </div>
          </Col>

          <Col
          lg={{ span: 17 , offset:7}}
          xl={{ span: 9, offset: 9 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }} > 
            <Form.Item
              className="Formitem"
              // style={{ marginLeft: "75%" }}
              name="preferredContact"
            >
              {getFieldDecorator("preferredContact", {
                rules: [
                  {
                    required: true,
                    message: "Please input !",
                  },
                ],
                initialValue: formData.preferredContact,
              })(
                <Select
                className="ant-select-selection--single"
                >
                  <Option value="E-Mail">E-Mail</Option>
                  <Option value="Phonecall">Phone Call</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item className="Formitem">
              <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
                The matched referrals will contact you through your preferred
                method of contact.
              </h2>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item className="Formitem" style={{ marginLeft: "39%" }}>
              {getFieldDecorator("information", {
                rules: [
                  {
                    required: false,
                    message: "Please input !",
                  },
                ],
                // initialValue:formData.information,
              })(
                <Checkbox
                  onChange={this.onItemCheck}
                  className="checbox-step8"
                  // style={{ marginLeft: "71%" }}
                  // required
                  checked={checked}
                ></Checkbox>
                // <input type="checkbox" id="myCheck" name="test" required/>
              )}
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item className="Formitem">
              <p style={{ fontWeight: "bold", marginRight: "10%" }}>
                You give us permission to share your business and contact
                information with matching media outlets
              </p>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="Previous"
                type="primary"
                onClick={() => this.props.back()}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                  marginBottom:"80px"
                }}
              >
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              {/* <Link to="/app/Companies"> */}
              <Button
                key="1"
                loading={this.state.loading}
                htmlType="submit"
                type="primary"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  // marginLeft: "4%",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Finish
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Form.create({ name: "Step8" })(Step8);
