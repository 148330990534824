import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Select, Input } from "antd";
import { Radio } from "antd";
// import { DownOutlined } from "@ant-design/icons";

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteRedio: true,
      isdeselect: false,
      selected1: false,
      pastMarket: [
        "A/B Testing",
        "Billboards",
        "Blogging or Content Management",
        "CTV/OTT",
        "Commuter Rail",
        "Conversion rates",
        "Digital Poster",
        "Digital Radio",
        "Digital Bulletins",
        "Dashbords and Analytics",
        "Direct Mail",
        "Digital Display(excluding podcast)",
        "Ecommerce and Point-of-sale",
        "Email Marketing",
        "Event Management",
        "Flyers",
        "Lead Management",
        "Landing Pages",
        "Live Chat",
        "Liner TV",
        "Mobile Marketing",
        "Mobile Digital",
        "Mobile Billboards",
        "Magazines",
        "Newspaper",
        "Print",
        "Podtcast",
        "Radio",
        "Restaurant Placemats",
        "Surveys",
        "Sheets",
        "SEM",
        "Social Media",
        "Web Forms",
        "Websities",
      ],
    };
  }

  handleClick = (e) => {
    let pastMarket = this.props.formData.pastMarket;
    let value = e.target.value;
    if (pastMarket) {
      if (pastMarket.indexOf(value) > -1) {
        this.props.setFieldsData(3, "pastMarket", value);
      }
    }
  };
  onSelectChange = (e) => {
    this.props.setFieldsData(3, "pastMarket", e.target.value);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          pastMarket: values.pastMarket,
        });
        // console.log( values,"pastMarke", this.props.formData,"formdataaa")
        let business = this.state.selected1;

        let data = {
          business: business,
          pastMarket: this.props.formData["pastMarket"],
          advertising: values["advertising"],
          objectives: values["objectives"],
          investment: values["investment"],
          advertise: values["advertise"],
          step3yes: !this.props.formData.step3no ? true : false,
          step3no: !this.props.formData.step3yes ? true : false,
          meetyes: !this.props.formData.meetno ? true : false,
          meetno: !this.props.formData.meetyes ? true : false,
          invesyes: !this.props.formData.invesno ? true : false,
          invesno: !this.props.formData.invesyes ? true : false,
        };

        if (this.props.formData.id) {
          this.props.updateData(data );
        } else {
          this.props.doRegister(data);
        }
        console.log("data", data.pastMarket);
        // this.addUser(values["email"], data);
      }
    });
  };

  handleRedioMarketing = (data) => {
    let checkValue = this.props.formData.pastMarket?.includes(data);
    return (
      <div>
        <label>
          <input
            name={data}
            type="checkbox"
            value={data}
            onChange={this.onSelectChange}
            onClick={this.handleClick}
            className="visually-hidden"
            checked={checkValue}
          />
          <div className="btn">{data}</div>
        </label>
      </div>
    );
  };
  handleClick2 = () => {
    const { formData } = this.props;
    if (formData.id) {
      let business = this.state.selected1;

      let data = {
        business: business,
        pastMarket: formData["pastMarket"],
        advertising: formData["advertising"],
        objectives: formData["objectives"],
        investment: formData["investment"],
        advertise: formData["advertise"],
        step3yes: true,
        step3no: false,
        // meetyes:true,
        // meetno: false,
      };
      this.props.updateData(data, "yes");
    } else {
      formData["step3yes"] = true;
      formData["step3no"] = false;
      // formData["meetyes"]= true
      // formData["meetno"] = false
      this.props.doRegister(formData, "yes");
    }
    this.setState({
      selected1: true,
    });
  };
  handleClick3 = () => {
    const { formData } = this.props;
    if (formData.id) {
      let business = this.state.selected1;

      let data = {
        business: business,
        pastMarket: formData["pastMarket"],
        advertising: formData["advertising"],
        objectives: formData["objectives"],
        investment: formData["investment"],
        advertise: formData["advertise"],
        step3no: true,
        step3yes: false,
        // meetyes:true,
        // meetno: false,
      };
      this.props.updateData(data, "yes");
    } else {
      formData["step3no"] = true;
      formData["step3yes"] = false;
      // formData["meetno"] = true
      // formData["meetyes"]= false
      this.props.doRegister(formData, "yes");
    }
    this.setState({
      selected1: false,
    });
  };
  handleClick4 = () => {
    const { formData } = this.props;
    if (formData.id) {
      let business = this.state.selected1;

      let data = {
        business: business,
        pastMarket: formData["pastMarket"],
        advertising: formData["advertising"],
        objectives: formData["objectives"],
        investment: formData["investment"],
        advertise: formData["advertise"],
        // step3yes:true,
        // step3no:false,
        meetyes: true,
        meetno: false,
      };
      this.props.updateData(data, "yes");
    } else {
      // formData["step3yes"] = true
      // formData["step3no"] = false
      formData["meetyes"] = true;
      formData["meetno"] = false;
      this.props.doRegister(formData, "yes");
    }
    this.setState({
      selected1: true,
    });
  };
  handleClick5 = () => {
    const { formData } = this.props;
    if (formData.id) {
      let business = this.state.selected1;

      let data = {
        business: business,
        pastMarket: formData["pastMarket"],
        advertising: formData["advertising"],
        objectives: formData["objectives"],
        investment: formData["investment"],
        advertise: formData["advertise"],
        // step3no:true,
        // step3yes:false,
        meetyes: false,
        meetno: true,
      };
      this.props.updateData(data, "yes");
    } else {
      // formData["step3no"] = true
      // formData["step3yes"] = false
      formData["meetno"] = true;
      formData["meetyes"] = false;
      this.props.doRegister(formData, "yes");
    }
    this.setState({
      selected1: false,
    });
  };
  handleClick6 = () => {
    const { formData } = this.props;
    if (formData.id) {
      let business = this.state.selected1;

      let data = {
        business: business,
        pastMarket: formData["pastMarket"],
        advertising: formData["advertising"],
        objectives: formData["objectives"],
        investment: formData["investment"],
        advertise: formData["advertise"],
        // step3yes:true,
        // step3no:false,
        invesyes: true,
        invesno: false,
      };
      this.props.updateData(data, "yes");
    } else {
      // formData["step3yes"] = true
      // formData["step3no"] = false
      formData["invesyes"] = true;
      formData["invesno"] = false;
      this.props.doRegister(formData, "yes");
    }
    this.setState({
      selected1: true,
    });
  };
  handleClick7 = () => {
    const { formData } = this.props;
    if (formData.id) {
      let business = this.state.selected1;

      let data = {
        business: business,
        pastMarket: formData["pastMarket"],
        advertising: formData["advertising"],
        objectives: formData["objectives"],
        investment: formData["investment"],
        advertise: formData["advertise"],
        // step3no:true,
        // step3yes:false,
        invesyes: false,
        invesno: true,
      };
      this.props.updateData(data, "yes");
    } else {
      // formData["step3no"] = true
      // formData["step3yes"] = false
      formData["invesno"] = true;
      formData["invesyes"] = false;
      this.props.doRegister(formData, "yes");
    }
    this.setState({
      selected1: false,
    });
  };

  render() {
    const { Option } = Select;
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const { formData } = this.props;
    console.log(formData, "aasdf");
    return (
      <Row>
        <Col span={24}>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <h1 style={{ fontWeight: "bold" }}>
              Are you currently or have previously invested in advertising?
            </h1>
          </div>
        </Col>
        <Col span={24}>
          <center>
            <Form.Item className="Formitem">
              <Radio.Group
                defaultValue="5"
                buttonStyle="solid"
                // style={{ marginLeft: "39%" }}
              >
                <Radio.Button
                  value="a"
                  onClick={this.handleClick2}
                  style={{ marginRight: "10px" }}
                  checked={formData?.step3yes ? true : false}
                >
                  Yes
                </Radio.Button>
                <Radio.Button
                  value="b"
                  onClick={this.handleClick3}
                  style={{ marginLeft: "10px" }}
                  checked={formData?.step3no ? true : false}
                >
                  No
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </center>
        </Col>

        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          {formData?.step3yes ? (
            <>
              <Col span={24}>
                <Form.Item className="Formitem">
                  <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
                    What type of marketing and advertising are you doing or have
                    you done in the past?
                    <h6 style={{ color: "gray", textAlign: "center" }}>
                      select 1 or more
                    </h6>
                  </h2>
                </Form.Item>
              </Col>

              <Col lg={12} md={12} sm={24} xs={24} className="service-step3">
                <Form.Item className="Formitem">
                  {this.state.pastMarket?.map((data) => (
                    <div
                      style={{
                        width: "max-content",
                        float: "left",
                        marginRight: "10px",
                      }}
                    >
                      {this.handleRedioMarketing(data)}
                    </div>
                  ))}
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="Formitem">
                  <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
                    Why are you doing that kind of advertising or marketing?
                  </h2>
                </Form.Item>
                <center>
                  <Form.Item className="Formitem">
                    {getFieldDecorator("advertising", {
                      rules: [
                        {
                          required: true,
                          message: "Please input !",
                        },
                      ],
                      initialValue: formData.advertising,
                    })(
                      <TextArea
                        className="text_areabox"
                        style={{
                          borderRadius: "0px",
                          height: "104px",
                          padding: "10px",
                        }}
                        // value={formData.advertising}
                        placeholder="Please briefly explain"
                      />
                    )}
                  </Form.Item>
                </center>
              </Col>

              <Col span={24}>
                <Form.Item className="Formitem">
                  <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
                    Did the advertising meet your marketing objectives? If yes,
                    how?
                  </h2>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Col span={24}>
                  <center>
                    <Form.Item className="Formitem">
                      <Radio.Group
                        defaultValue="5"
                        buttonStyle="solid"
                        // style={{ marginLeft: "45%" }}
                      >
                        <Radio.Button
                          value="a"
                          onClick={this.handleClick4}
                          style={{ marginRight: "10px" }}
                          checked={formData?.meetyes ? true : false}
                        >
                          Yes
                        </Radio.Button>
                        <Radio.Button
                          value="b"
                          onClick={this.handleClick5}
                          style={{ marginLeft: "10px" }}
                          checked={formData?.meetno ? true : false}
                        >
                          No
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </center>
                </Col>
                {formData?.meetyes ? (
                  <Col span={24}>
                    <center>
                      <Form.Item className="Formitem">
                        {getFieldDecorator("objectives", {
                          rules: [
                            {
                              required: true,
                              message: "Please input !",
                            },
                          ],
                          initialValue: this.props.formData.objectives,
                        })(
                          <TextArea
                            className="text_areabox"
                            style={{
                              // textAlign: "center" ,
                              borderRadius: "0px",
                              // width: "28%",
                              height: "104px",
                              padding: "10px",
                            }}
                            placeholder="Please briefly explain"
                          />
                        )}
                      </Form.Item>
                    </center>
                  </Col>
                ) : (
                  ""
                )}
              </Col>

              <Col span={24}>
                <Form.Item className="Formitem">
                  <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
                    Did you see a return on your investment? If not, why not?
                  </h2>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Col span={24}>
                  <center>
                    <Form.Item className="Formitem">
                      <Radio.Group defaultValue="5" buttonStyle="solid">
                        <Radio.Button
                          value="a"
                          onClick={this.handleClick6}
                          style={{ marginRight: "10px" }}
                          checked={formData?.invesyes ? true : false}
                        >
                          Yes
                        </Radio.Button>
                        <Radio.Button
                          value="b"
                          onClick={this.handleClick7}
                          style={{ marginLeft: "10px" }}
                          checked={formData?.invesno ? true : false}
                        >
                          No
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </center>
                </Col>
                {formData?.invesno ? (
                  <Col span={24}>
                    <center>
                      <Form.Item className="Formitem">
                        {getFieldDecorator("investment", {
                          rules: [
                            {
                              required: true,
                              message: "Please input !",
                            },
                          ],
                          initialValue: formData.objectives,
                        })(
                          <TextArea
                            className="text_areabox"
                            style={{
                              // textAlign: "center" ,
                              borderRadius: "0px",
                              // width: "28%",
                              height: "104px",
                              padding: "10px",
                            }}
                            placeholder="Please briefly explain"
                          />
                        )}
                      </Form.Item>
                    </center>
                  </Col>
                ) : (
                  ""
                )}
              </Col>

              <Col
                lg={{ span: 10, offset: 7 }}
                xl={{ span: 10, offset: 9 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
              >
                <h2 className="textView">How frequently do you advertise?</h2>
                <Form.Item className="Formitem">
                  {getFieldDecorator("advertise", {
                    rules: [
                      {
                        required: true,
                        message: "Please input !",
                      },
                    ],
                    initialValue: formData.advertise,
                  })(
                    <Select className="input_width">
                      <Option value="Weekly">Weekly</Option>
                      <Option value="Bi-Weekly">Bi-Weekly</Option>
                      <Option value="Monthly">Monthly</Option>
                      <Option value="Quarterly">Quarterly</Option>
                      <Option value="Annually">Annually</Option>
                      <Option value="Not Very Often">Not Very Often</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </>
          ) : (
            ""
          )}
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="Previous"
                type="primary"
                onClick={() => this.props.back()}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                  marginBottom: "80%",
                }}
              >
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="next"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  // marginLeft: "4%",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Form.create({ name: "Step3" })(Step3);
