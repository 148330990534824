import React, { Component } from "react";
// import {
//   Card,
//   CardBody,
//   CardGroup,
//   Col,
//   Container,
//   Input,
//   InputGroup,
//   InputGroupAddon,
//   InputGroupText,
//   Row,
//   Tooltip,
// } from "reactstrap";
import { Form, Input, Button,Col, Row, Popover } from "antd";
// import { Tooltip } from "reactstrap";
import LoderButton from "../Forgotpassword/LoderButton";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import LogoTransparency from "../../Images/LogoTransparency.png";
// import BackgroundImage from "../../../components/BackgroundImage";
import { Auth } from "aws-amplify";
// import { Form } from "antd";

class CofirmForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      confirmationCode: "",
      newPassword: "",
      confirmPassword: "",
      tooltipOpen: [false, false],
      tooltips: [
        {
          placement: "top",
          text: "Top",
        },
      ],
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(i) {
    const newArray = this.state.tooltipOpen.map((element, index) => {
      return index === i ? !element : false;
    });
    this.setState({
      tooltipOpen: newArray,
    });
  }
  content = (
    <div>
      <p>-Minimum password length: 6</p>
      <p>-Required at least one uppercase letter</p>
      <p>-Required at least one lowercase letter</p>
      <p>-Required at least one number</p>
      <p>-Required at least one nonalphanumeric character</p>
    </div>
  );

  componentDidMount() {
    this.setState({
      email: localStorage.getItem("forgotEmail"),
    });
    if (!localStorage.getItem("forgotEmail")) {
      this.props.history.push("/forgot_password");
    }
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.newPassword !== this.state.confirmPassword) {
      alert("Password and Confirm Password didnot match!");
      this.setState({ isLoading: false });
      console.log(this.state.newPassword, this.state.confirmPassword, "pass");
      return;
    }
    try {
      Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.confirmationCode,
        this.state.newPassword
      )
        .then((data) => {
          console.log(data);
          alert("Password reset successful, please login with the username and new password")
          this.props.history.push("/app/Leadlogin");
        })
        .catch((err) => {
          console.log(err);
          if (err.message) {
            alert(
              "Password does not conform to policy. Please verify.... \n-Minimum 6 Characters \n -At least one uppercase \n -At least one lowercase \n -At least one number \n -At least one special character"
            );
          }

          this.setState({ isLoading: false });
        });
    } catch (e) {
      this.setState({ isLoading: false });
      alert(e.message);
    }
  };

  render() {
    return (
      <div
        className="admin-login-form"
        style={{ background: "#eff1f3 0% 0% no-repeat padding-box" }}
      >
        <div className="login-fields" style={{ height: "500px" }}>
          <Form onSubmit={this.handleSubmit}>
            <div style={{ textAlign: "center", marginBottom: "24px" }}>
              <img
                src={LogoTransparency}
                alt="LogoTransparency"
                style={{ width: "100%" }}
              />
            </div>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input email",
                },
                {
                  type: "email",
                  message: "The input is not valid Corporate Email",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                type="text"
                placeholder="Email"
                id="email"
                value={this.state.email}
                readOnly
              />
            </Form.Item>
            <Form.Item
              name="confirmationCode"
              rules={[
                {
                  required: true,
                  message: "Please input Confirmation Code",
                },
                {
                  type: "confirmationCode",
                  message: "The input is not valid Corporate Email",
                },
              ]}
              //   initialValue={proConatct.town ? proConatct.town : ""}
            >
              <Input
                prefix={<UserOutlined />}
                type="text"
                placeholder="Confirmation Code (Code sent to your email)"
                id="confirmationCode"
                value={this.state.confirmationCode}
                onChange={this.handleChange}
                required
              />
            </Form.Item>
            <Popover
            content={this.content}
            title="Password Criteria"
            >
            <Button
                className="criteria"
                type="text"
                style={{
                color: "#39acda",
                marginLeft: "-15px",
                background: "transparent",
                border:'none'
                }}
            >
                Password Criteria
            </Button>
            </Popover>
            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please input newPassword",
                },
                {
                  type: "password",
                  message: "Please enter password",
                },
              ]}
              //   initialValue={proConatct.town ? proConatct.town : ""}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="New Password"
                id="newPassword"
                value={this.state.newPassword}
                onChange={this.handleChange}
                required
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please input confirmPassword",
                },
                {
                  type: "password",
                  message: "Please enter confirmPassword",
                },
              ]}
              //   initialValue={proConatct.town ? proConatct.town : ""}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Confirm Password"
                id="confirmPassword"
                value={this.state.confirmPassword}
                onChange={this.handleChange}
                required
              />
            </Form.Item>
            <Row>
              <Col xs="6">
                <LoderButton
                  block
                  bssize="large"
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Confirm"
                  loadingText="Logging in...."
                />
                {/*<input type="Submit" color="primary" className="px-4 primary" value="Login" />*/}
              </Col>
              <Col xs="6" className="text-right">
                <a href="/app/Leadlogin">Back</a>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      //
    );
  }
}

export default CofirmForgotPassword;
