import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Card } from "antd";
import Blog3image from "../../Images/Blog3image.png";

export default class Blog3 extends Component {
  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  render() {
    return (
      <div>
        <Row>
          <Col
            xl={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            xxl={{ span: 20, offset: 2 }}
          >
            <Card
              style={{
                justifyContent: "center",
                width: "100%",
                marginTop: "2vw",
              }}
            >
              <div style={{ padding: "0px,2px,0px,2px" }}>
                <Row>
                  <a
                    href="https://greaterhartford.score.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Blog3image"
                      src={Blog3image}
                      style={{ width: "20%", height: "20%" }}
                    />
                  </a>
                </Row>

                <Row className="content-priv" style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    SCORE, greater Hartford consist of a network of volunteers,
                    expert business mentors who are dedicated to helping small
                    businesses get off the ground, grow and achieve their goals.
                  </Col>
                </Row>
                <Row className="content-priv" style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    SCORE is a 501(c)(3) nonprofit organization and a resource
                    partner of the U.S. Small Business Administration (SBA).
                    Thanks to this generous support from the SBA and because of
                    the selfless contributions of our more than 10,000 dedicated
                    volunteers, we can deliver most of our offerings at no cost.
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    <b
                      className="priv-main"
                      style={{ fontSize: "20px", color: "#165874" }}
                    >
                      SCORE’s Small Business Services
                    </b>
                  </Col>
                </Row>
                <Row className="content-priv">
                  <Col span={24}>
                    SCORE provides a wide range of services to established and
                    budding business owners alike, including:
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      <a
                        href="https://greaterhartford.score.org/find-mentor"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#165874" }}
                      >
                        {" "}
                        Mentoring
                      </a>
                    </b>
                  </Col>
                </Row>
                <Row className="content-priv">
                  <Col span={24}>
                    Entrepreneurs can access free, confidential business
                    mentoring in person at more than 250 local chapters or
                    remotely via email, phone and video. SCORE mentors and all
                    experts in entrepreneurship and related fields meet with
                    their small business clients on an ongoing basis to provide
                    continued advice and support.
                    <a
                      href="https://greaterhartford.score.org/find-mentor"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Get connected with a mentor.
                    </a>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      <a
                        href="https://greaterhartford.score.org/take-workshop?f%5B0%5D=ss_language%3Aen&f%5B1%5D=custom"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#165874" }}
                      >
                        {" "}
                        Webinars and Courses on Demand
                      </a>
                    </b>
                  </Col>
                </Row>
                <Row className="content-priv">
                  <Col span={24}>
                    SCORE regularly offers free online workshops on topics
                    ranging from startup strategies to marketing and finance.
                    Attendees can watch webinars live, or view recordings online
                    on their own time. We also offer interactive courses
                    on-demand, so you can walk through each module at your own
                    pace.{" "}
                    <a
                      href="https://greaterhartford.score.org/live-webinars?f%5B0%5D=ss_language%3Aen&f%5B1%5D=custom"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Register for an upcoming LIVE webinar,
                    </a>
                    <a
                      href="https://greaterhartford.score.org/recorded-webinars?f%5B0%5D=ss_language%3Aen&f%5B1%5D=custom"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                       explore our past webinar recordings,
                    </a>{" "}
                    and 
                    <a
                      href="https://greaterhartford.score.org/courses-demand"
                      target="_blank"
                      rel="noreferrer"
                    >
                      take our courses on demand.
                    </a>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      <a
                        href="https://greaterhartford.score.org/browse-small-business-resource-library?f%5B0%5D=ss_language%3Aen&f%5B1%5D=custom"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#165874" }}
                      >
                        {" "}
                        Library of online resources{" "}
                      </a>
                    </b>
                  </Col>
                </Row>
                <Row className="content-priv">
                  <Col span={24}>
                    Business owners can also benefit from SCORE’s extensive
                    collection of guides, templates, checklists, blogs, videos,
                    infographics, and more. We strive to provide the most
                    relevant and current educational content to help small
                    business owners and entrepreneurs succeed.{" "}
                    <a
                      href="https://greaterhartford.score.org/browse-small-business-resource-library?f%5B0%5D=ss_language%3Aen&f%5B1%5D=custom"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Check out our library of resources.
                    </a>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      <a
                        href="https://greaterhartford.score.org/local-workshops-events"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#165874" }}
                      >
                        {" "}
                        Local events
                      </a>
                    </b>
                  </Col>
                </Row>
                <Row className="content-priv">
                  <Col span={24}>
                    Many local SCORE chapters hold free or low-cost in-person
                    workshops and roundtable discussions covering a range of
                    topics.
                    <a
                      href="https://greaterhartford.score.org/take-workshop-0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Find a local workshop near you.
                    </a>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col>
                    <b
                      className="priv-main"
                      style={{ fontSize: "25px", color: "#165874" }}
                    >
                      {" "}
                      Stay connected
                    </b>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      <a
                        href="https://greaterhartford.score.org/content/connect-score"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#165874" }}
                      >
                        {" "}
                        Sign up for their emails
                      </a>
                      .
                    </b>
                  </Col>
                </Row>
                <Row className="content-priv">
                  <Col span={24}>
                    Learn about the latest SCORE small business webinars, blog
                    posts, and resources.
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      <a
                        href="https://greaterhartford.score.org/content/connect-score"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#165874" }}
                      >
                        {" "}
                        Follow Score Greater Hartford on social media
                      </a>
                    </b>
                  </Col>
                </Row>
                <Row className="content-priv">
                  <Col span={24}>
                    Gain informative, motivational content for entrepreneurs,
                    and share it with your friends.
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
