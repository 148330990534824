import React, { Component } from "react";
import { Row, Col, Input, Button, Divider, Card,  } from "antd";
// import business from "../Images/business.png";
import { sendMail } from "../../Api/Apis";
import AppConfig from "../config/AppConfig";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    };
    // console.log(this.state, "data");
    // this.handleOk = this.handleOk.bind(this);
    this.handlechangeall = this.handlechangeall.bind(this);
  }
  handlechangeall = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    // console.log(event.target.value);
  };
  handleSend = (e) => {
    this.setState({
      firstName: e["firstName"],
      lastName: e["lastName"],
      email: e["email"],
      phone: e["phone"],
      message: e["message"],
    });
    // console.log("firstName", this.state.firstName);
    let data = {
      entityData: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.email,
        message: this.state.message,
      },
    };
    // console.log("data", data);
    sendMail(AppConfig["entityNames"]["contacts"], data).then((res) => {
      // console.log(res.data, "res");
      if (res.data.message === "Success") {
        alert("Form submitted successfully");
      }
      // } else {
      //   alert("Business name doesn't exist, please sign up");
      // }
    });
  };

  render() {
    const { TextArea } = Input;
    return (
      <>
        {/* <Loading enableLoading={this.state.initialLoading} /> */}
        <Row>
          <Col span={20} offset={2}>
            <Divider>
              <h1>Contact Us</h1>
            </Divider>
          </Col>
        </Row>

        <Row>
          <Col
            xl={{ span: 24, offset: 0 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <Row gutter={24}>
              <Col
                xl={{ span: 24, offset: 0 }}
                lg={{ span: 20, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                style={{padding: "0px 15vw 0px 15vw"}}
              >
                <Card
                  hoverable
                  bordered={false}
                  title={null}
                  bodyStyle={{ padding: "15px" }}
                  style={{
                    // background: contactUsPage.contact1.backgroundColor,
                    borderColor: "black",
                    marginTop: "1vw",
                    marginBottom: "100px",
                    backgroundColor: "#F0F2F5",
                    
                  }}
                  className="padding-card1-whyjoin"
                >
                  <Row className="padding">
                    <Col
                      xl={{ span: 24 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                    >
                      <h3
                        style={{
                          fontWeight: "bold",
                          marginBottom: "2.5vw",
                        }}
                      >
                        Contact us anytime for questions, suggestions, etc:
                      </h3>
                    </Col>
                  </Row>
                  <Row className="padding">
                    <Col
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                    >
                      <span className="color-grey  terms">First Name:</span>
                    </Col>
                    <Col
                      xl={{ span: 18, offset: 2 }}
                      lg={{ span: 18, offset: 2 }}
                      md={{ span: 18, offset: 2 }}
                      xs={{ span: 18, offset: 1 }}
                      sm={{ span: 18, offset: 1 }}
                    >
                      <Input
                        // disabled={this.state.disableEdit}
                        name="firstName"
                        placeholder="Enter Your First Name"
                        className="form-input"
                        value={this.state.firstName}
                        onChange={this.handlechangeall}
                        style={{
                          border: "1px solid #fff",
                          color: "black",
                          background: "white",
                        }}
                      />
                    </Col>
                  </Row>
                  <Divider style={{ margin: "5px" }}></Divider>

                  <Row className="padding">
                    <Col
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                    >
                      <span className="color-grey  terms"> Last Name :</span>
                    </Col>
                    <Col
                      xl={{ span: 18, offset: 2 }}
                      lg={{ span: 18, offset: 2 }}
                      md={{ span: 18, offset: 2 }}
                      xs={{ span: 18, offset: 1 }}
                      sm={{ span: 18, offset: 1 }}
                    >
                      <Input
                        // disabled={this.state.disableEdit}
                        name="lastName"
                        placeholder="Enter Your Last  Name"
                        className="form-input"
                        value={this.state.lastName}
                        onChange={this.handlechangeall}
                        style={{
                          border: "1px solid #fff",
                          color: "black",
                          background: "white",
                        }}
                      />
                    </Col>
                  </Row>
                  <Divider style={{ margin: "5px" }}></Divider>

                  <Row className="padding">
                    <Col
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                    >
                      <span className="color-grey terms">Email :</span>
                    </Col>
                    <Col
                      xl={{ span: 18, offset: 2 }}
                      lg={{ span: 18, offset: 2 }}
                      md={{ span: 18, offset: 2 }}
                      xs={{ span: 18, offset: 1 }}
                      sm={{ span: 18, offset: 1 }}
                    >
                      <Input
                        // disabled={this.state.disableEdit}
                        name="email"
                        placeholder="Enter Your Email"
                        className="form-input"
                        value={this.state.email}
                        onChange={this.handlechangeall}
                        style={{
                          border: "1px solid #fff",
                          color: "black",
                          background: "white",
                        }}
                      />
                    </Col>
                  </Row>

                  <Divider style={{ margin: "5px" }}></Divider>

                  <Row className="padding">
                    <Col
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                    >
                      <span className="color-grey terms">Phone :</span>
                    </Col>
                    <Col
                      xl={{ span: 18, offset: 2 }}
                      lg={{ span: 18, offset: 2 }}
                      md={{ span: 18, offset: 2 }}
                      xs={{ span: 18, offset: 1 }}
                      sm={{ span: 18, offset: 1 }}
                    >
                      <Input
                        // disabled={this.state.disableEdit}
                        name="phone"
                        placeholder="Enter Your Phone"
                        className="form-input"
                        value={this.state.phone}
                        onChange={this.handlechangeall}
                        style={{
                          border: "1px solid #fff",
                          color: "black",
                          background: "white",
                        }}
                      />
                    </Col>
                  </Row>

                  <Divider style={{ margin: "5px" }}></Divider>

                  {/* <Row className="padding">
                    <Col
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                    >
                      <span className="color-grey terms">Subject :</span>
                    </Col>
                    <Col
                      xl={{ span: 18, offset: 2 }}
                      lg={{ span: 18, offset: 2 }}
                      md={{ span: 18, offset: 2 }}
                      xs={{ span: 18, offset: 1 }}
                      sm={{ span: 18, offset: 1 }}
                    >
                      <Input
                        name="subject"
                        placeholder="Enter Your Subject"
                        className="form-input"
                        value={this.state.subject}
                        onChange={this.handleMailForm}
                        style={{ border: "1px solid #fff" }}
                      />
                    </Col>
                  </Row>

                  <Divider style={{ margin: "5px" }}></Divider> */}

                  <Row className="padding">
                    <Col
                      xl={{ span: 4 }}
                      lg={{ span: 5 }}
                      md={{ span: 4 }}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                    >
                      <span className="color-grey terms">Message :</span>
                    </Col>
                    <Col
                      xl={{ span: 18, offset: 2 }}
                      lg={{ span: 17, offset: 2 }}
                      md={{ span: 18, offset: 2 }}
                      xs={{ span: 18, offset: 1 }}
                      sm={{ span: 18, offset: 1 }}
                    >
                      <TextArea
                        placeholder="Start Typing.."
                        rows={3}
                        name="message"
                        value={this.state.message}
                        onChange={this.handlechangeall}
                        style={{ border: "1px solid #fff" }}
                      />
                    </Col>
                  </Row>

                  <Divider style={{ margin: "5px" }}></Divider>
                  <div style={{ float: "right" }}>
                    <Button
                      key="submit"
                      type="primary"
                      // loading={this.state.loading}
                      onClick={this.handleSend}
                      className="send-button-mobile-view"
                      style={{width:"100px"}}
                    >
                      <div
                        style={{
                          color: "#FFF",
                          fontWeight: "bold",
                          fontSize: "18px",
                          
                        }}
                      >
                        Send
                      </div>
                    </Button>
                  </div>
                </Card>
              </Col>
              {/* <Col
              xl={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
              className="card-description"
            >
              <Card
                hoverable
                bordered={false}
                title={null}
                bodyStyle={{ padding: "15px" }}
                className="padding-card1-whyjoin-image-card"
                style={{
                  // background: contactUsPage.contact1.backgroundColor,
                  borderColor: "black",
                  marginTop: "1vw",
                  marginBottom: "1vw",
                }}
              >
                <Row>
                  <Col
                    xl={{ span: 8, offset: 0 }}
                    lg={{ span: 8, offset: 0 }}
                    md={{ span: 8, offset: 0 }}
                    sm={{ span: 22, offset: 1 }}
                    xs={{ span: 22, offset: 1 }}
                  >
                    <img
                      alt="Tracy"
                      // src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${contactUsPage.contact1.profile_picture}`}
                      style={{
                        boxShadow: "0px 0px 10px 0px",
                        border: "6px solid rgb(28, 45, 65)",
                        width: "230px",
                        height: "230px",
                        objectFit: "contain",
                        background: "#FFF",
                      }}
                    />
                  </Col>
                  <Col
                    xl={{ span: 14, offset: 2 }}
                    lg={{ span: 14, offset: 2 }}
                    md={{ span: 14, offset: 2 }}
                    sm={{ span: 22, offset: 1 }}
                    xs={{ span: 22, offset: 1 }}
                    className="card-description"
                  >
                    <h3 style={{ fontWeight: "bold" }}>
                      {contactUsPage.contact1.name +
                        ", " +
                        contactUsPage?.contact1?.designation}
                    </h3>
                    <h3>{contactUsPage.contact1.website_name}</h3>
                    <h3>{contactUsPage.contact1.address}</h3>
                    <h3>
                      Phone :
                      <NumberFormat
                        format="(###) ###-####"
                        value={contactUsPage.contact1.phone_number}
                        displayType={"text"}
                      />
                    </h3>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col
                    xl={{ span: 8, offset: 0 }}
                    lg={{ span: 8, offset: 0 }}
                    md={{ span: 8, offset: 0 }}
                    sm={{ span: 22, offset: 1 }}
                    xs={{ span: 22, offset: 1 }}
                  >
                    <img
                      alt="kristin"
                      // src={kristinPic}
                      src={`https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/${contactUsPage.contact2.profile_picture}`}
                      style={{
                        boxShadow: "0px 0px 10px 0px",
                        border: "6px solid rgb(28, 45, 65)",
                        width: "230px",
                        height: "230px",
                        objectFit: "contain",
                        background: "#FFF",
                      }}
                    />
                  </Col>
                  <Col
                    xl={{ span: 14, offset: 2 }}
                    lg={{ span: 14, offset: 2 }}
                    md={{ span: 14, offset: 2 }}
                    sm={{ span: 22, offset: 1 }}
                    xs={{ span: 22, offset: 1 }}
                    className="card-description"
                  >
                    <h3 style={{ fontWeight: "bold" }}>
                      {contactUsPage.contact2.name +
                        ", " +
                        contactUsPage?.contact2?.designation}
                    </h3>
                    <h3>{contactUsPage.contact2.website_name}</h3>
                    <h3>{contactUsPage.contact2.address}</h3>
                    <h3>
                      Phone :
                      <NumberFormat
                        format="(###) ###-####"
                        value={contactUsPage.contact2.phone_number}
                        displayType={"text"}
                      />
                    </h3>
                  </Col>
                </Row>
              </Card>
            </Col> */}
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}
