import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Select, Input } from "antd";
// import { Radio } from "antd";

class Step7 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteRedio: true,
      isdeselect: false,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          agreement: values.agreement,
        });

        let data = {
          product: values["product"],
          people: values["people"],
          competition: values["competition"],
          competitors: values["competitors"],
          revenue: values["revenue"],
          marketBusiness: values["marketBusiness"],
        };
        if (this.props.formData.id) {
          this.props.updateData(data);
        } else {
          this.props.doRegister(data);
        }

        // console.log("data", data);
        // this.addUser(values["email"], data);
      }
    });
  };
  render() {
    const { TextArea } = Input;
    const { getFieldDecorator } = this.props.form;
    const { formData } = this.props;
    const { Option } = Select;
    return (
      <Row>
        <Col span={24}>
          <div style={{ textAlign: "center", marginTop: "100px" }}>
            <h2 style={{ fontWeight: "bold" }}>
              What can you tell me about the product and service you provide?
            </h2>
          </div>
        </Col>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col span={24}>
            <center>
            <Form.Item className="Formitem" name="product">
              {getFieldDecorator("product", {
                rules: [
                  {
                    required: true,
                    message: "Please input !",
                  },
                ],
                initialValue: formData.product,
              })(
               
               
                <TextArea
                className="text_areabox"
                  style={{
                    // textAlign: "center" ,
                    borderRadius: "0px",
                    // width: "28%",
                    height: "104px",
                    padding: "10px",
                  }}
                  placeholder="Please briefly explain"
                />
                
              )}
            </Form.Item>
            </center>
          </Col>
          <Col span={24}>
            <div style={{ textAlign: "center", marginTop: "50px" }}>
              <h2 style={{ fontWeight: "bold" }}>
                What do you want people to know about your business?
              </h2>
            </div>
          </Col>
          <Col span={24}>
            <center>
            <Form.Item className="Formitem" name="people">
              {getFieldDecorator("people", {
                rules: [
                  {
                    required: true,
                    message: "Please input !",
                  },
                ],
                initialValue: formData.people,
              })(
               
                <TextArea
                  className="text_areabox"
                  style={{height:'80px'}}
                  placeholder="Please briefly explain"
                />
            
              )}
            </Form.Item>
            </center>
          </Col>
          <Col span={24}>
            <div style={{ textAlign: "center", marginTop: "50px" }}>
              <h2 style={{ fontWeight: "bold" }}>
                Who do you believe is your competition?
              </h2>
            </div>
          </Col>
          
          <Col span={24} >
            <center>
            <Form.Item className="Formitem" name="competition">
              {getFieldDecorator("competition", {
                rules: [
                  {
                    required: true,
                    message: "Please input !",
                  },
                ],
                initialValue: formData.competition,
              })(
                
                <TextArea
                className="text_areabox"
                  style={{
                    // textAlign: "center" ,
                    borderRadius: "0px",
                    // width: "28%",
                    height: "104px",
                    padding: "10px",
                  }}
                  placeholder="Please briefly explain"
                />
               
              )}
            </Form.Item>
            </center>
          </Col>
          <Col span={24}>
            <div style={{ textAlign: "center", marginTop: "50px" }}>
              <h2 style={{ fontWeight: "bold" }}>
                How do these competitors affect your business?
              </h2>
            </div>
          </Col>
          <Col span={24}>
            <center>
            <Form.Item className="Formitem" name="competitors">
              {getFieldDecorator("competitors", {
                rules: [
                  {
                    required: true,
                    message: "Please input !",
                  },
                ],
                initialValue: formData.competitors,
              })(
               
                <TextArea
                className="text_areabox"
                style={{height:'80px'}}
                placeholder="Please briefly explain"
              />
               
              )}
            </Form.Item>
            </center>
          </Col>

          <Col span={24}>
            <div style={{ textAlign: "center", marginTop: "50px" }}>
              <h2 style={{ fontWeight: "bold" }}>
                How soon are you looking to market your business?
              </h2>
            </div>
          </Col>
          <Col
          lg={{span:17,offset:7}}
          xl={{ span: 9, offset: 9 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}>
            <Form.Item className="Formitem" name="marketBusiness" className1="input_step7">
              {getFieldDecorator("marketBusiness", {
                rules: [
                  {
                    required: true,
                    message: "Please input !",
                  },
                ],
                initialValue: formData.marketBusiness,
              })(
                <Select className="ant-select-selection--single">
                  <Option value="As soon as possible">
                    As soon as possible
                  </Option>
                  <Option value="1-3 months">1-3 months</Option>
                  <Option value="4-6 months">4-6 months</Option>
                  <Option value="12+ months">12+ months</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="Previous"
                type="primary"
                onClick={() => this.props.back()}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                  marginBottom:"80px"
                }}
              >
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="next"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  // marginLeft: "4%",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Form.create({ name: "Step7" })(Step7);
