import React, { Component } from "react";
import "antd/dist/antd.min.css";
import Header from "../Components/Header/Header";
import { Switch, Route } from "react-router-dom";
import AppRoutes from "../Components/config/AppRoutes";
import Navigation from "./Header/Navigation";

class Authenticated extends Component {
  render() {
    return (
      <div style={{ marginBottom: "10vw" }}>
        {window.location.pathname !== "/login" &&
          window.location.pathname !== "/register" && <Header />}

        <Switch>
          {AppRoutes.map((route) => (
            <Route
              exact={route.exact}
              path={route.path}
              component={route.component}
              key={route.key}
            />
          ))}
        </Switch>
        <div className="show_mobile">
          <Navigation/>
        </div>
      </div>
    );
  }
}
export default Authenticated;
