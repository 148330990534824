import React, { Component } from "react";
import { Row, Col, Form, Card, Button } from "antd";
import "antd/dist/antd.min.css";
import AppConfig from "../config/AppConfig";
import { getQuestion } from "../../Api/Apis";
import { Link } from "react-router-dom";

export default class Leadscontact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      List: ["9"],
      selectedRowKeys: [],
      companies: [],
      loading: false,
      matchingData: {},
    };
  }
  componentDidMount = () => {
    let id = JSON.parse(localStorage.getItem("leadId"));
    console.log(id, "leadid");
    // let companiesdata = this.state.companies;
    // let getInfoCompany = companiesdata.filter((o) => o.id === recordId);

    getQuestion(AppConfig["entityNames"]["getQuestion"], id).then((result) => {
      // console.log(result, "res");
      let matchedData = result.data.filter(
        (u) => u.id === this.props.match.params.id
      );
      this.saveCompaniesData(matchedData);
      console.log(matchedData, "dataaa");
      // this.setState({
      //   matchingData: result.data
      // })
      // console.log(this.state.matchingData,"matchhh")
    });

    this.saveCompaniesData = async (user, recordId) => {
      // console.log(data)
      this.setState({
        companies: user,
      });

      this.setState({
        loading: false,
      });
    };
  };
  render() {
    let companiesdata = this.state.companies;
    console.log(this.props, "props");
    console.log(this.props.match.params.id, "paramsid");

    return (
      <div>
        <Row style={{ marginTop: "3vw" }}>
          <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
            <Col span={24}>
              <div style={{ textAlign: "center", marginTop: "100px" }}>
                <h1 style={{ fontWeight: "bold" }}>
                  Here are the company details based on your search criteria
                </h1>
              </div>
            </Col>
            {companiesdata.map((data) => (
              <Col span={20} offset={2}>
                <Card
                  className="ant-card-body"
                  style={{
                    marginBottom: "2vw",
                    borderRadius: "10px",
                    // height: "110px",
                  }}
                >
                  <Row>
                    <Col
                      xxl={{ span: 10, offset: 2 }}
                      xl={{ span: 10, offset: 2 }}
                      lg={{ span: 10, offset: 2 }}
                      md={{ span: 10, offset: 2 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span style={{ fontSize: "18px" }}>
                        <b>Contact Person Name</b>
                      </span>
                      <Row>
                        <h4>{data.businessName}</h4>
                      </Row>
                    </Col>
                    <Col
                      xxl={{ span: 11, offset: 1 }}
                      xl={{ span: 11, offset: 1 }}
                      lg={{ span: 12 }}
                      md={{ span: 12 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span style={{ fontSize: "18px" }}>
                        <b>Contact Person Details </b>
                      </span>
                      <Row>
                        <h4>{data.contactPerson}</h4>
                      </Row>
                      <Row>
                        <h4>{data.phoneNumber}</h4>
                      </Row>
                      <Row>
                        <h4>{data.email}</h4>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
            <Col span={8} offset={10}>
              <Link to="/app/Myleads">
                <Button
                  key="1"
                 
                  type="primary"
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "#165874",
                    width: "200px",
                    justifyContent: "center",
                  }}
                >
                  Go back to My Leads
                </Button>
              </Link>
            </Col>
          </Form>
        </Row>
      </div>
    );
  }
}
