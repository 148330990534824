import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Input, Button, Progress } from "antd";
// import "antd/dist/antd.css";
import done from "../../Images/done.png";
import { Link } from "react-router-dom";

class Completed extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // console.log(this.props.data);
    // console.log(this.props.data);
    return (
      <Row>
        <div  className="progress-mobile">
          <Progress
            percent={this.props.count * 20}
            size="large"
            status="active"
          />
        </div>
        {this.props?.data2 ? (
          <Col span={24}>
            <div style={{ textAlign: "center", marginTop: "100px" }}>
              <h1 style={{ fontWeight: "bold" }}>Your account is Updated!</h1>
            </div>
          </Col>
        ) : (
          <Col span={24}>
            <div style={{ textAlign: "center", marginTop: "100px" }}>
              <h2 style={{ fontWeight: "bold" }}>
                Congratulations, ({this.props.data}) is now a member of
                Mediapiq’s network of marketing outlets.
              </h2>
              <div style={{ marginBottom: "1vw" }}>
                <h3 style={{ fontWeight: "bold" }}>
                  You will receive via email information on businesses that
                  could utilize your marketing services. 
                </h3>
                <h3 style={{ fontWeight: "bold" }}>
                  If you have any questions, you may reach us via email at{" "}
                  <Link
                    to="#"
                    onClick={() =>
                      (window.location = "mailto:contact@mediapiq.com")
                    }
                  >
                    contact@mediapiq.com
                  </Link>
                </h3>
              </div>
            </div>
          </Col>
        )}
        <Col span={12}>
          <Form.Item className="Formitem">
            <img className="imgView" src={done} alt="done" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item className="Formitem">
            {/* <p style={{ textAlign: "center" }}>
              MutationObserver.js' file: Error: ENOENT: no such file or <br />
              directory, open{" "}
            </p> */}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item className="Formitem">
            <Link to="/">
              <Button
                key="1"
                type="primary"
                // onClick={() => this.props.handlePrevious("/")}
                className="homeView"
              >
                Home
              </Button>
            </Link>
          </Form.Item>
        </Col>
      </Row>
    );
  }
}
export default Completed;
