import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button } from "antd";
// import { Radio } from "antd";

class Steprange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteRedio: true,
      isSelect: false,
      rangeGoals: ["$10K-$25K", "$26K-$35K", "$36K-$50K", "$51K+"],
    };
  }

  handleClick = (e) => {
    let rangeGoals = this.props.formData.rangeGoals;
    let value = e.target.value;
    this.setState({
      isSelect: value,
    });
    if (rangeGoals) {
      if (rangeGoals.indexOf(value) > -1) {
        this.props.setFieldsData(10, "rangeGoals", value);
      }
    }
  };

  onSelectChange = (e) => {
    this.props.setFieldsData(10, "rangeGoals", e.target.value);
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let isSelect = this.props.formData.rangeGoals
      ? this.props.formData.rangeGoals
      : this.state.isSelect;

    // console.log(this.state.isSelect, "next",isSelect);

    if (
     isSelect === ""
      || !isSelect
    ) {
      alert("Please select your options!");
      return;
    }
    this.props.updateData({ rangeGoals: isSelect });
  };

  handleRedioMarketing = (data) => {
    let isSelect = this.props.formData.rangeGoals
      ? this.props.formData.rangeGoals
      : this.state.isSelect;
    // let checkValue = this.props.formData.rangeGoals?.includes(data);
    return (
      <div style={{ marginBottom: "1vw", border: "black" }}>
        <label>
          <input
            name={data}
            type="checkbox"
            value={data}
            onChange={this.onSelectChange}
            onClick={this.handleClick}
            className="visually-hidden"
            checked={isSelect}
          />
          <div
            className="btn"
            style={{
              height: "75px",
              borderRadius: "0px",
              paddingTop: "25px",
              backgroundColor: data === isSelect ? "#165874" : "white",
              color: data === isSelect ? "white" : "black",
              border: "1px solid black",
            }}
          >
            {data}
          </div>
        </label>
      </div>
    );
  };

  render() {
    return (
      <Row>
        <Col span={24}>
          <div style={{ textAlign: "center", marginTop: "100px" }}>
            <h1 style={{ fontWeight: "bold" }}>
              What is your adversting budget?
            </h1>
          </div>
        </Col>

        {/* <Col span={24}>
          <Form.Item className="Formitem">
            <p style={{ color: "gray", textAlign: "center" }}>
              select 1 or more
            </p>
          </Form.Item>
        </Col> */}

        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col
            xl={{ span: 19, offset: 8 }}
            lg={{ span: 18, offset: 6 }}
            md={{ span: 12, offset: 7 }}
            sm={{ span: 24, offset: 0 }}
            xs={{ span: 24, offset: 3 }}
          >
            <Form.Item className="Formitem">
              {this.state.rangeGoals?.map((data) => (
                <div
                  style={{
                    width: "130px",
                    float: "left",
                    marginLeft: "10px",
                  }}
                >
                  {this.handleRedioMarketing(data)}
                </div>
              ))}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="Previous"
                type="primary"
                onClick={() => this.props.back()}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                  marginBottom: "80px",
                }}
              >
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="next"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  // marginLeft: "4%",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Steprange;
