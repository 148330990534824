import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

class LocationSearchInput extends React.Component {
  constructor(props) {
    // console.log(props);
    super(props);
    this.state = { address: props.defaultValue };
  }

  handleChange = (address) => {
    // console.log(address);
    // this.setState({ address });
    // if(this.props.editMode) {
    this.props.setAddress(address);
    // } else {
    this.setState({ address });
    // if(address === "") {
    this.props.getAddress(address, {});

    // }
  };

  // };

  handleSelect = (address) => {
    // console.log(address);
    if (this.props.editModeAuto) {
      this.setState({
        address,
      });
    }

    // this.props.setAddress(address);
    geocodeByAddress(address).then((results) => {
      // console.log(results, this.props.editMode);
      this.props.getNewAddress(results[0]);
      if (this.props.editMode) {
        this.props.click();
        this.setState({
          address: "",
        });
      }
      // } else {
      //   this.setState({
      //     address,
      //   });
      // }
    });
  };
  handleSingleSearch = (address) => {
    // console.log(address);
    let cityValue = "";
    let stateValue = "";
    let newLocations = [];
    // console.log(address);
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
        }
        const stateCodes = "ct";
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() === stateValue.toLowerCase() ||
            restState.stateType.toLowerCase() === stateValue.toLowerCase()
        );
        if (setVendor && setVendor.length > 0) {
          let newSearchLocation = {
            cityName: cityValue,
            stateName: stateValue,
            locationType: "location",
          };
          newLocations.push(newSearchLocation);
          this.setState({
            cities: {
              searchLocation: newLocations,
              type: "location",
              citiesList: [
                ...this.state.cities.citiesList,
                `${stateValue}:${cityValue}`,
                // console.log(this.stete.cities),
              ],
            },
          });
        }
      })
      .catch((error) => console.error("Error", error));
  };

  handleClick = (address) => {
    // console.log(address, "address");
  };

  render() {
    // console.log(this.props);
    // const searchOptions = {
    //   location: new window.google.maps.LatLng(37.0902, 95.7129),
    //   radius: 2000,
    //   types: ['address']
    // }
    // const options = {
    //   componentRestrictions: { country: ['us'] },
    //   types: ['city']
    // }
    return (
      <div
        style={{
          width: "98%",
          position: "relative",
        }}
      >
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          onClick={this.handleClick}
          // options={options}
          // searchOptions={searchOptions}

          // onChange={(result) => {
          //   this.handleSingleSearch(result);
          // }}
          // searchOptions={searchOptions}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <Input
                {...getInputProps({
                  prefix: <SearchOutlined />,
                  placeholder: "Search Places ...",
                  className: "location-search-input",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.length > 0 && (
                  <div style={{ borderRadius: 5, }}>
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}

                          // className='add-lead border-form-item'
                        >
                          {suggestion.description?.indexOf(", USA") > -1 &&
                            (suggestion.description.indexOf(" CT") > -1 ||
                              suggestion.description.indexOf(" Connecticut") >
                                -1) && <span>
                                  {console.log(suggestion)}
                                  {suggestion.description}</span>}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }
}
export default LocationSearchInput;
