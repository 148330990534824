import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Card } from "antd";
import AppConfig from "../config/AppConfig";
import { getCompanie } from "../../Api/Apis";

class Companies1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      List: ["9"],
      selectedRowKeys: [],
      companies: [],
      loading: false,
    };
  }
  componentDidMount = () => {
    let id = JSON.parse(localStorage.getItem("Step8"));
    console.log(id);
    getCompanie(AppConfig["entityNames"]["getCompanies"], id).then((result) => {
      if (result.success) {
        this.saveCompaniesData(result.data);
      }
    });

    this.saveCompaniesData = async (user) => {
      // console.log(data)
      this.setState({
        companies: user,
      });

      localStorage.setItem("compines ", JSON.stringify(user));
      this.setState({
        loading: false,
      });
    };
  };
  handleSubmit = (event) => {
    event.preventDefault();
    // this.props.history.push("/app/Companies2");
    this.setState({
      loading: true,
    });
    if (this.props.formData.id) {
      // localStorage.setItem("Step8",JSON.stringify(this.props.formData.id))
      this.props.gotoPayment();
    } else {
      this.props.doRegister();
    }
  };

  render() {
    let companiesdata = this.state.companies;

    let lifestyl = companiesdata.services ? companiesdata.services : [];
    console.log(lifestyl);
    const { formData } = this.props;
    console.log(formData, "form");
    console.log(companiesdata, "companydata");
    // console.log(companiesdata.ageGroup, "company");
    let selectedAgeGroup = [];
    companiesdata &&
      companiesdata?.map((e) => {
        let compAge = e.ageGroup.filter((ca) => formData.agegroup.includes(ca));

        if (!selectedAgeGroup.includes(compAge[0])) {
          selectedAgeGroup.push(compAge[0]);
        }
        // e["selectedAgeGroup"].pus
        // console.log(e.ageGroup,"ageeee",formData.agegroup),
        //   formData.agegroup.includes(e.ageGroup) ? e.ageGroup : ""
        // ))
      });

    // console.log(companiesdata);
    return (
      <Row>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col span={24}>
            <div style={{ textAlign: "center", marginTop: "100px" }}>
              <h1 style={{ fontWeight: "bold" }}>
                Thank you for completing the needs analysis form.<br></br> We
                will use the information you submitted and match it with a
                <br></br>
                marketing-advertising outlet that can best serve your
                business-organization promotional needs.
                {/* We found {companiesdata.length} companies who 
            can help you */}
              </h1>
              <h2 style={{ fontWeight: "bold" }}>
                We found {companiesdata.length} companies who can help you based
                on your search criteria
              </h2>
            </div>
          </Col>
          <Col span={12} style={{ marginLeft: "26%" }}>
            <Row>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <span style={{ fontSize: "18px" }}>
                  <b>Industry</b>
                </span>
                <Row>
                  <h4>{formData.industry}</h4>
                </Row>
              </Col>

              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <span style={{ fontSize: "18px" }}>
                  <b>Age Group</b>
                </span>
                <Row>
                  <h4>
                    {formData.agegroup.map((age) => (
                      <span>
                        {age}
                        <br />
                      </span>
                    ))}
                  </h4>
                </Row>
                {/* <div>
                    {selectedAgeGroup.map((age) => (
                      <span>
                        {age}
                        <br />
                      </span>
                    ))}
                  </div> */}
              </Col>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <span style={{ fontSize: "18px" }}>
                  <b>Budget </b>
                </span>
                <Row>
                  <h4>{formData.rangeGoals}</h4>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
          <Form.Item className="Formitem">
            <p style={{ color: "gray", textAlign: "center" }}>
              To get the full report please proceed to payment process
            </p>
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={20} xs={20} style={{ marginLeft: "45%" }}>
            <Col span={12}>
            <Form.Item className="Formitem">
              {/* <Link to="/app/Companies"> */}
              <Button
                key="1"
                loading={this.state.loading}
                htmlType="submit"
                type="primary"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  // marginLeft: "4%",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Go to payment
              </Button>
              {/* </Link> */}
              </Form.Item>          
               </Col>
          </Col>
          <Col span={20} offset={2}>
            <Card
              className="ant-card-body"
              style={{ marginTop: "2vw", borderRadius: "10px" }}
            >
              {companiesdata.map((data) => (
                <Row>
                  <Col
                     xxl={{ span: 8, offset: 4 }}
                     xl={{ span: 11,offset:1 }}
                     lg={{ span: 12 }}
                     md={{ span: 12 }}
                     sm={{ span: 12 }}
                     xs={{ span: 12 }}
                  >
                    <span>
                      <b>Contact Person Name</b>
                    </span>
                    <Row>
                      <h3 className="textshadow" style={{ filter: "blur 7px" }}>
                        {data.businessName}
                      </h3>
                    </Row>
                    <Row>
                      {data.area.length > 0 &&
                        data.area.map(
                          (style) => (
                            <Button
                              style={{
                                backgroundColor: "#9baebc",
                                color: "white",
                                fontSize: "10px",
                                border: "none",
                                marginRight: "5px",
                              }}
                            >
                              {/* {companiesdata.platform} */}
                              {style}
                            </Button>
                          )
                          // );
                        )}
                    </Row>
                  </Col>

                  <Col
                    xxl={{ span: 10, offset: 2 }}
                    xl={{ span: 10, offset: 2 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 12 }}
                  >
                    <span>
                      <b>Contact Person Deatails</b>
                    </span>
                    <Row>
                      <h4 className="textshadow">{data.contactPerson}</h4>
                    </Row>
                    <Row>
                      <h6 className="textshadow">{data.phoneNumber}</h6>
                    </Row>
                    <Row>
                      <h6 className="textshadow">{data.email}</h6>
                    </Row>
                  </Col>
                </Row>
              ))}
            </Card>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Companies1;
