import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Select, } from "antd";
import { DownOutlined } from "@ant-design/icons";

class Step6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteRedio: true,
      isdeselect: false,
      yes: "",
    };
  }

  handleClick = (e) => {
    let Step6 = this.props.formData.creatingbussiness;
    let value = e.target.value;
    if (Step6) {
      if (Step6.indexOf(value) > -1) {
        this.props.setFieldsData(6, "creatingbussiness", value);
      }
    }
  };

  onSelectChange = (e) => {
    this.props.setFieldsData(6, "creatingbussiness", e.target.value);
    // let Step6 = e.value;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          agreement: values.agreement,
        });

        let data = {
          specificValue: values["specificValue"],
          ageGroup: values["ageGroup"],
          hhincome: values["hhincome"],
          ethic: values["ethic"],
        };
        if (this.props.formData.id) {
          this.props.updateData(data);
        } else {
          this.props.doRegister(data);
        }
        // this.props.setFieldsData(6, "creatingbussiness", data);

        // console.log("data", data);
        // this.addUser(values["email"], data);
      }
    });
  };

  handleRedioMarketing = (data) => {
    let checkValue = this.props.formData.creatingbussiness?.includes(data);
    return (
      <div>
        <label>
          <input
            key={data}
            type="checkbox"
            value={data}
            onChange={this.onSelectChange}
            onClick={this.handleClick}
            className="visually-hidden"
            checked={checkValue}
          />
          <div className="btn">{data}</div>
        </label>
      </div>
    );
  };

  render() {
    const { Option } = Select;
    const { getFieldDecorator } = this.props.form;
    const { formData } = this.props;
    return (
      <Row>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col span={24}>
            <Form.Item className="Formitem">
              <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
                Do you have a specific target demographic that you are trying to
                reach ?
             
              <h6 style={{ color: "gray", textAlign: "center" }}>
              select 1 or more
            </h6>
            </h2>
            </Form.Item>
            
          </Col>
         

          <Col span={24}>
            <Form.Item className="Formitem" style={{ marginLeft: "39%" }}>
              {getFieldDecorator("specificValue", {
                rules: [
                  {
                    required: true,
                    message: "Please input !",
                  },
                ],
                initialValue: formData.specificValue,
              })(
                <Select
                  className="ant-select-selection--single"
                  style={{ width: "375px ",height:"auto" }}
                  mode="multiple"
                  showArrow={true}
                  suffixIcon={<DownOutlined />}
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value="Married Couples">Married Couples</Option>
                  <Option value="Single">Single</Option>
                  <Option value="Widow">Widow</Option>
                  <Option value="Divorce">Divorce</Option>
                  <Option value=" Families w children">
                    Families w children
                  </Option>
                  <Option value=" Families W/o children">
                    Families w/o children
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item className="Formitem">
              <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
                What age group are you targeting?
                <h6 style={{ color: "gray", textAlign: "center" }}>
              select 1 or more
            </h6>
              </h2>
              
            </Form.Item>
          </Col>
         
          <Col span={24}>
            <Form.Item className="Formitem" style={{ marginLeft: "39%" }}>
              {getFieldDecorator("ageGroup", {
                rules: [
                  {
                    required: true,
                    message: "Please input !",
                  },
                ],
                initialValue: formData.ageGroup,
              })(
                <Select className="ant-select-selection--single"
                mode="multiple"
                  showArrow={true}
                  style={{ width: "375px",height:"auto" }}
                  suffixIcon={<DownOutlined />}>
                <Option value="11 and under">11 and under</Option>
                <Option value="12-18">12-18</Option>
                <Option value="19-24">19-24</Option>
                <Option value="25-35">25-35</Option>
                <Option value="36-44">36-44</Option>
                <Option value="45-54">45-54</Option>
                <Option value="55-64">55-64</Option>
                <Option value="65-74">65-74</Option>
                <Option value="74+">74+</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
         
         
          <Col span={24}>
            <Form.Item className="Formitem">
              <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
                What Ethnic Groups are you targeting?
                
            <h6 style={{ color: "gray", textAlign: "center" }}>
              select 1 or more
            </h6>
     
              </h2>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item className="Formitem" style={{ marginLeft: "39%" }}>
              {getFieldDecorator("ethic", {
                rules: [
                  {
                    required: true,
                    message: "Please input !",
                  },
                ],
                initialValue: formData.ethic,
              })(
                <Select className="ant-select-selection--single"
                mode="multiple"
                showArrow={true}
                style={{ width: "375px",height:"auto" }}
                suffixIcon={<DownOutlined />}>
                  <Option value="White or Caucasian">White or Caucasian</Option>
                  <Option value="Blak,African American or Coloured People">
                    Blak,African American or Coloured People
                  </Option>
                  <Option value="American Indian or Alaska Native">
                    American Indian or Alaska Native
                  </Option>
                  <Option value="Latino or Hispanic">Latino or Hispanic</Option>
                  <Option value="Asian">Asian</Option>
                  <Option value="Pacific Islander or Hawaiian">
                    Pacific Islander or Hawaiian
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="Previous"
                type="primary"
                onClick={() => this.props.back()}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="next"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  // marginLeft: "4%",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Form.create({ name: "Step6" })(Step6);
