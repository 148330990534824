import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Input, Button, Card } from "antd";
// import "antd/dist/antd.css";
import { Link } from "react-router-dom";

class TermsofServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      revenue: "",
      business: "",
    };
  }

  render() {
    return (
      <div>
        <div style={{ textAlign: "center", color: "black" }}>
          <h1 className="priv-hed" style={{ fontSize: "25px" }}>
            <b> Terms & Conditions</b>
          </h1>
        </div>
        <Row>
          <Col
            xl={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            xxl={{ span: 20, offset: 2 }}
          >
            <Card
              style={{
                justifyContent: "center",
                width: "100%",
                marginTop: "2vw",
                
              }}
            >
              <div style={{  padding:"20px",marginTop:"1vw"}}>
                <p className="content-priv">
                  The following terms of service ("Terms") constitute an
                  agreement between Isabel Diaz dba MediaPiq ("Company"), and
                  you that governs your use of this website and all of its
                  associated services, content, and functionality. This policy
                  applies to the website administered by Company ("Website"). 
                </p>
                <p className="content-priv">
                  Throughout these terms, the collective content from the
                  Website, Services, or other content will be referred to as
                  “Company Content.”
                </p>
                <p className="content-priv">
                  Your use of the Website constitutes your acceptance of, and
                  agreement to, the following Terms. Company reserves the right
                  to modify, alter, amend or update its Website, policies, and
                  these Terms. These Terms are subject to change without notice.
                  If you do not agree with or do not accept any part of these
                  Terms, you must not use the Company Content.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Additional Terms</b>
                </h1>
                <p className="content-priv">
                  Any additional policies or terms adopted by Company are hereby
                  incorporated into these Terms. This includes: 
                </p>
                <ul>
                  <li>
                    <Link to="/app/Privacypolicy">
                      <span className="content-priv">Privacy Policy</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/app/Disclaimer">
                      <span className="content-priv">Disclaimer</span>
                    </Link>
                  </li>
                </ul>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Services</b>
                </h1>
                <p className="content-priv">
                  MediaPiq offers certain services to connect media outlets and
                  small businesses (“Services”). The following policies govern
                  these Services.
                </p>
                <h3 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Definition of Media Outlets and Businesses</b>
                </h3>
                <p className="content-priv">
                  These Terms will refer to both “Media Outlets” and
                  “Businesses.”
                  <br />
                  <br />
                  Under the Terms, a Media Outlet includes, but is not limited
                  to, marketing services, consultants, radio, or other forms of
                  media. A Media Outlet will be characterized as a type of
                  business who offers marketing, advertising, or media services
                  to a Business.
                  <br />
                  <br /> A Business may include a business or organization of
                  any size. A Business will be characterized as an entity or
                  individual who is seeking to hire a Media Outlet for certain
                  services, including marketing, consulting, or media
                  placements.
                </p>
                <b className="priv-hed" style={{ fontSize: "25px" }}>
                  {" "}
                  Account Creation{" "}
                </b>
                <p className="content-priv">
                  In order to use the Services, you must create an account. At
                  the time of account creation, you will select whether you are
                  a media outlet (“Media Outlet”) or small business
                  (“Business”). Certain Services may be limited by the type of
                  account you register for.
                </p>
                <b className="priv-hed" style={{ fontSize: "25px" }}>
                  Access to Services{" "}
                </b>
                <p className="content-priv">
                  The Services are intended and suitable for individuals age 18
                  and over who are enrolling on behalf of a business.
                </p>
                <p className="content-priv">
                  To access the Services, you must create an account on
                  MediaPiq’s technological platform. You may share login
                  credentials within your company; however, they may not be
                  shared with any third parties.
                </p>
                <p className="content-priv">
                  You are responsible for ensuring that your login information
                  remains private. If Company suspects that your login
                  credentials have been shared with a third party, Company
                  reserves the right to revoke your access to the Services.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Media Outlet Accounts</b>
                </h1>
                <p className="content-priv">
                  Media Outlets, marketing services or consultants are invited
                  to contribute certain information about their services in
                  order to be eligible to be matched with Businesses or
                  organizations that may be in need of the types of services
                  offered by the Media- marketing Outlet.
                </p>
                <p className="content-priv">
                  Establishing an account as a Media-marketing Outlet is free of
                  charge.
                </p>
                <p className="content-priv">
                  When you establish your account, you agree to provide the
                  requested information, which will be used by MediaPiq’s
                  algorithm to match you with Businesses who need services. For
                  example, a Media Outlet will be requested to discuss their
                  experience, industry, and other information that would be
                  relevant to MediaPiq to match the Media Outlet -marketing with
                  a Business.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Media Outlets - Paid Services</b>
                </h1>
                <p className="content-priv">
                  While there is no charge to have a Media Outlet Account,
                  MediaPiq does charge to receive Business contact information
                  in certain circumstances.
                </p>
                <p className="content-priv">
                  If MediaPiq determines that you are a Match, as defined below,
                  for a Business, you will receive a notification (via email or
                  on the MediaPiq account) to let you know that a potential lead
                  (“Lead”) has been identified. MediaPiq will provide you with
                  information about the Lead to help you determine if it is the
                  right fit for your business.
                </p>
                <p className="content-priv">
                  If you would like to receive contact information for the Lead,
                  you will then submit payment to MediaPiq for the Lead. The
                  price of the Lead will be based on the project submitted for
                  consideration by the Business.
                </p>
                <p className="content-priv">
                  All payments will be made through MediaPiq’s payment portal.
                  As multiple Media Outlets will be identified for a particular
                  Lead, Media Outlet should respond quickly to ensure that the
                  Lead has not yet retained another Media Outlet.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Business Accounts</b>
                </h1>
                <p className="content-priv">
                  Upon creation of a Business account, a Business may submit a
                  request to be Matched with a Media Outlet, as defined below.
                  Business understands that information submitted will be shared
                  as a potential lead with the Media Outlets. Contact
                  information for the Business will not be shared unless the
                  Media Outlet pays for the Lead.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>MediaPiq Matches</b>
                </h1>
                <p className="content-priv">
                  MediaPiq’s goal is to match Businesses with Media Outlets who
                  can provide services for the Business. To do this, MediaPiq
                  has developed a proprietary algorithm to create its matches
                  (“Matches” or “Match”).
                </p>
                <p className="content-priv">
                  You understand that MediaPiq uses the following process in its
                  Matches:{" "}
                </p>
                <ul>
                  <li className="content-priv">
                    Businesses will self-identify their needs, which will
                    include information about their industry, requested
                    services, budget, and more, when they make a request for a
                    Match.
                  </li>
                  <li className="content-priv">
                    Media Outlets will submit information regarding their skills
                    and industry upon creation of their account.
                  </li>
                  <li className="content-priv">
                    MediaPiq’s proprietary algorithm will determine if a Media
                    Outlet is a potential Match for a Business.
                  </li>
                  <li className="content-priv">
                    MediaPiq will then offer the opportunity for the Media
                    Outlet to purchase the Lead. Upon purchase, MediaPiq will
                    provide contact information and any other additional details
                    to the Media Outlet, who will then be responsible for
                    contacting the Lead.
                  </li>
                  <li className="content-priv">
                    Upon purchase of the Lead, MediaPiq’s role in the
                    transaction is completed.
                  </li>
                  <li className="content-priv">
                    The Media Outlet is then responsible for contacting the
                    Lead. Since multiple Media Outlets will receive the Lead’s
                    information, it will be up to the Media Outlet to promptly
                    contact and pitch the Business for the potential services
                    outlined in the Match.
                  </li>
                </ul>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Code of Conduct</b>
                </h1>
                <p className="content-priv">
                  Both Businesses and Media Outlets agree to provide truthful
                  information to MediaPiq in order to facilitate potential
                  matches. All parties agree to be respectful in their
                  communication regarding the Match.
                </p>
                <p className="content-priv">
                  If inappropriate conduct or communications are reported to
                  MediaPiq, MediaPiq reserves the right to ban members from the
                  MediaPiq platform.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Survey</b>
                </h1>
                <p className="content-priv">
                  In the event that a Business hires a Media Outlet based on a
                  Match, MediaPiq may submit a survey to the Business to learn
                  more about the Business’s experience with the Media Outlet.
                  Media Outlet understands that the results of these surveys may
                  also be used by MediaPiq’s proprietary algorithm in future
                  Matches.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Not Included in the Services</b>
                </h1>
                <p className="content-priv">
                  The Services include establishing Matches between Media
                  Outlets and Businesses. This includes offering Business
                  contact information in the form of a Lead to Media Outlets.
                </p>
                <ul>
                  <b className="priv-hed" style={{ fontSize: "25px" }}>
                    MediaPiq does not:{" "}
                  </b>
                  <li className="content-priv">
                    Pitch on the Media Outlet’s behalf;
                  </li>
                  <li className="content-priv">
                    Enter into any agreements outside of these Terms related to
                    the relationship between the Media Outlet and Business;
                  </li>
                  <li className="content-priv">
                    Process any payments from the Business to the Media Outlet;
                  </li>
                  <li className="content-priv">
                    Mediate any disputes between the Media Outlet and Business;
                  </li>
                  <li className="content-priv">
                    Manage or review any services, deliverables, or outcomes
                    from the Media Outlet.
                  </li>
                </ul>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>No Guarantees</b>
                </h1>
                <b className="priv-hed" style={{ fontSize: "25px" }}>
                  MediaPiq does not guarantee:
                </b>
                <ul>
                  <li className="content-priv">
                    That a Media Outlet will obtain paid clients from Leads. It
                    will be solely in the Media Outlet’s responsibility to pitch
                    and win the project with the Business.{" "}
                  </li>
                  <li className="content-priv">
                    That the scope of the deliverables provided by the Business
                    are complete. It is up to the Media Outlet to determine the
                    full scope of the project before entering into an agreement
                    with the Business.
                  </li>
                  <li className="content-priv">
                    That any relationship between the Media Outlet and Business
                    will have a certain result. MediaPiq is serving to match
                    Media Outlets with Businesses, but MediaPiq is responsible
                    solely for the Match process. MediaPiq cannot guarantee that
                    the Match will be successful.
                  </li>
                </ul>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b> Your Responsibility In Using The Website</b>
                </h1>
                <p className="content-priv">
                  The Website was developed strictly for informational purposes.
                  You understand and agree that you are fully responsible for
                  your use of the information provided on the Website. Company
                  makes no representations, warranties, or guarantees. You
                  understand that results may vary from person to person.
                  Company assumes no responsibility for errors or omissions that
                  may appear in the Website.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Use Of The Website </b>
                </h1>
                <p className="content-priv">
                  Unless otherwise stated, Company owns the intellectual
                  property and rights to all content and material on the
                  Website. Subject to the license below, all intellectual
                  property rights are reserved.
                </p>{" "}
                <p className="content-priv">
                  You may view, download (for caching purposes only), and print
                  pages for your personal use, subject to the restrictions set
                  out below and elsewhere in these Terms of Use.
                </p>
                <p className="content-priv">
                  Unless it is specifically and expressly made available for
                  such purpose, the following uses are not permitted:
                </p>
                <ul>
                  <li className="content-priv">
                    Republication, redistribution, sale, rental, or
                    sub-licensing of content from the Website;
                  </li>
                  <li className="content-priv">
                    Reproduction or duplication of any content on the Website
                    for commercial purposes;
                  </li>
                  <li className="content-priv">
                    Modification of any content on the Website;
                  </li>
                </ul>
                <p className="content-priv">
                  From time to time, the Website will utilize various plugins or
                  widgets to allow sharing of content via social media channels,
                  email, or other methods. Use of these plugins or widgets does
                  not constitute any waiver of Company's intellectual property
                  rights. Such use is a limited license to republish the content
                  on the approved social media channels only, with full credit
                  to Company.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Prohibited Uses Of The Website</b>
                </h1>
                <p className="content-priv">
                  You must not use the Website in a way that causes, or may
                  cause, damage to the Website or impair the availability of
                  access to the Website. You must not decompile, reverse
                  engineer, disassemble, or otherwise reduce the Website, except
                  to the extent that such activity is expressly permitted by
                  applicable law. You must not use the Website to copy, store,
                  host, transmit, send, use, publish, or distribute any material
                  that consists of (or is linked to) any spyware, computer
                  virus, Trojan horse, worm, keystroke logger, rootkit, and/or
                  other harmful code or malicious software.
                </p>
                <p className="content-priv">
                  You must not conduct any systematic or automated data
                  collection activities, including, but not limited to,
                  scraping, data mining, data extraction, or data harvesting on
                  or in relation to the Website without Company's express
                  written permission.
                </p>
                <p className="content-priv">
                  You must not use the Website to transmit or send any
                  unsolicited commercial communications, including, but not
                  limited to, spam comments.
                </p>
                <p className="content-priv">
                  You must not use the Website for any third-party marketing
                  without Company's express written permission.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Copyright</b>
                </h1>
                <p className="content-priv">
                  Unless otherwise noted, the design, content, and all
                  components of the Website are copyrights owned by Company or
                  third parties and are protected by United States and
                  international copyright laws and should not be reused or
                  republished without express written permission.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Trademarks</b>
                </h1>
                <p className="content-priv">
                  Company's trademarks and trade dress may not be used in
                  connection with any product or service that is not Company's,
                  in any manner likely to cause confusion among consumers, or in
                  any manner that disparages or discredits Company or its
                  owners.
                </p>
                <p className="content-priv">
                  From time to time, the Website will legally utilize trademarks
                  owned by third parties. These trademarks are the respective
                  property of their owners and Company makes no claim of
                  ownership.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Content Contributed To The Website</b>
                </h1>
                <p className="content-priv">
                  In limited circumstances, you may contribute content to the
                  Website, including, but not limited to, comments, posts, or
                  submissions. Any content you contribute to the site,
                  including, but not limited to text, images, audio material,
                  comments, video material, and audio-visual material, must not
                  be illegal or unlawful, may not infringe on any third-party's
                  legal rights, and must not be capable of giving rise to legal
                  action whether against you, Company, or a third party.
                </p>
                <p className="content-priv">
                  Company reserves the right to edit or remove: (i) any material
                  submitted to the Website; (ii) stored on Company's servers;
                  or, (iii) hosted or published on the Website. Company takes no
                  responsibility and assumes no liability for any content posted
                  by you or any third party.
                </p>
                <p className="content-priv">
                  Notwithstanding Company's rights under the Terms of Use,
                  Company does not undertake to monitor the submission of all
                  content to, or the publication of such content on, the
                  Website.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Grant Of Rights</b>
                </h1>
                <p className="content-priv">
                  You grant Company a worldwide, irrevocable, non-exclusive,
                  royalty-free license to use, reproduce, adapt, publish,
                  translate, and distribute any content you contribute to the
                  Website. This includes, but is not limited to, text, images,
                  audio material, comments, video material, and audio-visual
                  material. This license extends to all known and future media.
                  You also grant Company the right to sub-license these rights
                  and the right to bring an action for infringement of these
                  rights.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Communication</b>
                </h1>
                <p className="content-priv">
                  If you send Company an email, register to use the Website, or
                  provide your email to Company in any other way, you consent to
                  receive communications from Company electronically. You agree
                  that all legal notices provided via electronic means from
                  Company satisfy any requirement for written notice.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Third Parties </b>
                </h1>
                <p className="content-priv">
                  The Website may contain links to third-party websites that are
                  not governed or controlled by Company. You represent and
                  warrant that you have read and agree to be bound by all
                  applicable Terms of Use and policies for any third-party
                  website that relate to your use of the Website. Company
                  assumes no control or liability over the content of any
                  third-party sites. You expressly hold Company harmless from
                  any and all liability related to your use of a third-party
                  website.
                </p>
                <p className="content-priv">
                  Prior to engaging in any events or commercial transactions
                  with any third parties discovered through or linked on the
                  Website, you must complete any necessary investigation or due
                  diligence. If there is a dispute for any events or commercial
                  transactions with a third party discovered through or linked
                  on the Website, you expressly hold Company harmless from any
                  and all liability in any dispute.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>NO WARRANTIES </b>
                </h1>
                <p className="content-priv">
                  The Website and Services are provided on an "as is" and "as
                  available" basis without any representations or warranties,
                  express or implied. Company makes no representations or
                  warranties in relation to the Company Content or the
                  information and materials provided therein.
                  <br />
                  <br />
                  Company makes no warranty the Website or Services will meet
                  your requirements; will be available uninterrupted; timely and
                  free of viruses or bugs; or represents the full functionality,
                  accuracy, and reliability of the Website. Company is not
                  responsible to you for the loss of any content or material
                  uploaded or transmitted through the Website. The Website is
                  written in English and makes no warranty regarding translation
                  or interpretation of content in any language. 
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Limitation Of Liability</b>
                </h1>
                <p className="content-priv">
                  Comapny will not be liable for any consequential,incidental,
                  indirect,exemplary,punitive,or special damage of any
                  kind,however caused,including loss of profits,revenue,data or
                  use,incurred by you,whether under theory of contract,tort
                  (including negligence), warranty,or otherwise,even if the
                  other part has been advised of the possibility of such damages
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Indemnity</b>
                </h1>
                <p className="content-priv">
                  You agree to defend, indemnify and hold Company, its members,
                  employees, officers, directors, managers, and agents harmless
                  from and against any and all losses, claims, suits, actions,
                  liabilities, obligations, costs, and expenses (including
                  reasonable attorneys' fees and expenses) which Company suffers
                  as a result of third-party claims based on: (i) your
                  negligence or intentional misconduct, (ii) your breach of any
                  provision of the Terms of Use (including representation or
                  warranty); (iii) materials prepared or provided by you
                  including, but not limited to, any claims of infringement, or
                  misappropriation of copyright, trademark, patent, trade
                  secret, or other intellectual property or proprietary right,
                  infringement of the rights of privacy or publicity, or
                  defamation or libel; or (iv) death, personal injury, or
                  property damage arising out of, or relating to, your
                  obligations hereunder.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Arbitration</b>
                </h1>
                <p className="content-priv">
                  The Terms of Use will be governed and construed in accordance
                  with the laws of the State of Connecticut. Any controversy or
                  claim arising out of or relating to the Terms of Use, or the
                  breach thereof, shall be settled by arbitration administered
                  by the American Arbitration Association ("AAA") under its
                  Commercial Arbitration Rules, and judgment on the award
                  rendered by the arbitrator(s) may be entered in any court
                  having jurisdiction thereof. The place of any such arbitration
                  shall be in or near Hartford County, Connecticut. The parties
                  also agree that the AAA Optional Rules for Emergency Measures
                  of Protection shall apply to the proceedings.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Miscellaneous Provisions</b>
                </h1>
                <p className="content-priv">
                  If any provision(s) of the Terms of Use is held to be invalid,
                  illegal, or unenforceable, the remaining provisions shall be
                  severable and enforceable. If a provision is excessively
                  broad, such a provision shall be limited or reduced in scope
                  so as to be enforceable.
                </p>
                <p className="content-priv">
                  The Terms of Use may not be assigned by you without Company's
                  prior written consent; however, the Terms of Use may be
                  assigned by Company in its sole discretion.
                </p>
                <p className="content-priv">
                  The Terms of Use are the final, complete, and exclusive
                  agreement of the parties with respect to the Website offered
                  by Company.
                </p>
                <p className="content-priv">
                  All notices with respect to the Terms of Use must be in
                  writing and may be via email to INSERT EMAIL​ for Company and
                  to your email address.
                </p>
                <p className="content-priv">Updated: March 23, 2022</p>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Form.create({ name: "TermsofServices" })(TermsofServices);
