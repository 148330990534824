import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Card } from "antd";
import BoostFund from "../../Images/BoostFund.jpg";

export default class Blog1 extends Component {
  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  render() {
    return (
      <div>
        <Row>
          <Col
            xl={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            xxl={{ span: 20, offset: 2 }}
          >
            <Card
              style={{
                justifyContent: "center",
                width: "100%",
                marginTop: "2vw",
              }}
            >
              <div style={{ padding: "0px,2px,0px,2px" }}>
                <Row>
                  <a
                    href="https://ctsmallbusinessboostfund.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="BoostFund"
                      src={BoostFund}
                      style={{ width: "20%", height: "20%" }}
                    />
                  </a>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      Connecticut state officials launched a $150 million loan
                      program to help nonprofits and small businesses get access
                      to flexible working capital
                    </b>
                  </Col>
                </Row>
                <Row className="content-priv" style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    The Connecticut Small Business Boost Fund program offers
                    nonprofits and small businesses access to flexible working
                    capital. Working capital affects many aspects of your
                    business, from paying your employees and vendors to keeping
                    the lights on and planning for sustainable long- term
                    growth. In short, working capital is the money available to
                    meet your current, short-term obligations. The Boost Fund
                    program offers eligible businesses funding for working
                    capital and connects them with support services.
                  </Col>
                </Row>
                <Row className="content-priv" style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    Many small businesses and nonprofits in Connecticut
                    experience barriers to accessing financial resources. This
                    is especially true for organizations in low-income areas,
                    and those led by women and people of color. The Connecticut
                    Small Business Boost Fund was created to provide access to
                    working capital for those that need it most, in turn
                    supporting a greater economic recovery for Connecticut. It’s
                    a one-stop shop for business owners, where they can get
                    business support and guidance before, during and after the
                    loan application process.
                  </Col>
                </Row>
                <Row className="content-priv" style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    Small businesses and nonprofits can borrow between $5,000
                    and $500,000 depending on eligibility, need and can choose
                    whether to spend the money on equipment, payroll, utilities,
                    rent, supplies, marketing, advertising, eligible
                    refinancing, building renovations and other expenses. The
                    loans have a fixed, 4.5 percent interest rate and are
                    available to eligible small businesses and nonprofits with
                    operations in Connecticut that have 100 or fewer full-time
                    employees and annual revenues of less than $8 million
                    dollars. Businesses will need to pay back the full amount of
                    the loan with interest over a 60- or 72-month term. For more
                    information or to see If your business or nonprofit
                    qualifies, visit the Connecticut Small Business Boost Fund
                    website at{" "}
                    <a href="https://ctsmallbusinessboostfund.org">
                      https://ctsmallbusinessboostfund.org
                    </a>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      {" "}
                      Below are additional loan details:
                    </b>
                    <br />
                  </Col>
                  <Row className="content-priv">No origination fees</Row>
                  <Row className="content-priv">
                    {" "}
                    Streamlined application process
                  </Row>
                  <Row className="content-priv">
                    Quick application approval timelines
                  </Row>
                  <Row className="content-priv">
                    Support in multiple languages
                  </Row>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col>
                    <Row className="content-priv">
                      Borrow between $5,000 and $500,000 (subject to
                      eligibility).
                    </Row>
                    <Row className="content-priv">
                      Fixed 4.5% interest rate.
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col className="content-priv">
                    60-month repayment terms for loans less than $150,000;
                    72-month repayment terms for loans over $150,000.
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col className="content-priv">
                    Business and nonprofits must have operations in Connecticut.
                    <br />
                    Have no more than 100 full-time employees.
                    <br /> Have annual revenues of less than $8 million. <br />
                    Businesses and nonprofits must have been in operation for
                    at. least 1 year prior to the date of application. <br />A
                    small amount of financing is available for start-up
                    businesses.
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
