import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button,Input } from "antd";
// import { Radio } from "antd";

class Step4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteRedio: true,
      isdeselect: false,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          agreement: values.agreement,
        });
        // let business = this.state.selected1;

        let data = {
          peopleWant: values["peopleWant"],
        };
        if (this.props.formData.id) {
          this.props.updateData(data);
        } else {
          this.props.doRegister(data);
        }
      }
    });
  };
  render() {
    const { TextArea } = Input;
    const { getFieldDecorator } = this.props.form;
    const { formData } = this.props;

    return (
      <Row>
        <Col span={24}>
          <div style={{ textAlign: "center", marginTop: "24px" }}>
            <h1 style={{ fontWeight: "bold" }}>
              What do you want people to know about your business?
            </h1>
          </div>
        </Col>
        <Form onSubmit={this.handleSubmit}>
          <Col span={24}>
          <center>
            <Form.Item className="Formitem">
              {getFieldDecorator("peopleWant", {
                rules: [
                  {
                    required: true,
                    message: "Please input !",
                  },
                ],
                initialValue: formData.peopleWant,
              })(
             
               
                <TextArea
                  className="text_areabox"
                  style={{height:'80px'}}
                  placeholder="Please briefly explain"
                />
             
              )}
            </Form.Item>
            </center>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="Previous"
                type="primary"
                onClick={() => this.props.back()}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="next"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  // marginLeft: "4%",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Form.create({ name: "Step4" })(Step4);
