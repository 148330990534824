import { Call,  noAuthApiUrl, ApiUrl,ParseGeneralResponse } from "./ApiUtils";
const queryString = require("query-string");
// export function getCompanie() {
//   let url = noAuthApiUrl("getCompanies");

//   return Call(
//     "get",
//     url,
//     {},
//     ParseGeneralResponse,
//     { responseDataKeys: { data: "" } },
//     false
//   );
// }
export function getCompanie(entityName, id) {
  const url = ApiUrl(entityName + "/" + id);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
  true
  );
}
export function getQuestion(entityName, id) {
  const url = ApiUrl(entityName + "/" + id);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
  true
  );
}
export function update(entityName, id, data) {
  const url = ApiUrl(entityName + "/" + id);
  console.log(url);
  return Call(
    "put",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}
export function updateQuestion(entityName, id, data) {
  const url = ApiUrl(entityName + "/" + id);
  console.log(url);
  return Call(
    "put",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}
export function verifyName(businessType,data) {
  const url = noAuthApiUrl("verifyBusiness"+"/"+businessType);
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}
export function getUser(entityName, id) {
  const url = noAuthApiUrl(entityName +  "/" +  id);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}

export function getArticle(entityName) {
  const url = noAuthApiUrl(entityName);
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}
export function checkUser(entityName, query = {}) {
  let url = noAuthApiUrl(entityName);
  url = url + "?" +"emailId="+  query["emailId"] 
  return Call(
    "get",
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}

export function sendMail(entityName, data) {
  const url = noAuthApiUrl(entityName);
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}
export function sendPayment(entityName, data) {
  const url = ApiUrl(entityName);
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}