import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Button, Card } from "antd";
import AppConfig from "../config/AppConfig";
import { getQuestion } from "../../Api/Apis";
import Loading from "../Leads/Loading";
// import { Link } from "react-router-dom";
export default class Myleads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      List: ["9"],
      selectedRowKeys: [],
      companies: [],
      loading: false,
      matchingData: {},
    };
  }
  componentDidMount = () => {
    let id = JSON.parse(localStorage.getItem("leadId"));
    console.log(id, "userId");
    getQuestion(AppConfig["entityNames"]["getQuestion"], id).then((result) => {
      // console.log(result, "res");
      if (result.data) {
        this.saveCompaniesData(result.data);
        console.log(result.data);
      } else {
        alert("No matchingData");
      }
      // this.setState({
      //   loading:true,
      // })
      // console.log(this.state.matchingData,"matchhh")
    });

    this.saveCompaniesData = async (user) => {
      // console.log(data)
      this.setState({
        companies: user,
      });

      this.setState({
        loading: false,
      });
    };
  };
  handleTogetmoreInfo = (recordId) => {
    console.log(recordId, "record");
    let id = JSON.parse(localStorage.getItem("leadId"));
    console.log(id, "userid....");
    let companiesdata = this.state.companies;
    let getInfoCompany = companiesdata.filter((o) => o.id === recordId);
    console.log(getInfoCompany, "ooo");
    this.props.history.push(`/app/Leadspayment/${recordId}`, getInfoCompany);
  };
  handleAvailable = (recordId) => {
    let id = JSON.parse(localStorage.getItem("leadId"));
    console.log(id, "userid....");
    let companiesdata = this.state.companies;
    let getInfoCompany = companiesdata.filter((o) => o.id === recordId);
    console.log(getInfoCompany, "handleAvailable");
    if (getInfoCompany.paymentDone !== true) {
      this.props.history.push(`/app/Leadscontact/${recordId}`, getInfoCompany);
    } else {
      this.props.history.push(`/app/Leadspayment/${recordId}`, getInfoCompany);
    }
  };

  render() {
    let companiesdata = this.state.companies;
    let lifestyl = companiesdata.service ? companiesdata.service : [];
    let id = JSON.parse(localStorage.getItem("leadId"));
    console.log(id, "userid...id");
    console.log(lifestyl);
    // const { formData } = this.props;

    return (
      <div>
        <Loading enableLoading={this.state.loading} />
        <div style={{ marginTop: "3vw" }}>
          <Col span={20} offset={2}>
            <h1>
              <b>New Leads</b>
            </h1>
          </Col>
        </div>
        {companiesdata.map((data) => (
          <>
            {(!data.paymentIds || !data.paymentIds?.includes(id) ) && (
              <Col span={20} offset={2}>
                <Card
                  className="ant-card-body"
                  style={{
                    marginBottom: "2vw",
                    borderRadius: "10px",
                    // height: "110px",
                  }}
                >
                  <Row>
                    <Col
                      xxl={{ span: 8 }}
                      xl={{ span: 8 }}
                      lg={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <span style={{ fontSize: "11px" }}>Looking For</span>
                      <Row>
                        {data.service.length > 0 &&
                          data.service.map((style) => (
                            <Button
                              style={{
                                backgroundColor: "#9baebc",
                                color: "white",
                                fontSize: "10px",
                                border: "none",
                                marginRight: "5px",
                                marginBottom: "10px",
                              }}
                            >
                              {style} {""}
                            </Button>
                          ))}
                      </Row>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 12 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <Row>
                        <Col span={12}>
                          <span style={{ fontSize: "11px" }}>INDUSTRY</span>
                          <h4>
                            {" "}
                            <b>{data.industry}</b>
                          </h4>
                        </Col>
                        <Col span={12}>
                          <span style={{ fontSize: "11px" }}>BUDGET</span>
                          <h4>
                            <b>{data.rangeGoals}</b>
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xxl={{ span: 4 }}
                      xl={{ span: 4 }}
                      lg={{ span: 4 }}
                      md={{ span: 4 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <Button
                        key="1"
                        type="primary"
                        style={{
                          borderRadius: "50px",
                          backgroundColor: "#165874",
                          width: "200px",
                          // justifyContent: "center",
                        }}
                        onClick={() => this.handleTogetmoreInfo(data.id)}
                      >
                        Get More Information
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ) }
          </>
        ))}
        <div style={{ marginTop: "3vw" }}>
          <Col span={10} offset={2}>
            <h1>
              <b>Available Leads</b>
            </h1>
          </Col>
        </div>
        {companiesdata.map((data) => (
          <>
            {data.paymentIds?.includes(id) && (
              <>
                <Col span={20} offset={2}>
                  <Card
                    className="ant-card-body"
                    style={{
                      marginBottom: "2vw",
                      borderRadius: "10px",
                      // height: "110px",
                    }}
                  >
                    <Row>
                      <Col
                        xxl={{ span: 8 }}
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 8 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <span style={{ fontSize: "11px" }}>Looking For</span>
                        <Row>
                          {data.service.length > 0 &&
                            data.service.map((style) => (
                              <Button
                                style={{
                                  backgroundColor: "#9baebc",
                                  color: "white",
                                  fontSize: "10px",
                                  border: "none",
                                  marginRight: "5px",
                                  marginBottom: "10px",
                                }}
                              >
                                {style} {""}
                              </Button>
                            ))}
                        </Row>
                      </Col>
                      <Col
                        xxl={{ span: 12 }}
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 12 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Row>
                          <Col span={12}>
                            <span style={{ fontSize: "11px" }}>INDUSTRY</span>
                            <h4>
                              {" "}
                              <b>{data.industry}</b>
                            </h4>
                          </Col>
                          <Col span={12}>
                            <span style={{ fontSize: "11px" }}>BUDGET</span>
                            <h4>
                              <b>{data.rangeGoals}</b>
                            </h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xxl={{ span: 4 }}
                        xl={{ span: 4 }}
                        lg={{ span: 4 }}
                        md={{ span: 4 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                      >
                        <Button
                          key="1"
                          type="primary"
                          style={{
                            borderRadius: "50px",
                            backgroundColor: "#165874",
                            width: "200px",
                            // justifyContent: "center",
                          }}
                          onClick={() => this.handleAvailable(data.id)}
                        >
                          Get More Information
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </>
            )}
          </>
        ))}
      </div>
    );
  }
}
