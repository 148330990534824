import { Row, Col, Card } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Disclaimer extends Component {
  render() {
    return (
      <div>
        <div style={{ textAlign: "center", color: "black" }}>
          <h1 className="priv-hed" style={{ fontSize: "25px" }}>
            <b> Disclaimer</b>
          </h1>
        </div>
        <Row>
          <Col
            xl={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            xxl={{ span: 20, offset: 2 }}
          >
            <Card
              style={{
                justifyContent: "center",
                width: "100%",
                marginTop: "2vw",
              }}
            >
              <div style={{ padding: "20px", marginTop: "1vw" }}>
                <p className="content-priv">
                  MediaPiq is owned and operated by Isabel Diaz (“Company”). The
                  following Disclaimer is hereby incorporated into the Website’s
                  Terms by reference, located at{" "}
                  <Link to="/app/TermsofServices"> Terms Conditions. </Link>
                </p>
                <p className="content-priv">
                  Throughout these terms, we may refer to the MediaPiq website
                  (“Website”) or certain MediaPiq services (“Services”).
                  Collectively, we will refer to the Website, Services, and all
                  MediaPiq content as the “Company Content.”
                </p>
                <p className="content-priv">
                  Your use of the Company Content constitutes your acceptance
                  of, and agreement to, the following disclaimer (“Disclaimer).
                  In the event that the Disclaimer is silent on any particular
                  matter, please review the Terms for more information.
                </p>
                <p className="content-priv">
                  We reserve the right to modify, alter, amend or update this
                  Disclaimer. This Disclaimer is subject to change without
                  notice. If you do not agree with or do not accept any part of
                  this Disclaimer, you must not use the Company Content.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Disclaimer Regarding Services</b>
                </h1>
                <p className="content-priv">
                  MediaPiq offers a service to match business and organizations
                  with media outlets. MediaPiq uses its proprietary algorithm to
                  determine the best potential match between business, or
                  organizations and a particular Marketing-Media oultet outlet.
                </p>
                <p className="content-priv">
                  If you are engaging in MediaPiq’s services, you are doing so
                  at your own risk. MediaPiq cannot guarantee your results and
                  you must complete all necessary due diligence prior to
                  entering into an agreement with any third party, regardless of
                  whether MediaPiq has facilitated the introduction.
                </p>
                <p className="content-priv">
                  MediaPiq obtains only self-reported information from the
                  organizations who use its services. MediaPiq may use some
                  public information in evaluating a media outlet’s suitability
                  for a job with a business. MediaPiq is not responsible for the
                  accuracy of any information used in the Services.
                </p>
                <p className="content-priv">
                  Any statements related to income or earnings potential in the
                  Company Content are examples of what may be possible in the
                  future. We make no guarantees regarding results, present or
                  future. We are not responsible for your earnings, income,
                  sales, or any other performance as a result of the actions you
                  take based upon the information provided in the Company
                  Content. The Company Content is provided for informational
                  purposes only.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Content Disclaimer</b>
                </h1>
                <p className="content-priv">
                  The Company Content was developed strictly for informational
                  purposes. Company makes no guarantees regarding the results
                  that you will see from using the information provided in the
                  Company Content.
                </p>
                <p className="content-priv">
                  We disclaim, undertake, or assume no liability for incidental
                  or consequential damages and assume no responsibility or
                  liability for any loss or damage suffered by any person as a
                  result of the use of the information provided in the Company
                  Content.
                </p>
                <p className="content-priv">
                  There is no guarantee that you will see positive results to
                  your business using the techniques and materials provided in
                  the Company Content. We assume no responsibility for your
                  decisions or for policies or practices that you implement
                  based on information in the Company Content.
                </p>
                <p className="content-priv">
                  Any statements related to income or earnings potential in the
                  Company Content are examples of what may be possible in the
                  future. We make no guarantees regarding results, present or
                  future. We are not responsible for your earnings, income,
                  sales, or any other performance as a result of the actions you
                  take based upon the information provided in the Company
                  Content.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Your Responsibility</b>
                </h1>
                <p className="content-priv">
                  You understand and agree that you are fully responsible for
                  your use of the information provided in the Company Content.
                  We make no representations, warranties, or guarantees. You
                  understand that results may vary from person to person. We
                  assume no responsibility for errors or omissions that may
                  appear in the Company Content.
                </p>
                <p className="content-priv">Updated: March 22, 2022</p>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
