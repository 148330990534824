import React, { Component } from "react";
// import "antd/dist/antd.min.css";
// import "antd/dist/antd.min.css";
import "antd/dist/antd.css";
import { Row, Col, Form, Input, Button, Modal, Select } from "antd";
// import { CloseOutlined } from "@ant-design/icons";
// import LocationAutocomplete from "../Autocomplete";
import "../../index.css";
import AppConfig from "../config/AppConfig";
import { getUser, verifyName } from "../../Api/Apis";
import { Auth } from "aws-amplify";
import NumberFormat from "react-number-format";

// const Search = Input.Search;
// const { getFieldDecorator } = this.props.form;
class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BusinessName: "",
      selectedAddress: "",
      Address2: [],
      city: "",
      state: "",
      zip: "",
      email: "",
      Contactperson: "",
      loading: false,
      agreement: "",
      Servicearea: "",
      latLangs: {},
      visable: false,
      area: "",
      username: "",
      password: "",
      user: "",
      autocity: "",
      autostate: "",
      autozip: "",
      address2: "",
      selectedAddress2: "",
      item1: "",
      editMode: false,
      getLoginData: false,
      defaultEmptyAddress: true,
      defaultService: true,
      loginAddress: "",
      singInProps: false,
      industry: "",
      businessClassification: "",
      phoneNumber: "",
    };
  }
  componentDidMount = () => {
    this.setState({
      Address2:
        this.props.formData.serviceArea &&
        this.props.formData.serviceArea.length > 0
          ? this.props.formData.serviceArea
          : [],
    });
    // console.log(this.props.formData, "pppppppppppppppp");
  };

  static getDerivedStateFromProps(props, state) {
    if (props.formData.serviceArea !== state.Address2) {
      return {
        Address2:
          props.formData.serviceArea && props.formData.serviceArea.length > 0
            ? props.formData.serviceArea
            : [],
      };
    }
    return null;
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      [
        "name",
        "address",
        "city",
        "state",
        "code",
        "person",
        "email",
        "contact",
        "industry",
        "businessClassification",
      ],
      (err, values) => {
        if (!err) {
          this.setState({
            agreement: values.agreement,
          });
          // let item1 = JSON.parse(localStorage.getItem("address"));
          let data = {
            businessName: values["name"],
            address: values["address"],
            city: values["city"],
            place: values["state"],
            zip: values["code"],
            phoneNumber: values["person"],
            email: values["email"],
            contactPerson: values["contact"],
            industry: values["industry"],
            businessClassification: values["businessClassification"],

            // serviceArea: this.props.formData.serviceArea,
          };
          if (this.props.formData.id) {
            this.props.updateData(data);
          } else {
            this.props.doRegister(data);
          }
          // console.log("data", data);
          // this.addUser(values["email"], data);
        }
      }
    );
  };

  setPhoneNumber = (value, id) => {
    this.props.form.setFieldsValue({
      [id]: value,
    });
  };

  onSelect(value) {}

  handleSearch = (value) => {
    this.setState({
      dataSource: !value ? [] : [value, value + value, value + value + value],
    });
  };

  getAddress = (address, latLng, fieldId) => {
    this.props.form.setFieldsValue({
      [fieldId]: address,
    });
    let latLangs = this.state.latLangs;
    latLangs[fieldId] = latLng;
    this.setState({
      latLangs,
    });
  };

  click = () => {
    this.setState({
      editMode: false,
    });
  };

  handleState = (results) => {
    this.setState({
      selectedAddress: results.formatted_address,
    });
    localStorage.setItem("address", JSON.stringify(results.formatted_address));
    results.address_components.map((data) => {
      if (data["types"][0] === "locality") {
        this.props.setUsersdata({ city: data.long_name });
      }
      if (data["types"][0] === "postal_code") {
        this.props.setUsersdata({ zip: data.long_name });
      }
      if (data["types"][0] === "administrative_area_level_1") {
        this.props.setUsersdata({ place: data.long_name });
      }
      return data;
    });
  };

  showModal = (e) => {
    // console.log("hi");
    e.preventDefault();
    this.setState({
      visible: true,
    });
  };
  hideModal = () => {
    this.setState({
      visible: false,
      loading: false,
    });
  };

  handleSignin = (e) => {
    this.setState({
      defaultEmptyAddress: false,
      defaultService: false,
    });
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      ["username", "password"],
      (err, values) => {
        if (!err) {
          this.setState({
            loading: true,
          });
          this.handleLogin(values["username"], values["password"]);
          this.setState({
            agreement: values.agreement,
          });
        }
      }
    );
  };
  handleLogin = async (Username, Password) => {
    try {
      // console.log(Username, Password,"login")
      const user = await Auth.signIn(Username, Password);

      localStorage.setItem("user", JSON.stringify(user.username));
      getUser(AppConfig["entityNames"]["questionnaires"], user.username).then(
        (result) => {
          if (result.success) {
            this.props.setUsersdata(result.data);
            this.setState({
              defaultEmptyAddress: true,
              defaultService: true,
            });
            this.hideModal();
          }
        }
      );
    } catch (error) {
      alert(error)
    }
  };

  handleVerify = (event) => {
    let bu = "";
    this.props.form.validateFieldsAndScroll(["name"], (err, values) => {
      if (!err) {
        bu = values["name"];
      }
    });
    event.preventDefault();
    let data = {
      businessName: bu,
    };
    if (bu === "") {
      alert("Please enter your Business name ");
    } else {
      verifyName(AppConfig["entityNames"]["questionnaires"], data).then(
        (res) => {
          if (res.data.statusCode === 0) {
            alert(res.data.message);
          } else {
            alert("Business name doesn't exist, please sign up");
          }
        }
      );
    }
    this.setState({
      loading: false,
    });
  };

  handlePhone = (e) => {
    let phoneNumber = e.target.value.replace(/[^+0-9]/g, "").length;
    this.setState({
      phone: phoneNumber,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { defaultEmptyAddress } = this.state;
    const { formData } = this.props;
    const { Option } = Select;

    return (
      <>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Row>
            <Row>
              <Col span={24} style={{ textAlign: "center", marginLeft: "-3%" }}>
                <h3>
                  If you have already registered your business with us. Please
                  <a
                    href="/#"
                    onClick={this.showModal}
                    style={{ color: "#165874", padding: "5px" }}
                  >
                    {" "}
                    <b> Click here</b>{" "}
                  </a>
                  to sign-in
                </h3>
              </Col>
            </Row>
            <Row>
              <Col
                xl={{ span: 8, offset: 8 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                lg={{ span: 10, offset: 7 }}
                md={{ span: 12, offset: 6 }}
              >
                {" "}
                <h4>
                  <b>Business Name</b>
                </h4>
                <Form.Item className="Formitem" name="name">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your Business Name!",
                      },
                    ],
                    initialValue: formData.businessName,
                  })(
                    <Input
                      className="input_width1"
                      style={{
                        borderRadius: "50px",
                        // width: "400px",
                        height: "40px",
                        padding: "10px",
                      }}
                    />
                  )}
                  <span
                    onClick={this.handleVerify}
                    style={{
                      color: "#165874",
                      cursor: "pointer",
                      padding: "10px",
                    }}
                  >
                    <b> Verify</b>
                  </span>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              {defaultEmptyAddress && (
                <Col
                  xl={{ span: 8, offset: 8 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  lg={{ span: 10, offset: 7 }}
                  md={{ span: 12, offset: 6 }}
                >
                  {" "}
                  <h4>
                    <b>Address</b>
                    <span
                      style={{
                        marginLeft: "5px",
                        color: "#165874",
                        fontSize: "12px",
                      }}
                    >
                      <b>
                        {" "}
                        *We are currently serving businesses only in Connecticut{" "}
                      </b>
                    </span>
                  </h4>
                  <Form.Item name="address" className="input_width">
                    {getFieldDecorator("address", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your Address!",
                        },
                      ],
                      initialValue: formData.address,
                    })(
                      <Input
                        className="input_width1"
                        style={{
                          borderRadius: "50px",
                          // width: "400px",
                          height: "40px",
                          padding: "10px",
                        }}
                      />

                      // <LocationAutocomplete
                      //   getAddress={(address, latLng) =>
                      //     this.getAddress(address, latLng, "address")
                      //   }
                      //   address={formData.address}
                      //   editModeAuto={true}
                      //   setAddress={(address) => {
                      //     this.setState({ selectedAddress: address });
                      //   }}
                      //   defaultValue={formData.address}
                      //   // getNewAddress={this.handleState}
                      //   className="ant-input"
                      // />
                    )}
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              <Col
                xl={{ span: 8, offset: 8 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                lg={{ span: 10, offset: 7 }}
                md={{ span: 12, offset: 6 }}
              >
                <h4>
                  <b>City</b>
                </h4>
                <Form.Item className="Formitem" name="city">
                  {getFieldDecorator("city", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your City!",
                      },
                    ],
                    initialValue: formData.city,
                  })(
                    <Input
                      className="input_width1"
                      style={{
                        borderRadius: "50px",
                        // width: "400px",
                        height: "40px",
                        padding: "10px",
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                xl={{ span: 8, offset: 8 }}
                lg={{ span: 10, offset: 7 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                md={{ span: 12, offset: 6 }}
                className="input_width_state"
              >
                <Col
                  xl={{ span: 11 }}
                  lg={{ span: 11 }}
                  xs={{ span: 11 }}
                  sm={{ span: 11 }}
                  md={{ span: 11 }}
                >
                  <h4>
                    <b>State</b>
                  </h4>
                  <Form.Item className="Formitem" name="state">
                    {getFieldDecorator("state", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your State!",
                        },
                      ],
                      initialValue: formData.place,
                    })(
                      <Input
                        // className="input_width_zip"
                        // size="large"
                        style={{
                          borderRadius: "50px",
                          // width: "200px",
                          height: "40px",
                          padding: "10px",
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col
                  xl={{ span: 11, offset: 2 }}
                  lg={{ span: 11, offset: 2 }}
                  xs={{ span: 11, offset: 1 }}
                  sm={{ span: 11, offset: 1 }}
                  md={{ span: 11, offset: 1 }}
                >
                  <h4>
                    <b>Zip Code</b>
                  </h4>
                  <Form.Item className="Formitem" name="code">
                    {getFieldDecorator("code", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your Zip Code!",
                        },
                      ],
                      initialValue: formData.zip,
                    })(
                      <Input
                        // className="input_width_zip"
                        // size="large"
                        style={{
                          borderRadius: "50px",
                          // width: "200px",
                          height: "40px",
                          padding: "10px",
                        }}
                      />
                      // <Input
                      //    size="large"
                      //    style={{
                      //     borderRadius: "50px",

                      //   }}
                      // />
                    )}
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col
                xl={{ span: 8, offset: 8 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 10, offset: 7 }}
              >
                <h4>
                  <b> Industry</b>
                </h4>
                <Form.Item className="Formitem" name="industry">
                  {getFieldDecorator("industry", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your industry!",
                      },
                    ],
                    initialValue: formData.industry,
                  })(
                    <Select
                      className="input_industry"
                      // className="input-a"
                      style={{
                        borderRadius: "50px",
                        // width: "300px",
                        height: "40px",
                        // padding: "10px",
                      }}
                    >
                      <Option value="Automotive">Automotive</Option>
                      <Option value="Business Loans">Business Loans</Option>
                      <Option value="Casino/Gambling">Casino/Gambling</Option>
                      <Option value="Education">Education</Option>
                      <Option value="Entertainment">Entertainment</Option>
                      <Option value="Finance">Finance</Option>
                      <Option value="Government">Government</Option>
                      <Option value="HealthCare">HealthCare</Option>
                      <Option value="Health/Wellness">Health/Wellness</Option>
                      <Option value="Home Improvement">Home Improvement</Option>
                      <Option value="Insurance">Insurance</Option>
                      <Option value="Law">Law</Option>
                      <Option value="Non for Profit">Non for Profit</Option>
                      <Option value="Personal Loans">Personal Loans</Option>
                      <Option value="Political">Political</Option>
                      <Option value="Restaurants">Restaurants</Option>
                      <Option value="Retail">Retail</Option>
                      <Option value="Real Estate">Real Estate</Option>
                      <Option value="Service">Service</Option>
                      <Option value="Tourism">Tourism</Option>
                      <Option value="Supermarkets/Grocery">
                        Supermarkets/Grocery
                      </Option>
                      <Option value="Food and Beverage">
                        Food and Beverage
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                xl={{ span: 8, offset: 8 }}
                sm={{ span: 22, offset: 1 }}
                md={{ span: 12, offset: 6 }}
                xs={{ span: 22, offset: 1 }}
                lg={{ span: 10, offset: 7 }}
              >
                <h4 style={{ fontWeight: "bold" }}>Business Classification</h4>
                <Form.Item className="Formitems" name="businessClassification">
                  {getFieldDecorator("businessClassification", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your Business Classification!",
                      },
                    ],
                    initialValue: formData.businessClassification,
                  })(
                    <Select
                      className="input_width"
                      style={{
                        borderRadius: "50px",
                        height: "40px",
                      }}
                    >
                      <Option value="Sole Proprietor">Sole Proprietor</Option>
                      <Option value="LLC">LLC</Option>
                      <Option value="LLP">LLP</Option>
                      <Option value="C or S">C or S</Option>
                      <Option value="Non for Profit">Non for Profit</Option>
                      <Option value="Government">Government</Option>
                      <Option value="Political">Political</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Row>
                <Col
                  xl={{ span: 8, offset: 8 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  md={{ span: 12, offset: 6 }}
                  lg={{ span: 10, offset: 7 }}
                >
                  <h4>
                    <b>Contact person email</b>
                  </h4>
                  <Form.Item className="Formitem" name="email">
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          type: "email",
                          message: "This is not a valid Email Id",
                        },
                        {
                          required: true,
                          message: "Please input contact person email ID!",
                        },
                      ],
                      initialValue: formData.email,
                    })(
                      <Input
                        className="input_width1"
                        style={{
                          borderRadius: "50px",
                          // width: "400px",
                          height: "40px",
                          padding: "10px",
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={{ span: 8, offset: 8 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  md={{ span: 12, offset: 6 }}
                  lg={{ span: 10, offset: 7 }}
                >
                  <h4>
                    <b>Contact person phone</b>
                  </h4>
                  <Form.Item className="Formitem" name="person">
                    {getFieldDecorator("person", {
                      rules: [
                        {
                          required: true,

                          message: "Please input Valid phone number",
                        },
                      ],
                      initialValue: formData.phoneNumber,
                    })(
                      <NumberFormat
                        className="input_width"
                        format="+1 (###) ###-####"
                        style={{
                          borderRadius: "50px",
                          // width: "400px",
                          height: "40px",
                          padding: "10px",
                        }}
                        onChange={this.handlePhone}
                      />
                    )}
                    <div>
                      {this.state.phone > 1 && this.state.phone < 12 && (
                        <span style={{ color: "red" }}>
                          This is not a valid phone number
                        </span>
                      )}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ span: 12, offset: 6 }}
                  xl={{ span: 8, offset: 8 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  lg={{ span: 10, offset: 7 }}
                  className="input_width"
                  style={{ textAlign: "center" }}
                >
                  <Form.Item>
                    {/* <Link to="/app/Marketing"> */}
                    <Button
                      key="1"
                      type="primary"
                      loading={this.state.loading}
                      htmlType="submit"
                      className="signup-button"
                      style={{ marginBottom: "10%" }}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Row>
          </Row>
        </Form>
        <>
          <Form
            onSubmit={this.handleSignin}
            style={{ marginBottom: "20%" }}
            id="form"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Modal
              title="SIGN-IN"
              visible={this.state.visible}
              onCancel={this.hideModal}
              footer={[
                <div style={{ height: "35px" }}>
                  {/* <Button type="primary" form="form" style={{ float: "left" }}>
                    Forget password
                  </Button> */}
                  <Button
                    type="primary"
                    htmlType="submit"
                    form="form"
                    style={{ float: "right" }}
                    on
                    loading={this.state.loading}
                  >
                    Log in
                  </Button>
                </div>,
              ]}
            >
              <Form.Item label="Username :" name="username">
                {getFieldDecorator("username", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ],
                })(<Input className="input_modal" />)}
              </Form.Item>

              <Form.Item label="Password" name="password">
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ],
                })(<Input.Password className="input_password" />)}
              </Form.Item>
              <Form.Item name="submit"></Form.Item>
            </Modal>
          </Form>
        </>
      </>
    );
  }
}
export default Form.create({ name: "Step1" })(Step1);
