import React, { Component } from 'react';
import utils from '../utils/Utils';
import Amplify, { Auth } from 'aws-amplify';
import AppConfig from '../Components/config/AppConfig';
// 
Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: `${AppConfig.APP_IDENTITY_POOL_ID}`,
    // REQUIRED - Amazon Cognito Region
    region: `${AppConfig.APP_COGNITO_REGION}`,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: `${AppConfig.APP_COGNITO_USER_POOL_ID}`,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: `${AppConfig.APP_CLIENT_ID}`,
  },
});

export const AppContext = React.createContext('app');

const initialState = {
  user: '',
  userDetails: '',
  isLoading: false,
};

export class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    if (utils.checkCookie('user')) {
      this.setUser(JSON.parse(utils.getCookie('user')), false);
      this.setUserDetails(JSON.parse(utils.getCookie('userDetails')));
    }
    if (initialState.theme !== this.state.theme) {
      this.setThemeLoader(true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.theme !== this.state.theme) {
      this.setThemeLoader(true);
    }
  }


  setUser = (user, remember) => {
    this.setState({ user }, () => {
      utils.setCookie('user', JSON.stringify(this.state.user), remember);
    });
  };

  setLoader=(e)=>{
    this.setState({
      isLoading: e,
    });
  }

  setUserDetails = userDetails => {
    this.setState({ userDetails }, () => {
      utils.setCookie('userDetails', JSON.stringify(this.state.userDetails));
    });
  };

 
  
  dropUser = async() => new Promise(resolve => {
    Auth.signOut();
    this.setState(initialState, () => {
      utils.setCookie('user', '', false);
      utils.setCookie('userDetails', '', false);
    });
    this.setState(initialState, () => {
      resolve();
    });
    localStorage.clear();
  });

  render() {
    const { user, logDate, userDetails, orgDetails, cartData } = this.state;
    return (
      <AppContext.Provider
        value={ {
          state: {
                user, logDate, userDetails, orgDetails, cartData
          },
          setUser: this.setUser,
          dropUser: this.dropUser,
          setUserDetails: this.setUserDetails,
          setOrgDetails: this.setOrgDetails,
          setLoader: this.setLoader,
          setCartData: this.setCartData
        }}

      >
        { this.props.children }
      </AppContext.Provider>
    );
  }
}

export function withAppContext(GivenComponent) {
  return function WrapperComponent(props) {
    return (
      <AppContext.Consumer>
        { state => <GivenComponent { ...props } context={ state } /> }
      </AppContext.Consumer>
    );
  };
}
