import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Progress } from "antd";
// import "antd/dist/antd.css";
// import { Radio } from "antd";
import { update } from "../../Api/Apis";
import AppConfig from ".././config/AppConfig";

class Area extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      selectedRowKeys: [],
      secondstep: "",
      checked: false,
      selecteRedio: true,
      isdeselect: false,
      area: [
        "All Connecticut",
        "Fairfield",
        "Hartford",
        "New Haven",
        "Litchfield",
        "Windham",
        "Tolland",
        "New London",
        "Middlesex",
      ],
    };
  }
  componentDidMount = () => {
    // console.log(this.props.data, "this.props.areadata");
    this.setState({
      selected:
        this.props.areadata && this.props.areadata.length > 0
          ? this.props.areadata
          : this.props.data2.area &&
            this.props.data2.area.length > 0 &&
            typeof this.props.data2.area !== "string"
          ? this.props.data2.area
          : [],
    });
    // if(this.state.selected === "Internet marketing"){

    // }
  };

  handleClick = (e) => {
    const value = e.target.value;
    let { selected, area } = this.state;

    let updatedSelected = [...selected];
 
  if (value === "All Connecticut") {
    if (updatedSelected.includes("All Connecticut")) {    
      updatedSelected = [];
    } else {
      // Select "All Connecticut" and all individual areas
      updatedSelected = ["All Connecticut", ...area.slice(1)];
    }
  } else {
    // Handle individual area selection
    if (updatedSelected.includes(value)) {
      updatedSelected = updatedSelected.filter((item) => item !== value);
    } else {
      updatedSelected.push(value);
    }

    // If all individual areas are selected, also select "All Connecticut"
    const allSelected =
      area.slice(1).every((item) => updatedSelected.includes(item)) &&
      !updatedSelected.includes("All Connecticut");

    if (allSelected) {
      updatedSelected.push("All Connecticut");
    }

    // If any individual area is deselected, remove "All Connecticut"
    if (!area.slice(1).every((item) => updatedSelected.includes(item))) {
      updatedSelected = updatedSelected.filter((item) => item !== "All Connecticut");
    }
  }

  this.setState({ selected: updatedSelected });
 
  };

  onSelectChange = (data) => {
  
    let selected = this.state.selected.length > 0 ? this.state.selected : [];
    if (selected.includes(data.target.value)) {
      let Index = selected.indexOf(data.target.value);
      selected.splice(Index, 1);
      this.setState({
        selected,
       
      });
    } else {
      selected.push(data.target.value);
      this.setState({
        selected,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.selected.length === 0) {
      alert("Please select your options!");
      // console.log(this.state.secondstep);
      return;
    }

    this.setState({
      loading: true,
    });

    // console.log(this.state.selected);
    this.saveRegistrationData(this.state.selected);
  };

  saveRegistrationData = async (selected) => {
    this.props.handleInputs(selected, "/app/Marketing");
    let data = {
      area: selected,
    };

    let id =
      JSON.parse(localStorage.getItem("id")) ||
      JSON.parse(localStorage.getItem("userid"));
    // console.log(id);
    const Data = {
      entityData: data,
    };
    update(AppConfig["entityNames"]["registrations"], id, Data).then(
      (result) => {
        if (result.success) {
          // console.log(result.data.data.id);
          localStorage.setItem("user", JSON.stringify(result.data.data.id));
          // this.props.history.push("/app/Platform");
        }
        if (result.err) {
          alert("your registration failed ");
          this.setState({ loading: false });
        }
      }
    );
  };
  handleRedioMarketing = (data) => {
    const checkValue = this.state.selected.includes(data);
  
    return (
      <div key={data}>
        <label>
          <input
            name={data}
            type="checkbox"
            value={data}
            onChange={this.handleClick}
            className="visually-hidden"
            checked={checkValue}
                      />
         <div className="btn">{data}</div>
        </label>
      </div>
    );
  };  
  // handlePrevious = (e) => {
  //   e.preventDefault();
  //   this.window.history.back();
  // };
  render() {
    const { area } = this.state;
    // const Selection = {
    //   selectedKeys,
    //   onChange: this.onSelectChange,
    // };
    // const { areadata, isDeslected } = this.props;
    // const history = useHistory();

    // console.log(this.state.selected, "resdfghjk");
    // console.log(this.props.data);
    return (
      <Row>
        <div className="progress-mobile">
          <Progress
            percent={this.props.count * 10}
            size="small"
            status="active"
          />
        </div>
        <Col span={24}>
          <div style={{ textAlign: "center", marginTop: "100px" }}>
            <h1 style={{ fontWeight: "bold" }}>Which counties in Connecticut do you provide services in?</h1>
          </div>
        </Col>

        <Col span={24}>
          <Form.Item className="Formitem">
            <p style={{ color: "gray", textAlign: "center" }}>
              select 1 or more
            </p>
          </Form.Item>
        </Col>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col lg={12} md={12} sm={24} xs={24} className="service-area">
            <Form.Item className="Formitem">
              {this.state.area.map((data) => (
                  <div
                    key={data}
                    style={{
                      width: "max-content",
                      float: "left",
                      marginLeft: "10px",
                    }}
                  >
                    {this.handleRedioMarketing(data)}
                  </div>
                ))}
                </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="1"
                type="primary"
                loading={this.state.loading}
                onClick={() =>
                  this.props.handlePrevious(
                    "/app/Signup",
                    "doneRegistration",
                    this.props.data
                  )
                }
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="1"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  fontWeight: "bold",
                  height: "40px",
                }}
                onClick={this.handleCount}
              >
                Next
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Area;
