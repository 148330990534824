import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Stepservice from "./Stepservice";
import Stepage from "./Stepage";
// import Platform from "./Platform";
// import Industry from "./Industry";
// import Agegroup from "./Agegroup";
// import Lifestyle from "./Lifestyle";
// import Services from "./Services";
// import Range from "./Range";
// import Completed from "./Completed";
import { addQuestions } from "../../Api/RegistrationApis";
import { updateQuestion } from "../../Api/Apis";
import AppConfig from "../config/AppConfig";
import Steprange from "./Steprange";
import Companies1 from "./Companies1";
import Companies2 from "./Companies2";
import Payment from "./Payment";
import Stepdemograph from "./Stepdemograph";
import Stepethic from "./Stepethic";
import Auth from "@aws-amplify/auth";
// import Myleads from "../Leads/Myleads";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      formFields: {},
      loading: false,
      isloading: false,
      formData: {},
    };
  }

  componentDidMount = () => {
    let formData = JSON.parse(localStorage.getItem("formData"));
    let current = 0;
    // if (formData) {
    //   if (Object.keys(formData["first"]).length > 0) {
    //     current = 1;
    //   }
    //   if (Object.keys(formData["second"]).length > 0) {
    //     current = 2;
    //   }
    //   if (Object.keys(formData["third"]).length > 0) {
    //     current = 3;
    //   }
    // this.setState({
    //   current: current,
    //   formData: formData,
    // });
    // }
  };

  doRegister = async (data, incre) => {
    this.setState({
      loading: true
    })
    let formData = { ...this.state.formData, ...data };
    let entityData = { entityData: formData };
    addQuestions(AppConfig["entityNames"]["questionnaires"], entityData).then(
      (result) => {
        if (result.success) {
          Auth.signIn(formData["email"], result.data.password)
            .then(res=>{
              localStorage.setItem("user", JSON.stringify(res.username));
            })
          if (result.data.statusCode === 0) {
            alert(result.data.message);
            console.log(data);
            
            this.setState({
              loading: false,
            });
            return;
          }
          if (result.data.code === "UsernameExistsException") {
            alert(result.data.message);
            this.setState({
              loading: false,
            });
            return;
          }
          this.setState({
            formData: result.data.data,
            current:
              incre === "yes" ? this.state.current : this.state.current + 1,
            loading: false,
          });
        } else if (result.err) {
          console.log("4");
          this.setState({ loading: true });
        }
        this.setState({
          loading: false,
        });
      }
    );
  };

  updateData = async (data, incre) => {
    console.log(data, "kkkkkkkkkkk");
    updateQuestion(
      AppConfig["entityNames"]["questionnaires"],
      this.state.formData["id"],
      { entityData: data }
    ).then((result) => {
      if (result.success) {
        this.setState({
          formData: result.data.data,
          current:
            incre === "yes" ? this.state.current : this.state.current + 1,
          loading: false,
        });
      }
      if (result.err) {
        alert("your registration failed ");
        this.setState({ loading: false });
      }
    });
  };

  backPage = () => {
    this.setState({
      current: this.state.current - 1,
    });
  };

  setUserData = (data) => {
    this.setState({
      formData: { ...this.state.formData, ...data },
    });
  };

  setFieldsData = (pageNo, key, value) => {
    if (
      pageNo === 1 ||
      pageNo === 2 ||
      pageNo === 3 ||
      pageNo === 4 ||
      pageNo === 5 ||
      pageNo === 6 ||
      pageNo === 7 ||
      pageNo === 8 ||
      pageNo === 9 ||
      pageNo === 10 ||
      pageNo === 11 ||
      pageNo === 12 ||
      pageNo === 13
    ) {
      let fieldData = this.state.formData[key];
      if (fieldData) {
        if (fieldData.indexOf(value) > -1) {
          if (this.state.current !== 10) {
            fieldData =
              fieldData.length > 0 && fieldData.filter((m) => m !== value);
          }
        } else {
          if (this.state.current === 10) {
            fieldData = value;
          } else {
            fieldData.push(value);
          }
        }
      } else {
        if (this.state.current !== 10) {
          fieldData = [];
          fieldData.push(value);
        }
      }
      this.setState({
        formData: { ...this.state.formData, [key]: fieldData },
      });
    }
  };

  gotoPayment = () => {
    this.setState({
      current: this.state.current + 1,
      loading: false,
    });
  };
  gotoMatch = () => {
    this.setState({
      current: this.state.current + 1,
      loading: false,
    });
  };
  render() {
    const current = this.state.current;
    console.log(this.state.formData);
    return (
      <div>
        {current === 0 && (
          <Step1
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            doRegister={(data, loadbutton) => {
              this.doRegister(data, loadbutton);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            setUsersdata={(data) => {
              this.setUserData(data);
            }}
          />
        )}
        {current === 1 && (
          <Stepservice
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            back={() => {
              this.backPage();
            }}
          />
        )}
        {current === 2 && (
          <Step2
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            back={() => {
              this.backPage();
            }}
          />
        )}

        {current === 3 && (
          <Step3
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            back={() => {
              this.backPage();
            }}
          />
        )}
        {current === 4 && (
          <Step4
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            back={() => {
              this.backPage();
            }}
          />
        )}
        {current === 5 && (
          <Step5
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            back={() => {
              this.backPage();
            }}
          />
        )}
        {current === 6 && (
          <Stepdemograph
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            back={() => {
              this.backPage();
            }}
          />
        )}
        {current === 7 && (
          <Stepage
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            back={() => {
              this.backPage();
            }}
          />
        )}
        {current === 8 && (
          <Stepethic
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            back={() => {
              this.backPage();
            }}
          />
        )}

        {/* {current === 6 && (
          <Step6
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            back={() => {
              this.backPage();
            }}
          />
        )} */}
        {current === 9 && (
          <Step7
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            back={() => {
              this.backPage();
            }}
          />
        )}
        {current === 10 && (
          <Steprange
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            back={() => {
              this.backPage();
            }}
          />
        )}

        {current === 11 && (
          <Step8
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            back={() => {
              this.backPage();
            }}
          />
        )}
        {current === 12 && (
          <Companies1
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            gotoPayment={() => {
              this.gotoPayment();
            }}
            back={() => {
              this.backPage();
            }}
          />
        )}
        {current === 13 && (
          <Payment
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            updateData={(data, loadbutton) => {
              this.updateData(data, loadbutton);
            }}
            back={() => {
              this.backPage();
            }}
            gotoMatch={() => {
              this.gotoMatch();
            }}
          />
        )}
        {current === 14 && (
          <Companies2
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            gotoMatch={() => {
              this.gotoMatch();
            }}
            back={() => {
              this.backPage();
            }}
          />
        )}
        {/* {current === 15 && (
          <Myleads
            formData={this.state.formData}
            loading={this.state.loading}
            isloading={this.state.isloading}
            setFieldsData={(pageNo, key, value) => {
              this.setFieldsData(pageNo, key, value);
            }}
            gotoMatch={() => {
              this.gotoMatch();
            }}
            back={() => {
              this.backPage();
            }}
          />
        )} */}
        {/*{(this.state.current === 2) &&
          <Third increment = {this.increment} loading={this.state.loading} formFields={this.state.formFields} decrement={this.decrement} formData={this.state.formData} doRegister = {(data, formNo) => {this.doRegister(data, formNo)}}/> }
        {(this.state.current === 3) &&
          <Fourth loading={this.state.loading} increment = {this.increment} formFields={this.state.formFields} decrement={this.decrement} formData={this.state.formData} doRegister = {(data, formNo) => {this.doRegister(data, formNo)}} /> }  */}
      </div>
    );
  }
}

export default withRouter(Home);
