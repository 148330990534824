import React, { Component } from "react";
import { Form, Input, Button, Col } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import LogoTransparency from "../../Images/LogoTransparency.png";
// import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import AppConfig from "../config/AppConfig";
import { checkUser } from "../../Api/Apis";
// import Loading from "../Leads/Loading"

class Leadlogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      isLoading: false,
      loadingStatus: false,
      email: "",
      password: "",
      user: "",
      companies: [],
      loading: false,
      newPassword: "",
    };
  }

  handleChange = ({ target }) => {
    this.setState({
      values: (prev) => ({ ...prev, [target.id]: target.value }),
    });
  };
  handleSubmit = async (event, email, password) => {
    this.setState({
      loading: true,
    });
    event.preventDefault();
    this.props.form.validateFieldsAndScroll(
      ["email", "password"],
      (err, values) => {
        console.log(values, "Leadlogin");
        if (!err) {
          this.setState({
            agreement: values.agreement,
          });

          let data = {
            email: values["email"],
            password: values["password"],
          };
          console.log("data", data);
          // console.log(data.email, password, "dataaaaa");
          checkUser(AppConfig["entityNames"]["checkUser"], {
            emailId: encodeURIComponent(data.email),
          }).then((result) => {
            console.log(result.data.message, "resultcheck");
            if (result.data.statusCode === 0) {
              alert(result.data.message);
              this.setState({
                loading: false,
              });
            } else if (result.data.statusCode === 1) {
              Auth.signIn(data.email, data.password)
                .then((result) => {
                  this.setState({
                    loading: false,
                  });
                  console.log(result, "result");
                  localStorage.setItem(
                    "leadId",
                    JSON.stringify(result.username)
                  );
                  if (result) {
                    this.props.history.push("/app/Myleads");
                  } else {
                    console.log(result, "resuuuuuu");
                  }
                })
                .catch((err) => {
                  alert("Incorrect username or password");
                  this.setState({ loading: false });
                });
            }
          });
        }
      }
    );
  };
  handleSignin = (e) => {
    this.setState({
      defaultEmptyAddress: false,
      defaultService: false,
    });
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      ["username", "password"],
      (err, values) => {
        if (!err) {
          this.setState({
            loading: true,
          });
          this.handleSubmit(values["username"], values["password"]);
          this.setState({
            agreement: values.agreement,
          });
        }
      }
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div
        style={{ background: "#eff1f3 0% 0% no-repeat padding-box" }}
        className="admin-login-form"
      >
        {/* <Loading enableLoading={this.state.loading} /> */}
        <div className="login-fields" style={{ height: "386px" }}>
          <Form onSubmit={this.handleSubmit}>
            <div style={{ textAlign: "center", marginBottom: "24px" }}>
              <img
                src={LogoTransparency}
                alt="LogoTransparency"
                style={{ width: "100%" }}
              />
            </div>
            <Form.Item name="email">
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ],
              })(
                <Input
                  prefix={<UserOutlined />}
                  type="text"
                  placeholder="Email address"
                  required
                />
              )}
            </Form.Item>
            <Form.Item name="password">
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ],
              })(
                <Input.Password
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Password"
                  id="password"
                  // onChange={this.handleChange}
                  required
                  // className="form-input"
                />
              )}
            </Form.Item>

            <Form.Item className="password-row">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{
                  backgroundColor: " rgb(22, 88, 116)",
                  border: "none",
                  height: "40px",
                }}
                loading={this.state.loading}
              >
                <span style={{ fontWeight: "500", color: "white" }}>Login</span>
              </Button>
            </Form.Item>
            <Col xs="6" className="text-right">
              <a
                href="/app/ForgotPassword"
                style={{ color: "rgb(22, 88, 116)", fontWeight: "bold" }}
              >
                Forgot password?
              </a>
            </Col>
          </Form>
        </div>
      </div>
    );
  }
}

export default Form.create({ name: "LeadLogin" })(Leadlogin);
