import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Progress } from "antd";
// import "antd/dist/antd.css";
import { Radio } from "antd";
import { update } from "../../Api/Apis";
import AppConfig from "../config/AppConfig";

class Industry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      selectedRowKeys: [],
      fourthstep: "",
      selecteRedio: true,
      isdeselect: false,
    };
  }
  componentDidMount = () => {
    this.setState({
      selected:
        this.props.Industrydata && this.props.Industrydata.length > 0
          ? this.props.Industrydata
          : this.props.data2.industry && this.props.data2.industry.length > 0
          ? this.props.data2.industry
          : [],
    });
  };
  handleClick = (e) => {
    // console.log(e.target.value, "ggggggg");
    // let selected = [];
    // this.state.selected?.splice(this.state.selected.indexOf(e.target.value), 1);
    // console.log(
    //   this.state?.selected,
    //   "selectedt",
    //   this.props?.marketdata,
    //   "markeData"
    // );
    let deleteSelected = this.state.selected?.filter(
      (i) => i === e.target.value
    );
    // console.log(deleteSelected, "hell", this.state.selected);
    if (deleteSelected && deleteSelected.length) {
      deleteSelected.splice(deleteSelected.indexOf(e.target.value), 1);
      this.setState({ selected: deleteSelected, isdeselect: true });
      // console.log(deleteSelected, "dddd");
    }
    // const target = data;

    // console.log(selected, "kkkkk");

    this.props.handleInputs(this.state.selected, "/app/Industry", "deselect");
    this.setState({ selecteRedio: true });
  };
  onSelectChange = (data) => {
    let selected = this.state.selected.length > 0 ? this.state.selected : [];
    if (selected.includes(data.target.value)) {
      let Index = selected.indexOf(data.target.value);
      selected.splice(Index, 1);
      this.setState({
        selected,
      });
    } else {
      selected.push(data.target.value);
      this.setState({
        selected,
      });
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.selected.length === 0) {
      alert("Please select your options!");
      // console.log(this.state.selected);
      return;
    }
    this.setState({
      loading: true,
    });
    // console.log(this.state.selected);
    this.saveRegistrationData(this.state.selected);
  };
  saveRegistrationData = async (industry) => {
    this.props.handleInputs(industry, "/app/Services");
    let data = {
      industry: industry,
    };
    let id =
      JSON.parse(localStorage.getItem("id")) ||
      JSON.parse(localStorage.getItem("userid"));
    // console.log(id);
    const Data = {
      entityData: data,
    };
    update(AppConfig["entityNames"]["registrations"], id, Data).then(
      (result) => {
        if (result.success) {
          // console.log(result.data.data.id);
          localStorage.setItem("user", JSON.stringify(result.data.data.id));
          this.props.history.push("/app/Services");
        }
        if (result.err) {
          alert("your registration failed ");
          this.setState({ loading: false });
        }
      }
    );
  };
  handleRedioIndustry = (data) => {
    let checkValue = this.state.selected.includes(data);
    var selectedValue = checkValue.toString() == "true" ? true : false;

    return (
      // <Radio.Group buttonStyle="solid">
      //   <Radio.Button
      //     value={data}
      //     onChange={this.onSelectChange}
      //     onClick={this.handleClick}
      //     style={{
      //       borderRadius: "50px",
      //       textAlign: "center",
      //       marginLeft: "15px",
      //       backgroundColor: checkValue.toString() == 'true' ? "#1890ff" : "#ffff",
      //       color:checkValue.toString() == 'true' ? "#fff" : "#000",

      //     }}
      //     // checked={selectedValue}
      //   >
      //     {data}
      //   </Radio.Button>
      // </Radio.Group>
      <div>
        <label>
          <input
            name={data}
            type="checkbox"
            value={data}
            onChange={this.onSelectChange}
            onClick={this.handleClick}
            checked={checkValue}
            className="visually-hidden"
          />
          <div className="btn">{data}</div>
        </label>
      </div>
    );
  };
  render() {
    const { selectedKeys } = this.state;

    const Selection = {
      selectedKeys,
      onChange: this.onSelectChange,
    };
    const { Industrydata } = this.props;
    const data = this.state.fourthstep;
    const Industry = [
      "Restaurants",
      "Automotive",
      "Entertainment",
      "Casino/Gambling",
      "Retail",
      "Home Improvement",
      "Insurance",
      "Finance",
      "Personal Loans",
      "Business Loans",
      "Education",
      "Healthcare",
      "Political",
      "Health/Wellness",
      "Law",
      "Real Estate",
      "Non for Profit",
      "Government",
      "Service",
      "Wellness",
      "Tourism",
    ];
    return (
      <Row>
        <div  className="progress-mobile">
          <Progress
            percent={this.props.count * 10}
            size="small"
            status="active"
          />
        </div>
        <Col span={24}>
          <div style={{ marginTop: "100px" }}>
            <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
              What industry do you specialize in?
            </h1>
          </div>
        </Col>

        <Col span={24}>
          <Form.Item className="Formitem">
            <p style={{ color: "gray", textAlign: "center" }}>
              select 1 or more
            </p>
          </Form.Item>
        </Col>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col
            lg={12} md={12} sm={24} xs={24} className="service-area"
          >
            <Form.Item className="Formitem">
              {Industry.map((data) => (
                <div
                  style={{
                    width: "max-content",
                    float: "left",
                    marginLeft: "10px",
                  }}
                >
                  {this.handleRedioIndustry(data)}
                </div>
              ))}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item className="Formitem">
              {/* <Link to="/app/Platform"> */}
              <Button
                key="1"
                type="primary"
                loading={this.state.loading}
                // onClick={ this. window.history.back()}
                onClick={() => this.props.handlePrevious("/app/Platform")}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                  marginBottom: "80px",
                }}
              >
                Previous
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              {/* <Link to="/app/Services"> */}
              <Button
                key="1"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  // marginLeft: "4%",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Industry;
