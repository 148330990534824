import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button } from "antd";

export default class Stepdemograph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteRedio: true,
      isdeselect: false,
      demograph: [
        "Male",
        "Female",
        "Married Couples",
        "Single",
        "Widow",
        "Divorce",
        "Families W/o children",
      ],
    };
  }
  handleClick = (e) => {
    let demograph = this.props?.formData?.demograph;
    let value = e.target.value;
    if (demograph) {
      if (demograph.indexOf(value) > -1) {
        this.props.setFieldsData(6, "demograph", value);
      }
    }
  };
  onSelectChange = (e) => {
    this.props.setFieldsData(6, "demograph", e.target.value);
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.props.formData.demograph === undefined || this.props.formData.demograph.length === 0) {
      alert("Please select your options!");
      return;
    }
    this.props.updateData({"demograph": this.props.formData.demograph});
  };
  handleRedioMarketing = (data) => {
    let checkValue = this.props.formData?.demograph?.includes(data);
    return (
      <div>
        <label>
          <input
            name={data}
            type="checkbox"
            value={data}
            onChange={this.onSelectChange}
            onClick={this.handleClick}
            className="visually-hidden"
            checked={checkValue}
          />
          <div className="btn">{data}</div>
        </label>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "center", marginTop: "100px" }}>
              <h1 style={{ fontWeight: "bold" }}>
                Do you have a specific target demographic that you are trying to
                reach ?
              </h1>
            </div>
          </Col>
          <Col span={24}>
            <Form.Item className="Formitem">
              <p style={{ color: "gray", textAlign: "center" }}>
                select 1 or more
              </p>
            </Form.Item>
          </Col>
          <Form onSubmit={this.handleSubmit}>
            <Col
              xl={{span:18,offset:7}}
              lg={{ span: 20, offset: 4 }}
              md={{ span: 12, offset: 7 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
            >
              <Form.Item className="Formitem">
                {this.state.demograph?.map((data) => (
                  <div
                    style={{
                      width: "max-content",
                      float: "left",
                      marginLeft: "10px",
                    }}
                  >
                    {this.handleRedioMarketing(data)}
                  </div>
                ))}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item className="Formitem">
                <Button
                  key="Previous"
                  type="primary"
                  onClick={() => this.props.back()}
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "#dce7f0",
                    color: "#004b84",
                    width: "150px",
                    border: "none",
                    marginRight: "10px",
                    float: "right",
                    fontWeight: "bold",
                    height: "40px",
                    marginBottom:"80%",
                  }}
                >
                  Previous
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item className="Formitem">
                <Button
                  key="next"
                  type="primary"
                  loading={this.state.loading}
                  htmlType="submit"
                  className="Submit"
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "#dce7f0",
                    color: "#004b84",
                    width: "150px",
                    border: "none",
                    // marginLeft: "4%",
                   
                    fontWeight: "bold",
                    height: "40px",
                  }}
                >
                  Next
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Row>
      </div>
    );
  }
}
