import React, { Component } from "react";
import Marketing from "./Registration/marketing";
import Signup from "./Registration/Signup";
import Platform from "./Registration/Platform";
import Industry from "./Registration/Industry";
import Agegroup from "./Registration/Agegroup";
import Lifestyle from "./Registration/Lifestyle";
import Services from "./Registration/Services";
import Range from "./Registration/Range";
import Completed from "./Registration/Completed";
import Internetmarketing from "../Components/Registration/Internetmarketing";
import Area from "./Registration/Area";
export default class Landingscreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: "",
      selected: "",
      countArea: "",
      countMarket: "",
      countAdvertise: "",
      nextPathaName: "",
      platform: "",
      industry: "",
      services: "",
      lifestyle: "",
      agegroup: "",
      range: "",
      check: "",
      user: "",
      internet: "",
      area: "",
      selectedBtn: "",
      internetData: "",

      // deselected:false
    };
  }
  componentDidMount() {
    let nextPathaName = JSON.parse(localStorage.getItem("path"));
    nextPathaName = nextPathaName ? nextPathaName : "/app/Signup";
    console.log(nextPathaName);
    this.setState({
      nextPathaName,
    });
    if (this.state.user === "") {
      let values = this.state.values;
      this.setState({
        user: values,
      });
    }
    // let loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    // console.log(loginDetails,"loginDetails");
    // if(loginDetails && loginDetails["internet"]){
    //   this.setState({
    //     internet:loginDetails["internet"]
    //   })
    // }
  }
  firstStep = (values, path) => {
    this.setState({
      values,
      nextPathaName: path,
    });
    localStorage.setItem("path", JSON.stringify(path));
  };
  signIn = (user) => {
    this.setState({
      user,
    });
  };

  secondStep = (selected, path, type, countArea) => {
    this.setState({
      selected,
      countArea,
      nextPathaName: path,
      internetData: "",
      // internet:""
      // deselected:true
    });
    if (selected === "Internet marketing") {
      this.setState({
        internetData: selected,
      });
    }
    // if(type === "deselect"){
    //   this.setState({
    // })
    // }
    localStorage.setItem("path2", JSON.stringify(path));
  };
  thirdStep = (platform, path, countMarket, countArea) => {
    this.setState({
      platform,
      nextPathaName: path,
      countMarket,
      countArea,
    });
    localStorage.setItem("path3", JSON.stringify(path));
  };
  forthStep = (industry, path) => {
    this.setState({
      industry,
      nextPathaName: path,
      // let id = JSON.parse(localStorage.getItem("id"));
    });
    localStorage.setItem("path4", JSON.stringify(path));
  };
  fifthStep = (services, selected1, path) => {
    this.setState({
      services,
      selectedBtn: selected1,
      nextPathaName: path,
    });
    localStorage.setItem("path5", JSON.stringify(path));
  };
  sixthStep = (lifestyle, path) => {
    this.setState({
      lifestyle,
      nextPathaName: path,
    });
    localStorage.setItem("path6", JSON.stringify(path));
  };
  seventhStep = (agegroup, path) => {
    this.setState({
      agegroup,
      nextPathaName: path,
    });
    localStorage.setItem("path7", JSON.stringify(path));
  };
  eightStep = (range, path) => {
    this.setState({
      range,
      nextPathaName: path,
    });
    localStorage.setItem("path8", JSON.stringify(path));
  };
  ninthStep = (internet, path, selected) => {
    this.setState({
      internet,
      selected,
      nextPathaName: path,
    });
    localStorage.setItem("path9", JSON.stringify(path));
  };
  tenthStep = (area, path) => {
    this.setState({
      area,
      nextPathaName: path,
    });
    localStorage.setItem("path10", JSON.stringify(path));
  };
  previousPage = (nextPathaName, check, countArea, countMarket) => {
    this.setState({
      nextPathaName,
      check,
      countArea,
      countMarket,
      // values
    });
    console.log(check);

    localStorage.setItem("path", JSON.stringify(nextPathaName));

    // this.props.history.push("/app/Signup");
  };
  render() {
    const { nextPathaName, deselected, check, internetData } = this.state;
    // console.log(this.state.values, "kkkkk", nextPathaName);
    // console.log(this.state.user);
    console.log(this.state.internet, "interrr");

    const businessName = this.state.values.businessName;
    // console.log(businessName);
    return (
      <div>
        {nextPathaName === "/app/Signup" && (
          <Signup
            handleInputs={this.firstStep}
            handleSigninData={this.signIn}
            data2={this.state.user}
            data={this.state.values}
            isRegister={check}
          />
        )}
        {nextPathaName === "/app/Area" && (
          <Area
            handleInputs={this.tenthStep}
            handlePrevious={this.previousPage}
            countArea={this.state.countArea}
            countMarket={this.state.countMarket}
            areadata={this.state.area}
            data2={this.state.user}
            data={this.state.values}
            isDeslected={deselected}
            count={0}
          />
        )}
        {nextPathaName === "/app/Marketing" && (
          <Marketing
            handleInputs={this.secondStep}
            handlePrevious={this.previousPage}
            marketdata={this.state.selected}
            countArea={this.state.countArea}
            countMarket={this.state.countMarket}
            data2={this.state.user}
            internet={this.state.internet}
            data={this.state.values}
            isDeslected={deselected}
            count={1}
            internetData={internetData}
          />
        )}
        {nextPathaName === "/app/Internetmarketing" && (
          <>
            <Internetmarketing
              handleInputs={this.ninthStep}
              handlePrevious={this.previousPage}
              // marketdata={this.state.internet}
              internet={this.state.internet}
              data2={this.state.user}
              isDeslected={deselected}
              count={2}
            />
          </>
        )}
        {nextPathaName === "/app/Platform" && (
          <Platform
            handleInputs={this.thirdStep}
            handlePrevious={this.previousPage}
            Platformdata={this.state.platform}
            countMarket={this.state.countMarket}
            data2={this.state.user}
            internet={this.state.internet}
            isDeslected={deselected}
            count={3}
            internetData={internetData}
          />
        )}
        {nextPathaName === "/app/Industry" && (
          <Industry
            handleInputs={this.forthStep}
            handlePrevious={this.previousPage}
            data2={this.state.user}
            Industrydata={this.state.industry}
            isDeslected={deselected}
            count={4}
          />
        )}
        {nextPathaName === "/app/Services" && (
          <Services
            handleInputs={this.fifthStep}
            handlePrevious={this.previousPage}
            data2={this.state.user}
            services={this.state.services}
            isDeslected={deselected}
            selectedBtn={this.state.selectedBtn}
            count={5}
          />
        )}
        {nextPathaName === "/app/Lifestyle" && (
          <Lifestyle
            handleInputs={this.sixthStep}
            handlePrevious={this.previousPage}
            lifestyledata={this.state.lifestyle}
            data2={this.state.user}
            isDeslected={deselected}
            count={6}
          />
        )}
        {nextPathaName === "/app/Agegroup" && (
          <Agegroup
            handleInputs={this.seventhStep}
            handlePrevious={this.previousPage}
            data2={this.state.user}
            agegroupdata={this.state.agegroup}
            isDeslected={deselected}
            count={7}
          />
        )}
        {nextPathaName === "/app/Range" && (
          <Range
            handleInputs={this.eightStep}
            handlePrevious={this.previousPage}
            data2={this.state.user}
            rangedata={this.state.range}
            isDeslected={deselected}
            count={8}
          />
        )}
        {nextPathaName === "/app/Completed" && (
          <Completed
            handleInputs={this.eightStep}
            data2={this.state.user}
            data={businessName}
            handlePrevious={this.previousPage}
            count={9}
          />
        )}
      </div>
    );
  }
}
