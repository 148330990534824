import React, { Component } from "react";
import BottomNavigation from "reactjs-bottom-navigation";
import "reactjs-bottom-navigation/dist/index.css";
import {
  HomeOutlined,
  TeamOutlined,
  UserSwitchOutlined,
  ShopOutlined,
  CloseOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { Row, Col, Button, Drawer } from "antd";

export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableMoreOptions: false,
      enableHomeOptions: false,
    };
  }

  onClickMenu = (menu) => {
    window.location.replace(menu);
  };

  render() {
    const { enableMoreOptions, enableHomeOptions } = this.state;
    const bottomNavItems = [
      {
        title: "Homes",
        icon: <HomeOutlined style={{ fontSize: "24px", cursor: "pointer" }} />,
        activeIcon: (
          <HomeOutlined
            style={{ fontSize: "24px", color: "#165874", cursor: "pointer" }}
          />
        ),
        onClick: () => {
          this.onClickMenu("/");
        },
      },
      // {
      //   title: "Business",
      //   icon: (
      //     <DollarCircleOutlined
      //       style={{ fontSize: "24px", cursor: "pointer" }}
      //     />
      //   ),
      //   activeIcon: (
      //     <DollarCircleOutlined
      //       style={{ fontSize: "24px", color: "#165874", cursor: "pointer" }}
      //     />
      //   ),
      //   onClick: () => {
      //     this.onClickMenu("/business");
      //   },
      // },
      {
        title: "Marketing/Media",
        icon: <ShopOutlined style={{ fontSize: "24px", cursor: "pointer" }} />,
        activeIcon: (
          <ShopOutlined
            style={{ fontSize: "24px", color: "#165874", cursor: "pointer" }}
          />
        ),
        onClick: () => {
          this.onClickMenu("/app/Media");
        },
      },

      {
        title: " About Us",
        icon: <TeamOutlined style={{ fontSize: "24px", cursor: "pointer" }} />,
        activeIcon: (
          <TeamOutlined
            style={{ fontSize: "24px", color: "#165874", cursor: "pointer" }}
          />
        ),
        onClick: () => {
          this.onClickMenu("/app/About");
        },
      },

      {
        title: "Contact",
        icon: (
          <UserSwitchOutlined style={{ fontSize: "24px", cursor: "pointer" }} />
        ),
        activeIcon: (
          <UserSwitchOutlined
            style={{ fontSize: "24px", color: "#165874", cursor: "pointer" }}
          />
        ),
        onClick: () => {
          this.onClickMenu("/app/Contact");
        },
      },
    ];

    return (
      <div>
        <Drawer
          title={null}
          placement="bottom"
          height={120}
          // mask={true}
          closable={false}
          getContainer={false}
          onClose={() => () =>
            this.setState({
              enableHomeOptions: false,
            })}
          visible={enableHomeOptions}
          headerStyle={{ margin: "10%" }}
          bodyStyle={{
            paddingBottom: 100,
          }}
        >
          <Row
            align="middle"
            justify="space-between"
            style={{
              padding: "10px",
              borderBottom: "1px solid #ccc",
            }}
          >
            <Col span={8}>
              <Button
                style={{
                  backgroundColor:
                    window.location.pathname === "/" ? "#165874" : "#FFF",
                  color: window.location.pathname === "/" ? "#FFF" : "#165874",
                  width: "100%",
                }}
                onClick={() => {
                  this.onClickMenu("/");
                }}
              >
                Home
              </Button>
            </Col>
          </Row>
        </Drawer>

        <Drawer
          title={null}
          placement="bottom"
          height={160}
          // mask={true}
          closable={false}
          getContainer={false}
          onClose={() => () =>
            this.setState({
              enableMoreOptions: false,
            })}
          visible={enableMoreOptions}
          headerStyle={{ margin: "10%" }}
          bodyStyle={{
            paddingBottom: 80,
          }}
        >
          <Row
            align="middle"
            justify="space-between"
            style={{ padding: "10px", borderBottom: "1px solid #ccc" }}
          >
            <div>
              <h4>Business</h4>
            </div>
            <div
              onClick={() => {
                this.setState({
                  enableMoreOptions: false,
                });
              }}
            >
              <CloseOutlined style={{ fontSize: "18px" }} />
            </div>
          </Row>

          <Col span={24} style={{ margin: "10px" }}>
            <Row>
              <Col span={8}>
                <Button
                  style={{
                    backgroundColor:
                      window.location.pathname === "/app/Media"
                        ? "#165874"
                        : "#FFF",
                    color:
                      window.location.pathname === "/app/Media"
                        ? "#FFF"
                        : "#165874",
                    width: "100%",
                  }}
                  onClick={() => {
                    this.onClickMenu("/app/Media");
                  }}
                >
                  Marketing/Media
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  style={{
                    backgroundColor:
                      window.location.pathname === "/app/About"
                        ? "#165874"
                        : "#FFF",

                    color:
                      window.location.pathname === "/app/About"
                        ? "#FFF"
                        : "#165874",
                    width: "100%",
                  }}
                  onClick={() => {
                    this.onClickMenu("/app/About");
                  }}
                >
                  About Us
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  style={{
                    backgroundColor:
                      window.location.pathname === "/contactUs"
                        ? "#165874"
                        : "#FFF",

                    color:
                      window.location.pathname === "/contactUs"
                        ? "#FFF"
                        : "#165874",
                    width: "100%",
                  }}
                  onClick={() => {
                    this.onClickMenu("/contactUs");
                  }}
                >
                  Contact Us
                </Button>
              </Col>
            </Row>
          </Col>
        </Drawer>

        <BottomNavigation
          items={bottomNavItems}
          defaultSelected={
            window.location.pathname === "/"
              ? 0
              : window.location.pathname === "/"
              ? 1
              : window.location.pathname === "/app/Media"
              ? 2
              : window.location.pathname === "/app/About"
              ? 3
              : window.location.pathname === "/contactUs"
              ? 4
              : ""
          }
          // activeBgColor="#fc572b"
          color="#165874"
          activeBgColor="#fff"
          activeTextColor='#165874'
          onItemClick={(item) => console.log(item)}
        />
      </div>
    );
  }
}
