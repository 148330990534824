
import React, { Component } from "react";
// import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

import Authenticated from "../src/Components/Authenticated";
// import { withAppContext } from "./store/contextStore";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" component={Authenticated} />
        </Switch>
      </Router>
    );
  }
}

export default App;
