import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button } from "antd";


class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteRedio: true,
      isdeselect: false,
      marketing: [
        "Introduce product/services",
        "Branding/brand building",
        "Awareness creation",
        "Stand out from the competition",
        "Increase traffic to place of business",
        "Increase traffic to website",
        "Positioning",
        "Increase profitability",
        "Completion of online forms/surveys",
        "Call to action",
        "Create a desire/need/want for your products or services", 
      ],
    };
  }

  handleClick = (e) => {
    let marketing = this.props.formData.marketing;
    let value = e.target.value;
    if (marketing) {
      if (marketing.indexOf(value) > -1) {
        this.props.setFieldsData(2, "marketing", value);
      }
    }
  };

  onSelectChange = (e) => {
    this.props.setFieldsData(2, "marketing", e.target.value);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (
      this.props.formData.marketing === undefined ||
      this.props.formData.marketing.length === 0
    ) {
      alert("Please select your options!");
      return;
    }
    this.props.updateData({ "marketing": this.props.formData.marketing });
  };

  handleRedioMarketing = (data) => {
    let checkValue = this.props.formData.marketing?.includes(data);
    return (
      <div>
        <label>
          <input
            name={data}
            type="checkbox"
            value={data}
            onChange={this.onSelectChange}
            onClick={this.handleClick}
            className="visually-hidden"
            checked={checkValue}
          />
          <div className="btn">{data}</div>
        </label>
      </div>
    );
  };

  render() {
    return (
      <Row>
        <Col span={24}>
          <div style={{ textAlign: "center",marginTop: "100px" }}>
            <h1 style={{ fontWeight: "bold" }}>
              What objectives do you expect your marketing campaign to meet?
            </h1>
          </div>
        </Col>

        <Col span={24}>
          <Form.Item className="Formitem">
            <p style={{ color: "gray", textAlign: "center" }}>
              select 1 or more
            </p>
          </Form.Item>
        </Col>
        <Form onSubmit={this.handleSubmit} >
          <Col lg={12} md={12} sm={24} xs={24} className="service-area" >
            <Form.Item className="Formitem">
              {this.state.marketing?.map((data) => (
                <div
                  style={{
                    width: "max-content",
                    float: "left",
                    marginLeft: "10px",
                  }}
                >
                  {this.handleRedioMarketing(data)}
                </div>
              ))}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="Previous"
                type="primary"
                onClick={() => this.props.back()}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                  marginBottom:"80%",
                }}
              >
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="next"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  // marginLeft: "4%",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Step2;
