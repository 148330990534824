import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Card } from "antd";
import AppConfig from "../config/AppConfig";
import { MailOutlined } from "@ant-design/icons";
import { getCompanie } from "../../Api/Apis";
import { addEmail } from "../../Api/RegistrationApis";
// import "antd/dist/antd.css";
class Companies2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      List: ["9"],
      selectedRowKeys: [],
      companies: [],
      loading: false,
      matchingList: "",
      matchEmail: "",
      industry: "",
      ageGroup: "",
      rangeGoals: "",
    };
  }
  componentDidMount = () => {
    let id = JSON.parse(localStorage.getItem("payment"));
    console.log(id);
    getCompanie(
      AppConfig["entityNames"]["getCompanies"],
      this.props.formData.id
    ).then((result) => {
      if (result.success) {
        this.saveCompaniesData(result.data);
        this.setState({
          matchingList: result.data,
        });
      }
    });

    this.saveCompaniesData = async (user) => {
      // console.log(data)
      this.setState({
        companies: user,
      });

      localStorage.setItem("compines ", JSON.stringify(user));
      this.setState({
        loading: false,
      });
    };
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      loading: true,
    });
    // if (this.props.formData.id) {
    //   // localStorage.setItem("Step8",JSON.stringify(this.props.formData.id))
    //   this.props.gotoMatch();
    // } else {
    //   this.props.doRegister();
    // }
    const data = {
      entityData: {
        matchingList: this.state.matchingList,
        matchEmail: this.props.formData.email,
        industry: this.props.formData.industry,
        ageGroup: this.props.formData.agegroup,
        rangeGoals: this.props.formData.rangeGoals,
      },
    };
    console.log(data, "data");
    addEmail(AppConfig["entityNames"]["sendMail"], data).then((result) => {
      console.log(result, "result");
      if (result.success) {
        alert("Email sent successfully");
        // window.location.assign("/")
        // this.props.history.push("/")
      }
    });
  };
  render() {
    // console.log(this.state.List);
    const { formData } = this.props;
    let companiesdata = this.state.companies;

    let lifestyl = companiesdata.services ? companiesdata.services : [];
    console.log(lifestyl);
    let selectedAgeGroup = [];
    companiesdata &&
      companiesdata?.map((e) => {
        let compAge = e.ageGroup.filter((ca) => formData.agegroup.includes(ca));

        if (!selectedAgeGroup.includes(compAge[0])) {
          selectedAgeGroup.push(compAge[0]);
        }
      });

    console.log(companiesdata);

    return (
      <Row>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col span={24}>
            <div style={{ textAlign: "center", marginTop: "100px" }}>
              <h1 style={{ fontWeight: "bold" }}>
                We found {companiesdata.length} companies who <br />
                can help you based on your search criteria
              </h1>
            </div>
          </Col>
          <Col span={12} style={{ marginLeft: "26%" }}>
            <Row>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <span style={{ fontSize: "18px" }}>
                  <b>Industry</b>
                </span>
                <Row>
                  <h4>{formData.industry}</h4>
                </Row>
              </Col>

              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <span style={{ fontSize: "18px" }}>
                  <b>Age Group</b>
                </span>
                <Row>
                  <h4>
                    {formData.agegroup.map((age) => (
                      <span>
                        {age}
                        <br />
                      </span>
                    ))}
                  </h4>
                </Row>
                {/* <div>
                    {selectedAgeGroup.map((age) => (
                      <span>
                        {age}
                        <br />
                      </span>
                    ))}
                  </div> */}
              </Col>
              <Col
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <span style={{ fontSize: "18px" }}>
                  <b>Budget </b>
                </span>
                <Row>
                  <h4>{formData.rangeGoals}</h4>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <p
              style={{
                color: "gray",
                textAlign: "center",
                lineHeight: "20px",
              }}
            >
              You can expect to be contacted by agencies who <br /> specialize
              in your industry.
            </p>
          </Col>
          <Col lg={12} md={12} sm={19} xs={19} style={{ marginLeft: "45%" }}>
            <Col span={12}>
              {/* <Link to="/app/Companies"> */}
              <Button
                key="1"
                type="primary"
                // loading={this.state.loading}
                htmlType="submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  fontSize: "13px",
                  width: "185px",
                  border: "none",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                <MailOutlined />
                Email me this list
              </Button>
              {/* </Link> */}
            </Col>
          </Col>

          <Col span={20} offset={2}>
            <Card
              className="ant-card-body"
              style={{ marginTop: "2vw", borderRadius: "10px" }}
            >
              {companiesdata.map((data) => (
                <Row>
                  <Col
                    xxl={{ span: 8, offset: 4 }}
                    xl={{ span: 11,offset:1 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 12 }}
                  >
                    <span >
                      <b>Contact Person Name</b>
                    </span>
                    <Row>
                      <div style={{fontSize:"15px",color:"black"}}>{data.businessName}</div>
                    </Row>
                    <Row>
                      {data.area.length > 0 &&
                        data.area.map(
                          (style) => (
                            <Button
                              style={{
                                backgroundColor: "#9baebc",
                                color: "white",
                                fontSize: "15px",
                                border: "none",
                                marginRight: "5px",
                              }}
                            >
                              {/* {companiesdata.platform} */}
                              {style}
                            </Button>
                          )
                          // );
                        )}
                    </Row>
                  </Col>

                  <Col
                   xxl={{ span: 10, offset: 2 }}
                   xl={{ span: 10, offset: 2 }}
                    lg={{ span: 12 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 12 }}
                  >
                    <span >
                      <b>Contact Person Details</b>
                    </span>
                    <Row>
                      <div style={{fontSize:"15px",color:"black"}}>{data.contactPerson}</div>
                    </Row>
                    <Row>
                      <div style={{fontSize:"15px",color:"black"}}>{data.phoneNumber}</div>
                    </Row>
                    <Row>
                      <div style={{fontSize:"15px",color:"black"}}>{data.email}</div>
                    </Row>
                  </Col>
                </Row>
              ))}
            </Card>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Companies2;
