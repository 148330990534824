import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Progress } from "antd";
// import "antd/dist/antd.css";
// import { Radio } from "antd";
import { update } from "../../Api/Apis";
import AppConfig from ".././config/AppConfig";
// import { Link } from "react-router-dom";
class Internetmarketing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      selectedRowKeys: [],
      secondstep: "",
      checked: false,
      selecteRedio: true,
      isdeselect: false,
      marketing: [
        "Search engine optimization (SEO)",
        "Pay-per-click (PPC) advertising",

        "Geofencing services",
        "Website maintenance",

        "Review management",
        "Content marketing",
        "Web design and development",
        "Conversion rate optimization (CRO)",
        "Email marketing",
        "Programmatic advertising services",
        "Social media management and social media advertising",
      ],
    };
  }
  componentDidMount = () => {
    console.log(this.props, "l;l;l");
    
    this.setState({
      selected:
        this.props.internet && this.props.internet?.length > 0
          ? this.props.internet
          : this.props.data2?.internet &&
            this.props.data2.internet?.length > 0
          ? this.props.data2?.internet
          : [],
    });
    // if(this.state.selected === "Internet marketing"){

    // }
  };
  handleSubmit = (event) => {
    event.preventDefault();

    const selectMar = this.state.marketing.filter((eachMar) =>
      this.state.selected.includes(eachMar)
    );
    if (selectMar.length === 0) {
      alert("Please select your options!");
      return;
    }

    this.setState({
      loading: true,
    });

    console.log(this.state.selected);
    this.saveRegistrationData(this.state.selected);
  };

  saveRegistrationData = async (selected) => {
    // this.props.handleInputs(selected, "/app/Platform");
    let data = {
      internet: selected,
    };
    // console.log(data)
    let id =
      JSON.parse(localStorage.getItem("id")) ||
      JSON.parse(localStorage.getItem("userid"));
    // console.log(id);
    const Data = {
      entityData: data,
    };
    update(AppConfig["entityNames"]["registrations"], id, Data).then(
      (result) => {
        if (result.success) {
          console.log(result.data,"result internet");
          localStorage.setItem("user", JSON.stringify(result.data.data.id));
          this.props.handleInputs(this.state.selected, "/app/Platform");
        }
        if (result.err) {
          alert("your registration failed ");
          this.setState({ loading: false });
        }
      }
    );
  };
  handleRedioMarketing = (data) => {
    // console.log(data,this.state.selected);
    let checkValue = this.state.selected.includes(data);
    var selectedValue = checkValue.toString() == "true" ? true : false;
    // console.log(checkValue)
    return (
      // <Radio.Group buttonStyle="solid">
      //   <Radio.Button
      //     value={data}
      //     onChange={this.onSelectChange}
      //     onClick={this.handleClick}
      //     style={{
      //       borderRadius: "50px",
      //       textAlign: "center",
      //       marginLeft: "15px",
      //       backgroundColor: checkValue.toString() == 'true' ? "#1890ff" : "#ffff",
      //       color:checkValue.toString() == 'true' ? "#fff" : "#000",

      //     }}
      //     // checked={selectedValue}
      //   >
      //     {data}
      //   </Radio.Button>
      // </Radio.Group>
      <div>
        <label>
          <input
            name={data}
            type="checkbox"
            value={data}
            onChange={this.onSelectChange}
            onClick={this.handleClick}
            className="visually-hidden"
            checked={checkValue}
          />
          <div className="btn">{data}</div>
        </label>
      </div>
    );
  };
  handlePrevious = (e) => {
    e.preventDefault();
    this.window.history.back();
  };
  onSelectChange = (data) => {
    let selected = this.state.selected.length > 0 ? this.state.selected : [];
    if (selected.includes(data.target.value)) {
      let Index = selected.indexOf(data.target.value);
      selected.splice(Index, 1);
      this.setState({
        selected,
      });
    } else {
      selected.push(data.target.value);
      this.setState({
        selected,
      });
    }
  };

  handleClick = (e) => {
    // console.log(e.target.value, "ggggggg");
    // let selected = [];
    // this.state.selected?.splice(this.state.selected.indexOf(e.target.value), 1);
    // console.log(
    //   this.state?.selected,
    //   "selectedt",
    //   this.props?.marketdata,
    //   "markeData"
    // );
    let deleteSelected = this.state.selected?.filter(
      (i) => i === e.target.value
    );
    // console.log(deleteSelected, "hell", this.state.selected);
    if (deleteSelected && deleteSelected.length) {
      deleteSelected.splice(deleteSelected.indexOf(e.target.value), 1);
      this.setState({ selected: deleteSelected, isdeselect: true });
      // console.log(deleteSelected, "dddd");
    }
    // const target = data;

    // console.log(selected, "kkkkk");
    this.props.handleInputs(
      this.state.selected,
      "/app/Internetmarketing",
      "deselect"
    );
    this.setState({ selecteRedio: true });
  };
  render() {
    const { selectedKeys, selecteRedio, isdeselect, marketing } = this.state;
    // const Selection = {
    //   selectedKeys,
    //   onChange: this.onSelectChange,
    // };
    const { marketdata, isDeslected } = this.props;
    // const history = useHistory();

    // console.log(this.state.selected, "rrrrrrr");
    // console.log(this.props.data);
    return (
      <Row>
        <div  className="progress-mobile">
          <Progress
            percent={this.props.count * 10}
            size="small"
            status="active"
          />
        </div>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col span={24}>
            <div style={{ textAlign: "center", marginTop: "100px" }}>
              <h1 style={{ fontWeight: "bold" }}>
                What type of internet marketing services does your organization
                offer?
              </h1>
            </div>
          </Col>
          <Col span={24}>
            <Form.Item className="Formitem">
              <p style={{ color: "gray", textAlign: "center" }}>
                select 1 or more
              </p>
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24} className ="internet-market ">
            <Form.Item className="Formitem">
              {marketing.map((data) => (
                <div
                  style={{
                    width: "max-content",
                    float: "left",
                    marginLeft: "10px",
                  }}
                >
                  {this.handleRedioMarketing(data)}
                </div>
              ))}
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginReft: "5px" }}>
            <Form.Item className="Formitem">
              {/* <Link to="/app/Platform"> */}
              <Button
                key="1"
                type="primary"
                // loading={this.state.loading}
                // onClick={ this. window.history.back()}
                onClick={() => this.props.handlePrevious("/app/Marketing")}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                  marginBottom: "80px",
                }}
              >
                Previous
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              {/* <Link to="/app/Platform"> */}
              <Button
                key="1"
                type="primary"
                // loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  // marginLeft: "4%",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Internetmarketing;
