import React from "react";
import "antd/dist/antd.min.css";
import "../../index.css";
import LogoTransparency from "../../Images/LogoTransparency.png";
import { Auth } from "aws-amplify";
import { Button, Row, Col } from "antd";
import { Link } from "react-router-dom";

function Header(props) {
  const handleClick = () => {
    localStorage.setItem("path", JSON.stringify(""));
  };

  const handleLogout = async () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    try {
      await Auth.signOut()
        .then((res) => {
          localStorage.clear();
          // this.props.context.setUserLogin(false);
          // localStorage.setItem("leadId",JSON.stringify(""))
          localStorage.removeItem("leadId");
          props.history.push("/");
        })
        .catch((error) => {
          console.log("error signing out: ", error);
          window.location.replace("/");
        });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  let loginId = localStorage.getItem("leadId");

  return (
    <div className="site-page-header-ghost-wrapper">
      {/* <PageHeader
        ghost={false}
       
        extra={[ */}
      <Row style={{ marginTop: "15px", marginBottom: "15px" }}>
        <Col
          xxl={{ span: 10, offset: 1 }}
          xl={{ span: 6, offset: 1 }}
          lg={{ span: 6, offset: 1 }}
          md={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
        >
          <Link to="/">
            <div style={{ float: "left" }}>
              <img
                src={LogoTransparency}
                style={{
                  width: "294px",
                  height: "44px",
                  //== padding:"2px",
                  float: "left",
                }}
                alt="uploaded"
              />
            </div>
          </Link>
        </Col>
        <Col
          xxl={{ span: 11, offset: 0 }}
          xl={{ span: 14, offset: 0 }}
          lg={{ span: 14, offset: 0 }}
          md={{ span: 10, offset: 0 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
          className="show_desktop_header"
        >
          <Link to="/">
            <Button key="1" style={{ fontWeight: "bold", border: "0px" }}>
              Home
            </Button>
          </Link>
          {/* <Button key="2" style={{ fontWeight: "bold", border: "0px" }}>
            Business
          </Button> */}
          <Link to="/app/Media">
            <Button key="3" style={{ fontWeight: "bold", border: "0px" }}>
              Marketing/Media
            </Button>
          </Link>
          <Link to="/app/About">
            <Button key="4" style={{ fontWeight: "bold", border: "0px" }}>
              About Us
            </Button>
          </Link>
          <Link to="/app/Contact">
            <Button key="5" style={{ fontWeight: "bold", border: "0px" }}>
              Contact
            </Button>
          </Link>
          <Link to="/app/register">
            <Button
              key="1"
              onClick={handleClick}
              type="primary"
              style={{
                borderRadius: "50px",
                backgroundColor: "#165874",
                width: "200px",
                justifyContent: "center",
              }}
            >
              Join As a Media Outlet
            </Button>
          </Link>
          {/* {!loginId && (
            <Link to="/app/Leadlogin">
              <div style={{ padding: "2px" }}>
                <Button
                  key="1"
                  onClick={handleClick}
                  type="primary"
                  style={{
                    borderRadius: "50px",
                    backgroundColor: "#165874",
                    width: "150px",
                    justifyContent: "center",
                  }}
                >
                  My Leads
                </Button>
              </div>
            </Link>
          )} */}
          <div>
            {loginId && (
              <Button
                key="1"
                onClick={handleLogout}
                type="primary"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#165874",
                  width: "150px",
                  justifyContent: "center",
                }}
              >
                Logout
              </Button>
            )}
          </div>
        </Col>
        {window.location.pathname === "/" && (
          <Row>
            <Col span={12} style={{ marginLeft: "5px" }}>
              <Link to="/app/register" className="show_mobile">
                <Button
                  key="1"
                  onClick={handleClick}
                  type="primary"
                  className="show_mobile_btn"
                >
                  Join As a Media Outlet
                </Button>
              </Link>
            </Col>

            {/* <Col span={7}>
              {!loginId && (
                <Link to="/app/Leadlogin" className="show_mobile">
                  <div style={{ padding: "2px" }}>
                    <Button
                      key="1"
                      onClick={handleClick}
                      type="primary"
                      className="show_mobile_btn"
                    >
                      My Leads
                    </Button>
                  </div>
                </Link>
              )}
            </Col> */}
          </Row>
        )}
        {window.location.pathname === "/app/Myleads" && (
          <Col span={4} className="show_mobile">
            <div>
              {loginId && (
                <Button
                  key="1"
                  onClick={handleLogout}
                  type="primary"
                  className="show_mobile_btn"
                >
                  Logout
                </Button>
              )}
             
            </div>
          </Col>
        )}
     
      </Row>
    </div>
  );
}

export default Header;
