import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col,  Card } from "antd";
import Blog2image from "../../Images/Blog2.png";
import { Link } from "react-router-dom";

export default class Blog2 extends Component {
  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  render() {
    return (
      <div>
        <Row>
          <Col
            xl={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            xxl={{ span: 20, offset: 2 }}
          >
            <Card
              style={{
                justifyContent: "center",
                width: "100%",
                marginTop: "2vw",
              }}
            >
              <div style={{ padding: "0px,2px,0px,2px" }}>
                <Row>
                  <img
                    src={Blog2image}
                    alt="Blog2image"
                    style={{ width: "40%", height: "40%" }}
                  />
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      Mediapiq – Your Online Media Marketing Matchmaker!
                    </b>
                  </Col>
                </Row>
                <Row className="content-priv" style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    Mediapiq is an online platform matching marketing outlets
                    with businesses and non-for-profit organizations looking to
                    promote their products and services. Our algorithm
                    cross-references the businesses profile information with our
                    database of marketing outlets and makes a match based on
                    different criteria, such as budget, demographics, location
                    and so on.
                  </Col>
                </Row>
                <Row className="content-priv" style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    At the present time, this service is only available in the
                    state of Connecticut, but any business, agency or
                    individuals providing marketing or advertising services in
                    the state can sign up as a media outlet. Sign up is free!
                    The process takes approximately 10 minutes. You will create
                    a media outlet profile by selecting a username and password
                    of your choosing. You will then be asked to complete a form
                    with questions pertinent to your business, such as business
                    name, address, and the types of services you offer. Once you
                    create a profile, you will have access to your leads.
                  </Col>
                </Row>
                <Row className="content-priv" style={{ marginTop: "1vw" }}>
                  <Col span={24}>
                    When the system has identified a business that can benefit
                    from your marketing services, you will be sent a
                    notification via email that a match has been made. The email
                    will include limited information on the business. You can
                    have access to the full business profile after paying a fee
                    via our secured website. The referral fees range from $100 -
                    $200 per referral. The amount charged per referral is based
                    on the amount of ad spend the business has allocated towards
                    promoting their products and services. One of the features
                    that make us different is that you will be provided with the
                    referrals preferred method of communication. There are
                    different ways of approaching prospects that will vary based
                    on their preferences. This is crucial when it comes to
                    reaching out to them as some prospects will have
                    communication preferences, such as email, text, telephone
                    call, or social media connection, that is critical for you
                    to know as it increases your chances of connecting with
                    them.
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col className="content-priv">
                    You may be wondering, why should I pay for a lead? Adding
                    new customers within your sales funnel is crucial for
                    sustainable growth, it is also necessary to focus on the
                    efforts and the means you will use. Mediapiq, can cut down
                    on the time, money, and resources it takes your business to
                    generate a lead by matching you with prospects well-primed
                    to contract your services. Our referrals don’t want to
                    aimlessly throw money into digital platforms, radio, or
                    television and hope for the best. Businesses and non for
                    profits organizations who sign up with us are looking to be
                    matched with the right marketing outlet.
                  </Col>
                </Row>
                <Row style={{ marginTop: "1vw" }}>
                  <Col className="content-priv">
                    We look forward to you joining our network of
                    marketing-media outlets. Feel free to contact us at
                    <Link
                      to="/"
                      onClick={() =>
                        (window.location = "mailto:contact@mediapiq.com")
                      }
                    >
                      contact@mediapiq.com
                    </Link>
                    with any questions. Don’t forget to follow us on{" "}
                    <a
                      href="https://www.linkedin.com/company/mediapiq/?viewAsMember=true"
                      target="_blank"
                      rel="noreferrer" 
                    >
                      Linkedin
                    </a>{" "}
                    or like us on{" "}
                    <a
                      href="https://www.facebook.com/profile.php?id=100079737978276"
                      target="_blank"
                      rel="noreferrer" 
                    >
                      Facebook
                    </a>{" "}
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
