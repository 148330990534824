import { Call, noAuthApiUrl,ApiUrl, ParseGeneralResponse } from "./ApiUtils";

export function doRegistration(userType,data) {
  const url = noAuthApiUrl("add"+"/"+userType);
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}

export function addQuestions(userType,data) {
  const url = noAuthApiUrl("add"+"/"+userType);
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    false
  );
}

export function addEmail(userType,data) {
  const url = ApiUrl(userType);
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}