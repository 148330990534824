import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Progress } from "antd";
import { update } from "../../Api/Apis";
import AppConfig from "../config/AppConfig";
// import "antd/dist/antd.css";
import { Radio } from "antd";
class Range extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],

      loading: false,
      signUp: [],
    };
  }
  componentDidMount = () => {
    this.setState({
      selected:
        this.props.rangedata && this.props.rangedata.length > 0
          ? this.props.rangedata
          : this.props.data2.ranges && this.props.data2.ranges.length > 0
          ? this.props.data2.ranges
          : [],
    });
  };
  handleClick = (e) => {
    // console.log(e.target.value, "ggggggg");
    // let selected = [];
    // this.state.selected?.splice(this.state.selected.indexOf(e.target.value), 1);
    // console.log(
    //   this.state?.selected,
    //   "selectedt",
    //   this.props?.marketdata,
    //   "markeData"
    // );
    let deleteSelected = this.state.selected?.filter(
      (i) => i === e.target.value
    );
    // console.log(deleteSelected, "hell", this.state.selected);
    if (deleteSelected && deleteSelected.length) {
      deleteSelected.splice(deleteSelected.indexOf(e.target.value), 1);
      this.setState({ selected: deleteSelected, isdeselect: true });
      // console.log(deleteSelected, "dddd");
    }
    // const target = data;

    // console.log(selected, "kkkkk");

    this.props.handleInputs(this.state.selected, "/app/Range", "deselect");
    this.setState({ selecteRedio: true });
  };
  onSelectChange = (data) => {
    let selected = this.state.selected.length > 0 ? this.state.selected : [];
    if (selected.includes(data.target.value)) {
      let Index = selected.indexOf(data.target.value);
      selected.splice(Index, 1);
      this.setState({
        selected,
      });
    } else {
      selected.push(data.target.value);
      this.setState({
        selected,
      });
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.selected.length === 0) {
      alert("Please select the range of marketing-advertising!");
      return;
    }
    this.setState({
      loading: true,
    });
    // console.log(this.state.selected);
    this.saveRegistrationData(this.state.selected);
  };
  saveRegistrationData = async (range) => {
    this.props.handleInputs(range, "/app/Completed");
    let data = {
      ranges: range,
    };
    let id =
      JSON.parse(localStorage.getItem("id")) ||
      JSON.parse(localStorage.getItem("userid"));
    // console.log(id);
    const Data = {
      entityData: data,
    };

    update(AppConfig["entityNames"]["registrations"], id, Data).then(
      (result) => {
        if (result.success) {
          // console.log(result.data.data.id);
          localStorage.setItem("user", JSON.stringify(result.data.data.id));
          localStorage.clear();
        }
        if (result.err) {
          alert("your registration failed ");
          // console.log(range);
          this.setState({ loading: false });
        }
      }
    );
  };
  handleRedioIndustry = (data) => {
    let checkValue = this.state.selected.includes(data);
    var selectedValue = checkValue.toString() == "true" ? true : false;

    return (
      // <Radio.Group buttonStyle="solid">
      //   <Radio.Button
      //     value={data}
      //     onChange={this.onSelectChange}
      //     onClick={this.handleClick}
      //     style={{
      //       borderRadius: "50px",
      //       textAlign: "center",
      //       marginLeft: "15px",
      //       backgroundColor: checkValue.toString() == 'true' ? "#1890ff" : "#ffff",
      //       color:checkValue.toString() == 'true' ? "#fff" : "#000",

      //     }}
      //     // checked={selectedValue}
      //   >
      //     {data}
      //   </Radio.Button>
      // </Radio.Group>
      <div>
        <label>
          <input
            name={data}
            type="checkbox"
            value={data}
            onChange={this.onSelectChange}
            onClick={this.handleClick}
            className="visually-hidden"
            checked={checkValue}
          />
          <div
            className="btn"
            style={{ height: "75px", borderRadius: "0px", paddingTop: "20px" }}
          >
            {data}
          </div>
        </label>
      </div>
    );
  };
  render() {
    const { selected } = this.state;
    const Selection = {
      selected,
      onChange: this.onSelectChange,
    };
    const { rangedata } = this.props;
    // console.log(this);
    const Ranges = ["$10K-$25K", "$26K-$35K", "$36K-$50K", "$51K+"];
    return (
      <Row>
        <div className="progress-mobile">
          <Progress
            percent={this.props.count * 10}
            size="small"
            status="active"
          />
        </div>
        <Col span={24}>
          <div style={{ textAlign: "center", marginTop: "100px" }}>
            <h1 style={{ fontWeight: "bold" }}>
              Select the range of marketing/advertising <br />
              dollars your clients must have for you to assist them
              <br />
              with their promotional goals
            </h1>
          </div>
        </Col>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col lg={14} md={14} sm={24} xs={24} style={{textAlign:"center"}}className="service-range">
            <Form.Item className="Formitem">
              {Ranges.map((data) => (
                <div
                  style={{
                    width: "130px",
                    float: "left",
                    marginLeft: "15px",
                  }}
                >
                  {this.handleRedioIndustry(data)}
                </div>
              ))}
              {/* <Radio.Group defaultValue="value" buttonStyle="solid">
                <Radio.Button
                  value="$10k-$20k"
                  onChange={this.onSelectChange}
                  style={{
                    textAlign: "center",
                    marginRight: "10px",
                    width: "130px",
                    paddingTop: "20px",
                    height: "75px",
                  }}
                  checked={this.state.selected.includes("$10k-$20k")}
                >
                  $10k-$20k
                </Radio.Button>
              </Radio.Group>

              <Radio.Group defaultValue="none" buttonStyle="solid">
                <Radio.Button
                  value="$21k-$30k"
                  onChange={this.onSelectChange}
                  style={{
                    textAlign: "center",
                    marginRight: "10px",
                    paddingTop: "20px",
                    width: "130px",
                    height: "75px",
                  }}
                  checked={this.state.selected.includes("$21k-$30k")}
                >
                  $21k-$30k
                </Radio.Button>
              </Radio.Group>

              <Radio.Group defaultValue="none" buttonStyle="solid">
                <Radio.Button
                  value="$31k-$40k"
                  onChange={this.onSelectChange}
                  style={{
                    textAlign: "center",
                    marginRight: "10px",
                    width: "130px",
                    paddingTop: "20px",
                    height: "75px",
                  }}
                  checked={this.state.selected.includes("$31k-$40k")}
                >
                  $31k-$40k
                </Radio.Button>
              </Radio.Group>

              <Radio.Group defaultValue="none" buttonStyle="solid">
                <Radio.Button
                  value="$41k-$50k"
                  onChange={this.onSelectChange}
                  style={{
                    textAlign: "center",
                    marginRight: "10px",
                    width: "130px",
                    paddingTop: "20px",
                    height: "75px",
                  }}
                  checked={this.state.selected.includes("$41k-$50k")}
                >
                  $41k-$50k
                </Radio.Button>
              </Radio.Group>

              <Radio.Group defaultValue="none" buttonStyle="solid">
                <Radio.Button
                  value="$51k+"
                  onChange={this.onSelectChange}
                  style={{
                    textAlign: "center",
                    marginTop: "15px",
                    marginRight: "10px",
                    paddingTop: "20px",
                    width: "130px",
                    height: "75px",
                  }}
                  checked={this.state.selected.includes("$51k+")}
                >
                  $S51k+
                </Radio.Button>
              </Radio.Group> */}
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginTop: "35px" }}>
            <Form.Item className="Formitem">
              <Button
                key="1"
                type="primary"
                loading={this.state.loading}
                onClick={() => this.props.handlePrevious("/app/Agegroup")}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                  marginBottom: "80px",
                }}
              >
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginTop: "35px" }}>
            <Form.Item className="Formitem">
              <Button
                key="1"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  // marginLeft: "4%",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Form.create({ name: "Range" })(Range);
