import React from "react";
import { Button } from "reactstrap";
import "./LoderButton.css";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) =>
  <Button
    className={`LoderButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && <i className="fa fa-spinner fa-lg mt-2"></i>}
    {!isLoading ? text : loadingText}
  </Button>;
