import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Progress } from "antd";
// import "antd/dist/antd.css";
import { Radio } from "antd";
import { update } from "../../Api/Apis";
import AppConfig from "../config/AppConfig";
import { Link } from "react-router-dom";
class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
     
      selectedRowKeys: [],
      selected1: false,
      loading: false,
      selectedBtn:""
    };
  } 
  componentDidMount = () => {
    console.log(this.props,"props");
    this.setState({
      selected:
        this.props.services && this.props.services.length > 0
          ? this.props.services
          : this.props.data2.services && this.props.data2.services.length > 0
          ? this.props.data2.services
          : [],
      selectedBtn:this.props.data2.services && this.props.data2.services.length > 0 ? "yes" : this.props.selectedBtn,
      selected1:this.props.data2.services && this.props.data2.services.length > 0 ? true : false

    });
    if (this.props.data2.services && this.props.data2.services.length > 0) {
      this.setState({
        selected1: true,
      });
    }
  };
  handleClick = (e) => {
    // console.log(e.target.value, "ggggggg");
    //  let selected = [];
    //  this.state.selected?.splice(this.state.selected.indexOf(e.target.value), 1);
    // console.log(
    //   this.state?.selected,
    //   "selectedt",
    //   this.props?.marketdata,
    //   "markeData"
    // );
    let deleteSelected = this.state.selected?.filter(
      (i) => i === e.target.value
    );
    // console.log(deleteSelected, "hell", this.state.selected);
    if (deleteSelected && deleteSelected.length) {
      deleteSelected.splice(deleteSelected.indexOf(e.target.value), 1);
      this.setState({ selected: deleteSelected, isdeselect: true });
      // console.log(deleteSelected, "dddd");
    }
    // const target = data;
    // console.log(selected, "kkkkk");
    this.props.handleInputs(this.state.selected, "/app/Services", "deselect");
    this.setState({ selecteRedio: true });
  };
  
  onSelectChange = (data) => {
    console.log(this.state.selected,"this.state.selected");
    let selected = this.state.selected.length > 0 ? this.state.selected : [];
    if (selected.includes(data.target.value)) {
      let Index = selected.indexOf(data.target.value);
      selected.splice(Index, 1);
      this.setState({
        selected,
         
      });
    } else {
      selected.push(data.target.value);
      this.setState({
        selected,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    console.log(this.state.selectedBtn,"btn...",this.state.selected,"selected yes or no")
    if(this.state.selectedBtn ==="" && this.state.selected.length === 0){
     
      alert("Plese select a option")
    }else{
      this.saveRegistrationData(this.state.selected);
     
    } 
  };
  saveRegistrationData = async (services) => {
    let selected1 = this.state.selectedBtn;
    this.props.handleInputs(services, selected1,"/app/Lifestyle");

    let data = {
      services: services,
      selected1,
    };
    let id =
      JSON.parse(localStorage.getItem("id")) ||
      JSON.parse(localStorage.getItem("userid"));
    // console.log(id);
    const Data = {
      entityData: data,
    };
    update(AppConfig["entityNames"]["registrations"], id, Data).then(
      (result) => {
        if (result.success) {
          console.log(result,"rfesult");
          // console.log(result.data.data.id);
          localStorage.setItem("user", JSON.stringify(result.data.data.id));
          this.props.history.push("/app/Lifestyle");
        }
        if (result.err) {
          alert("your registration failed ");
          this.setState({ loading: false });
        }
      }
    );
  };
  render() {
    const {  selectedBtn } = this.state;
    // console.log(this.props.data2);
    return (
      <Row>
        <div className="progress-mobile">
          <Progress
            percent={this.props.count * 10}
            size="small"
            status="active"
          />
        </div>
        <Col span={24}>
          <div style={{ textAlign: "center", marginTop: "100px" }}>
            <h1 style={{ fontWeight: "bold" }}>
              Do you specialize in ethnic marketing?
            </h1>
          </div>
        </Col>

        <Col xs={{ span: 15, offset: 4 }} sm={{ span: 15, offset: 4 }}>
          <Form.Item className="Formitem">
            <p style={{ color: "gray", textAlign: "center" }}>
              select 1 or more
            </p>
          </Form.Item>
        </Col>
        <Col
          xl={{ span: 18, offset: 6 }}
          xs={{ span: 19, offset: 1 }}
          sm={{ span: 19, offset: 1 }}
          style={{ marginTop: "10px" }}
        >   
          <Form.Item className="Formitem">
            <Radio.Group
            value={selectedBtn==="yes"?"a":selectedBtn==="no"?"b":""}
              // defaultValue="5"
              buttonStyle="solid"
              className="buttonView"
            >
              <Radio.Button
                value="a"
                onClick={()=>this.setState({selectedBtn:"yes",selected:[]})}
                style={{ marginRight: "10px" }}
                checked={this.state.selected?.length > 0 ? true : false}
              >
                Yes
              </Radio.Button>
              <Radio.Button
                value="b"
                onClick={()=>this.setState({selectedBtn:"no",selected:[]})}
                // style={{ marginLeft: "10px" }}
                // checked={this.state.selected =false}
              >   
                No
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <div style={{ textAlign: "center" }}>
            {selectedBtn==="yes" ? (
              <>
                <Col
                  xl={{ span: 16, offset: 3 }}
                  xs={{ span: 19, offset: 1 }}
                  sm={{ span: 19, offset: 1 }}
                >
                  <Form.Item className="Formitem">
                    <div>
                      <label>
                        <input
                          name="White or Caucasian"
                          type="checkbox"
                          value="White or Caucasian"
                          onChange={this.onSelectChange}
                          // onClick={this.handleClick}
                          className="visually-hidden"
                          checked={this.state.selected.includes(
                            "White or Caucasian"
                          )}
                        />
                        <div
                          className="btnServices"
                          // style={{ height: "80px", width:'370px',marginLeft:'39%',borderRadius: "0px" }}
                        >
                          {" "}
                          <b>White or Caucasian</b>
                          <br />
                          <i>British, French, German, etc.</i>
                        </div>
                      </label>
                    </div>
                  </Form.Item>
                </Col>

                <Col
                  xl={{ span: 16, offset: 3 }}
                  xs={{ span: 19, offset: 1 }}
                  sm={{ span: 19, offset: 1 }}
                  style={{ marginTop: "-15px" }}
                >
                  <Form.Item className="Formitem">
                    <div>
                      <label>
                        <input
                          name="Black, African American or Color People"
                          type="checkbox"
                          value="Black, African American or Color People"
                          onChange={this.onSelectChange}
                          // onClick={this.handleClick}
                          className="visually-hidden"
                          checked={this.state.selected.includes(
                            "Black, African American or Color People"
                          )}
                        />
                        <div className="btnServices">
                          {" "}
                          <b>Black, African American or Color People</b>
                          <br />
                          <i>Kenyan, Nigerian, Somalian, Biracial, etc.</i>
                        </div>
                      </label>
                    </div>
                  </Form.Item>
                </Col>

                <Col
                  xl={{ span: 16, offset: 3 }}
                  xs={{ span: 19, offset: 1 }}
                  sm={{ span: 19, offset: 1 }}
                  style={{ marginTop: "-15px" }}
                >
                  <Form.Item className="Formitem">
                    <div>
                      <label>
                        <input
                          name="American Indian or Alaska Native"
                          type="checkbox"
                          value="American Indian or Alaska Native"
                          onChange={this.onSelectChange}
                          // onClick={this.handleClick}
                          className="visually-hidden"
                          checked={this.state.selected.includes(
                            "American Indian or Alaska Native"
                          )}
                        />
                        <div className="btnServices">
                          <b>American Indian or Alaska Native</b>
                          <br />
                          <i>Iroquois, Cherokee, Navajo, Haida, etc.</i>
                        </div>
                      </label>
                    </div>
                  </Form.Item>
                </Col>

                <Col
                  xl={{ span: 16, offset: 3 }}
                  xs={{ span: 19, offset: 1 }}
                  sm={{ span: 19, offset: 1 }}
                  style={{ marginTop: "-15px" }}
                >
                  <Form.Item className="Formitem">
                    <div>
                      <label>
                        <input
                          name="Latino or Hispanic"
                          type="checkbox"
                          value="Latino or Hispanic"
                          onChange={this.onSelectChange}
                          // onClick={this.handleClick}
                          className="visually-hidden"
                          checked={this.state.selected.includes(
                            "Latino or Hispanic"
                          )}
                        />
                        <div className="btnServices">
                          <b> Latino or Hispanic</b>
                          <br />
                          <i>Cuban, Mexican, Puerto Rican, etc.</i>
                        </div>
                      </label>
                    </div>
                  </Form.Item>
                </Col>

                <Col
                  xl={{ span: 16, offset: 3 }}
                  xs={{ span: 19, offset: 1 }}
                  sm={{ span: 19, offset: 1 }}
                  style={{ marginTop: "-15px" }}
                >
                  <Form.Item className="Formitem">
                    <div>
                      <label>
                        <input
                          name="Asian"
                          type="checkbox"
                          value="Asian"
                          onChange={this.onSelectChange}
                          // onClick={this.handleClick}
                          className="visually-hidden"
                          checked={this.state.selected.includes("Asian")}
                        />
                        <div className="btnServices">
                          <b>Asian</b> <br />
                          <i> Japanese, Korean, Chinese, Cambodian, etc. </i>
                        </div>
                      </label>
                    </div>
                  </Form.Item>
                </Col>

                <Col
                  xl={{ span: 16, offset: 3 }}
                  xs={{ span: 19, offset: 1 }}
                  sm={{ span: 19, offset: 1 }}
                  style={{ marginTop: "-15px" }}
                >
                  <Form.Item className="Formitem">
                    <div>
                      <label>
                        <input
                          name="Pacific Islander or Hawaiian"
                          type="checkbox"
                          value="Pacific Islander or Hawaiian"
                          onChange={this.onSelectChange}
                             // onClick={this.handleClick}
                          className="visually-hidden"
                          checked={this.state.selected.includes(
                            "Pacific Islander or Hawaiian"
                          )}
                        />
                        <div className="btnServices">
                          <b>Pacific Islander or Hawaiian</b>
                          <br />
                          <i>Samoan, Tongan, Maori, Tahitian, etc.</i>
                        </div>
                      </label>
                    </div>
                  </Form.Item>
                </Col>
              </>
            ) : (
              ""
            )}
          </div>

          <Col span={12} style={{ marginTop: "60px", marginReft: "5px" }}>
            <Form.Item className="Formitem">
              <Button
                key="1"
                type="primary"
                loading={this.state.loading}
                // onClick={ this. window.history.back()}
                onClick={() => this.props.handlePrevious("/app/Industry")}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                  marginBottom: "80px",
                }}
              >
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginTop: "60px" }}>
            <Form.Item className="Formitem">
              <Button
                key="1"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Services;
