import React from "react";
import { Card, Row, Col,Button } from "antd";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  Elements,
} from "react-stripe-elements";
import AppConfig from "../config/AppConfig";
import { sendPayment } from "../../Api/Apis";

const handleChange = (change) => {
  console.log("[change]", change);
};
// const handleClick = () => {
//   // console.log("[click]");
// };

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4",
        },
        padding,
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

class _SplitForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      
    };
  }
  handleSubmit = (ev) => {
    this.setState({
      loading: true,
    });
    ev.preventDefault();
    if (this.props && this.props.stripe) {
      this.props.stripe.createToken().then((data) => {
        if (!("error" in data)) {
          // console.log(data, "dat");
          this.savePayment(data);
        } else {
          if (data["error"]["decline_code"] === "live_mode_test_card") {
            alert(
              "You are in live mode don't use test card, please use live card only."
            );
          }
          this.setState({
            loading: false,
          });
        }
      });
    } else {
      this.setState({
        loading: false,
      });
      // console.log("Stripe.js hasn't loaded yet.");
    }
  };

  savePayment = async (stripeToken) => {
    let id = JSON.parse(localStorage.getItem("Step8"));
    // console.log("userId===>", id);
    const data = {
      entityData: {
        tokenId: stripeToken.token.id,
        userId: id,
        amount: this.props.amount,
        desc: "testing stripe",
      },
    };
    // console.log("dataaaaa", data);
    sendPayment(AppConfig["entityNames"]["payment"], data).then((result) => {
      // console.log("result====>", result);
      if (result.success) {
        this.setState({
          loading: false,
        });
        alert("Payment successful");
      }else{
        alert("Payment not successful");
        this.setState({
          loading: false,
        });
      }
      // console.log(this.props, "prop");
      // this.props.gotoMatch()
      // this.props.match();
      if (this.props.formData) {
        this.props.match();
        
      }
    });
  };
  render() {
    // const { formData } = this.props;

    // console.log(this?.props, "props");
    return (
      <Row justify="center">
        <Col span={14} offset={4}>
          <Card
            bodyStyle={{ padding: "20px" }}
            style={{
              backgroundColor: "#F0F2F5",
              borderRadius: "10px",
              boxShadow:
                "0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)",
            }}
          >
            <Col
              span={20}
              offset={4}
              sm={{ span: 20, offset: 2 }}
              xs={{ span: 20, offset: 2 }}
            >
              <form onSubmit={this.handleSubmit} className="pay-form">
                <label className="pay-label">
                  Card number
                  <CardNumberElement
                    onChange={handleChange}
                    {...createOptions(this.props.fontSize)}
                  />
                </label>
                <label className="pay-label">
                  Expiration date
                  <CardExpiryElement
                    onChange={handleChange}
                    {...createOptions(this.props.fontSize)}
                  />
                </label>
                <label className="pay-label">
                  CVC
                  <CardCVCElement
                    onChange={handleChange}
                    {...createOptions(this.props.fontSize)}
                  />
                </label>
                {this.state.loading ? (
                  <Button
                    className="pay-button"
                    loading={this.state.loading}
                  >
                    Loading
                  </Button>
                ) : (
                  <button
                    className="pay-button"
                    type="submit"
                    loading={this.state.loading}
                  >
                    Pay
                  </button>
                )}
              </form>
            </Col>
          </Card>
        </Col>
      </Row>
    );
  }
}
const SplitForm = injectStripe(_SplitForm);
export default class PaymentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elementFontSize: window.innerWidth < 450 ? "14px" : "18px",
      total: this.props.amount,
    };
    window.addEventListener("resize", () => {
      if (window.innerWidth < 450 && this.state.elementFontSize !== "14px") {
        this.setState({ elementFontSize: "14px" });
      } else if (
        window.innerWidth >= 450 &&
        this.state.elementFontSize !== "18px"
      ) {
        this.setState({ elementFontSize: "18px" });
      }
    });
  }

  render() {
    // console.log(this.props, "amount");
    const { elementFontSize } = this.state;
    return (
      <div className="Checkout">
        {/* <h1 className="pay-head">Available Elements</h1> */}

        <Elements>
          <SplitForm
            fontSize={elementFontSize}
            amount={this.props.amount}
            match={this.props.match}
            formData={this.props.formData}
          />
        </Elements>
      </div>
    );
  }
}
