import React, { Component } from 'react'
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button } from "antd";

export default class Stepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteRedio: true,
      isdeselect: false,
      agegroup: [
        "11 and under",
        "12-18",
        "19-24",
        "25-35",
        "45-54",
        "65-74", 
        "74+",
     
      ],
    };
  }
  handleClick = (e) => {
    let agegroup = this.props?.formData?.agegroup;
    let value = e.target.value;
    if (agegroup) {
      if (agegroup.indexOf(value) > -1) {
        this.props.setFieldsData(7, "agegroup", value);
      }
    }
  };
  onSelectChange = (e) => {
    this.props.setFieldsData(7, "agegroup", e.target.value);
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.props.formData.agegroup === undefined || this.props.formData.agegroup.length === 0) {
      alert("Please select your options!");
      return;
    }
    this.props.updateData({"agegroup": this.props.formData.agegroup});
  };
  handleRedioMarketing = (data) => {
    let checkValue = this.props.formData?.agegroup?.includes(data);
    return (
      <div>
        <label>
          <input
            name={data}
            type="checkbox"
            value={data}
            onChange={this.onSelectChange}
            onClick={this.handleClick}
            className="visually-hidden"
            checked={checkValue}
          />
          <div className="btn">{data}</div>
        </label>
      </div>
    );
  };
  render() {
    return (
      <div>
        <Row>
      <Col span={24}>
        <div style={{ textAlign: "center", marginTop: "100px" }}>
          <h1 style={{ fontWeight: "bold" }}>
          What age group are you targeting?
          </h1>
        </div>
      </Col>
      <Col span={24}>
        <Form.Item className="Formitem">
          <p style={{ color: "gray", textAlign: "center" }}>
            select 1 or more
          </p>
        </Form.Item>
      </Col>
      <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
        <Col lg={12} md={10} sm={24} xs={24} className="service-demo">
          <Form.Item className="Formitem">
            {this.state.agegroup?.map((data) => (
              <div
                style={{
                  width: "max-content",
                  float: "left",
                  marginLeft: "10px",
                }}
              >
                {this.handleRedioMarketing(data)}
              </div>
            ))}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className="Formitem">
            <Button
              key="Previous"
              type="primary"
              onClick={() => this.props.back()}
              style={{
                borderRadius: "50px",
                backgroundColor: "#dce7f0",
                color: "#004b84",
                width: "150px",
                border: "none",
                marginRight: "10px",
                float: "right",
                fontWeight: "bold",
                height: "40px",
              }}
            >
              Previous
            </Button>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className="Formitem">
            <Button
              key="next"
              type="primary"
              loading={this.state.loading}
              htmlType="submit"
              className="Submit"
              style={{
                borderRadius: "50px",
                backgroundColor: "#dce7f0",
                color: "#004b84",
                width: "150px",
                border: "none",
                // marginLeft: "4%",
                fontWeight: "bold",
                height: "40px",
              }}
            >
              Next
            </Button>
          </Form.Item>
        </Col>
      </Form>
    </Row></div>
    )
  }
}
