
import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button } from "antd";
// import { Radio } from "antd";

class Stepservice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteRedio: true,
      isdeselect: false,
      service: [
        "Fairfield",
        "Hartford",
        "New Haven",
        "Litchfield",
        "Windham",
        "Tolland",
        "New London",
        "Middlesex",
      ],
      // loading:false
    };
  }

  handleClick = (e) => {
    let service = this.props?.formData?.service;
    let value = e.target.value
    if(service) {
      if(service.indexOf(value) > -1) {
        this.props.setFieldsData(1, 'service', value);
      }
    }
  };

  onSelectChange = (e) => {
    this.props.setFieldsData(1, 'service', e.target.value);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // this.setState({
    //   loading:true
    // })
    if (this.props.formData.service === undefined || this.props.formData.service.length === 0) {
      alert("Please select your options!");
      return;
    }
    this.props.updateData({"service": this.props.formData.service});
  };

  handleRedioMarketing = (data) => {
    let checkValue = this.props.formData?.service?.includes(data);
    return (
      <div>
      <label>
        <input
          name={data}
          type="checkbox"
          value={data}
          onChange={this.onSelectChange}
          onClick={this.handleClick}
          className="visually-hidden"
          checked={checkValue}
        />
        <div className="btn">{data}</div>
      </label>
    </div>
    );
  };

  render() {
    return (
      <Row>
        <Col span={24}>
          <div style={{ textAlign: "center", marginTop: "100px" }}>
            <h1 style={{ fontWeight: "bold" }}>
            Service Areas
            </h1>
          </div>
        </Col>

        <Col span={24}>
          <Form.Item className="Formitem">
            <p style={{ color: "gray", textAlign: "center" }}>
              select 1 or more
            </p>
          </Form.Item>
        </Col>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col lg={12} md={12} sm={24} xs={24} className="service-area">
          <Form.Item className="Formitem">
          {this.state.service?.map((data) =>
                <div
                  style={{
                    width: "max-content",
                    float: "left",
                    marginLeft: "10px",
                  }}
                >
                  {this.handleRedioMarketing(data)}
                </div>
              )}
            </Form.Item>
         
          </Col>

          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="Previous"
                type="primary"
                onClick={() => this.props.back()}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="next"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Stepservice;
