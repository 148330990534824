import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Input } from "antd";
import { Radio } from "antd";

class Step5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteRedio: true,
      selected1: false,
      isdeselect: false,
      selected: "",
      value: "",
      yes: "",
      Step5: [
        "Business Cards",
        "Brochures",
        "Pamphlets",
        "Website",
        "Landing Page",
        "Facebook Account",
        "Instagram Account",
        "Youtube Account",
        "Not Applicable",
      ],
      Step6: [
        "Business Cards",
        "Brochures",
        "Pamphlets",
        "Website",
        "Landing Page",
        "Facebook Account",
        "Instagram Account",
        "Youtube Account",
      ],
      show: false,
    };
  }
  onSelectChange2 = (data) => {
    let selected = this.state.selected.length > 0 ? this.state.selected : [];
    if (selected.includes(data.target.value)) {
      let Index = selected.indexOf(data.target.value);
      selected.splice(Index, 1);
      this.setState({
        selected,
      });
    } else {
      selected.push(data.target.value);
      this.setState({
        selected,
      });
    }

    if (this.props.formData.notApplicableText) {
      this.props.updateData(
        {
          notApplicableText: false,
          creatingBussiness: [],
        },
        "yes"
      );
    }
  };
  handleClick = (e) => {
    let Step5 = this.props.formData.currentlybussiness;
    let value = e.target.value;
    if (Step5) {
      if (Step5.indexOf(value) > -1) {
        this.props.setFieldsData(5, "currentlybussiness", value);
      }
    }
  };

  onSelectChange = (e) => {
    if (e.target.value === "Not Applicable") {
      this.props.setFieldsData(5, "currentlybussiness", e.target.value);
      this.setState({
        value: e.target.value,
      });
      this.props.updateData(
        {
          currentlybussiness: ["Not Applicable"],
        },
        "yes"
      );
    } else if (
      this.props?.formData?.currentlybussiness?.length > 0 &&
      this.props?.formData?.currentlybussiness.includes("Not Applicable")
    ) {
      this.props.updateData(
        {
          currentlybussiness: [e.target.value],
        },
        "yes"
      );
      this.props.setFieldsData(5, "currentlybussiness", e.target.value);
      this.setState({
        value: e.target.value,
      });
    } else {
      this.props.setFieldsData(5, "currentlybussiness", e.target.value);
      this.setState({
        value: e.target.value,
      });
    }
  };

  handleNotapplicableClick = (e) => {
    let Step6 = this.props.formData.creatingBussiness;
    let value = e.target.value;
    if (Step6) {
      if (Step6.indexOf(value) > -1) {
        this.props.setFieldsData(5, "creatingBussiness", value);
      }
    }
  };

  onNotapplicableSelect = (e) => {
    this.props.setFieldsData(5, "creatingBussiness", e.target.value);
    this.setState({
      value: e.target.value,
    });
  };

  handleNotApplicable = (e) => {
    this.props.setFieldsData(5, "notApplicableText", e.target.value);
    this.props.updateData(
      {
        currentlybussiness: [],
      },
      "yes"
    );
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          agreement: values.agreement,
        });
        // let data = {
        //   // previous: this.state.selected1,
        //   currentlybussiness: values["currentlybussiness"],
        //   reversed: values["reversed"],
        //   creatingBussiness: values["creatingBussiness"],
        //   notApplicableText: values["notApplicableText"],
        // };

        if (
          this.props.formData.currentlybussiness === undefined ||
          this.props.formData.currentlybussiness.length === 0
        ) {
          if (
            this.props.formData.creatingBussiness === undefined ||
            this.props.formData.creatingBussiness.length === 0
          ) {
            alert("Please select your options!");
            return;
          }
        }
        this.props.updateData({
          currentlybussiness: this.props.formData.currentlybussiness,
          reversed: values["reversed"],
          creatingBussiness: this.props.formData.creatingBussiness,
          notApplicableText: values["notApplicableText"],
          previousyes: !this.props.formData.previousno ? true : false,
          previousno: !this.props.formData.previousyes ? true : false,
        });
      }
    });
  };
  handleClick2 = () => {
    const { formData } = this.props;
    if (formData.id) {
      let data = {
        previousyes: true,
        previousno: false,
        currentlybussiness: this.props.formData.currentlybussiness,
      };
      this.props.updateData(data, "yes");
    } else {
      formData["previousyes"] = true;
      formData["previousno"] = false;
      this.props.doRegister(formData, "yes");
    }
    this.setState({
      selected1: true,
    });
  };
  handleClick3 = () => {
    const { formData } = this.props;
    if (formData.id) {
      let data = {
        previousyes: false,
        previousno: true,
        currentlybussiness: this.props.formData.currentlybussiness,
      };
      this.props.updateData(data, "yes");
    } else {
      formData["previousyes"] = false;
      formData["previousno"] = true;
      this.props.doRegister(formData, "yes");
    }
    this.setState({
      selected1: false,
    });
  };
  handleRedioMarketing = (data) => {
    let checkValue = this.props.formData.currentlybussiness?.includes(data);
    return (
      <div>
        <label>
          <input
            key={data}
            type="checkbox"
            value={data}
            onChange={this.onSelectChange}
            onClick={this.handleClick}
            className="visually-hidden"
            checked={checkValue}
          />
          <div className="btn">{data}</div>
        </label>
      </div>
    );
  };
  handleRedioMarketing1 = (data) => {
    let checkValue = this.props.formData.creatingBussiness?.includes(data);
    return (
      <div>
        <label>
          <input
            key={data}
            type="checkbox"
            value={data}
            onChange={this.onNotapplicableSelect}
            onClick={this.handleNotapplicableClick}
            className="visually-hidden"
            checked={checkValue}
          />
          <div className="btn">{data}</div>
        </label>
      </div>
    );
  };
  render() {
    // const { Option } = Select;
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const { formData } = this.props;
    console.log(formData.previousyes);

    return (
      <Row>
        <Col span={24}>
          <div style={{ textAlign: "center", marginTop: "100px" }}>
            <h1 style={{ fontWeight: "bold" }}>
              Please mark the items that you currently have available for your
              business
            </h1>
          </div>
        </Col>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col lg={12} md={12} sm={24} xs={24} className="service-step3">
            <Form.Item className="Formitem" name="1">
              {this.state.Step5?.map((data) => (
                <div>
                  <div
                    style={{
                      width: "max-content",
                      float: "left",
                      marginLeft: "10px",
                    }}
                    // onClick={() => this.toggleListItem(data)}
                  >
                    {this.handleRedioMarketing(data)}
                  </div>
                </div>
              ))}
            </Form.Item>
          </Col>

          {this.props.formData?.currentlybussiness?.length > 0 &&
          this.props.formData.currentlybussiness?.includes("Not Applicable") ? (
            <div>
              <Col span={24}>
                <div style={{ textAlign: "center", marginTop: "100px" }}>
                  <h1 style={{ fontWeight: "bold" }}>
                    Select the items that you would like assistance in
                    designing/creating for your business
                  </h1>
                </div>
              </Col>
              <Col
                lg={12}
                md={12}
                sm={24}
                xs={24}
                style={{ marginLeft: "27%" }}
              >
                <Form.Item className="Formitem" name="1">
                  {this.state.Step6?.map((data) => (
                    <div
                      style={{
                        width: "max-content",
                        float: "left",
                        marginLeft: "10px",
                      }}
                    >
                      {this.handleRedioMarketing1(data)}
                    </div>
                  ))}
                </Form.Item>
              </Col>
            </div>
          ) : this.props.formData?.currentlybussiness?.length > 0 ? (
            !this.props.formData.currentlybussiness?.filter((eachOne) =>
              this.state.Step5.includes(eachOne)
            ) ? (
              ""
            ) : (
              this.props.formData?.currentlybussiness?.length > 0 && (
                <>
                  <Col span={24}>
                    <Form.Item className="Formitem">
                      <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
                        Are the promotional items that you selected in the
                        previous question updated?
                      </h2>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Col span={24}>
                      <center>
                    <Form.Item className="Formitem">
                      <Radio.Group
                        defaultValue="5"
                        buttonStyle="solid"
                        // style={{ marginLeft: "46%" }}
                      >
                        <Radio.Button
                          value="a"
                          onClick={this.handleClick2}
                          style={{ marginRight: "10px" }}
                          checked={formData?.previousyes ? true : false}
                        >
                          Yes
                        </Radio.Button>
                        <Radio.Button
                          value="b"
                          onClick={this.handleClick3}
                          style={{ marginLeft: "10px" }}
                          checked={formData?.previousno ? true : false}

                          // checked={this.state.selected =false}
                        >
                          No
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                    </center>
                  </Col>
                  
                    {formData?.previousno ? (
                      <Col span={24}>
                        <center>
                      <Form.Item className="Formitem">
                        <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
                          If not, when was the last time you revised or updated
                          them?
                        </h2>
                       
                        <Form.Item className="Formitem">
                          {getFieldDecorator("reversed", {
                            rules: [
                              {
                                required: true,
                                message: "Please input !",
                              },
                            ],
                            initialValue: formData.reversed,
                          })(
                           
                           
                              <TextArea
                              rows="5" cols="50"
                                className="text_areabox"
                                style={{height:'80px'}}
                                placeholder="Please briefly explain"
                              />
                            
                          )}
                        </Form.Item>
                        
                      </Form.Item>
                      </center>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Col>
                </>
              )
            )
          ) : (
            ""
          )}

          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="Previous"
                type="primary"
                onClick={() => this.props.back()}
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                  marginBottom: "80%",
                }}
              >
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                key="next"
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  // marginLeft: "4%",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
              {/* </Link> */}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}
export default Form.create({ name: "Step5" })(Step5);
