import React, { Component } from "react";
import { Form, Input, Button, notification, Col, Row } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import LogoTransparency from "../../Images/LogoTransparency.png";
// import LoaderButton from "../../../containers/DefaultLayout/LoaderButton";
// import BackgroundImage from "../../../components/BackgroundImage";
import { Auth } from "aws-amplify";
import LoderButton from "../Forgotpassword/LoderButton";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      console.log(this.state.email);
      Auth.forgotPassword(this.state.email)
        .then((data) => {
          console.log(data);
          this.props.history.push("/app/CofirmForgotPassword");
        })
        .catch((err) => {
          console.log(err);
          if (err.code === "UserNotFoundException") {
            alert("User not found!");
            this.setState({ isLoading: false });
          }
        });
      localStorage.setItem("forgotEmail", this.state.email);
    } catch (e) {
      this.setState({ isLoading: false });

      alert(e.message);
    }
  };

  render() {
    return (
      <div
        className="admin-login-form"
        style={{ background: "#eff1f3 0% 0% no-repeat padding-box" }}
      >
        <div className="login-fields" style={{ height: "260px" }}>
          <Form onSubmit={this.handleSubmit}>
            <div style={{ textAlign: "center", marginBottom: "24px" }}>
              <img
                src={LogoTransparency}
                alt="LogoTransparency"
                style={{ width: "100%" }}
              />
            </div>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input email",
                },
                {
                  type: "email",
                  message: "The input is not valid Corporate Email",
                },
              ]}
              //   initialValue={proConatct.town ? proConatct.town : ""}
            >
              <Input
                prefix={<UserOutlined />}
                type="text"
                placeholder="Email"
                id="email"
                value={this.state.email}
                onChange={this.handleChange}
                required
              />
             <b> Verification code will be sent your registered Email ID</b>
            </Form.Item>
            <Row>
              <Col span={12}>
                <LoderButton
                  block
                  bssize="large"
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Submit"
                  loadingText="Sending code.."
                />
                {/*<input type="Submit" color="primary" className="px-4 primary" value="Login" />*/}
              </Col>

              <Col span={12} className="text-right">
                <a href="/app/Leadlogin">Back</a>
              </Col>
            </Row>
          </Form>
        </div>
        {/* <BackgroundImage /> */}
      </div>
    );
  }
}

export default ForgotPassword;
