import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Input, Button, Card } from "antd";
// import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import ".././index.css";

class Privacypolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      revenue: "",
      business: "",
    };
  }
  render() {
    return (
      <div>
        <div style={{ textAlign: "center", color: "black" }}>
          <h1 className="priv-hed">
            <b> Privacy Policy</b>
          </h1>
        </div>
        <Row>
          <Col
            xl={{ span: 20, offset: 2 }}
            lg={{ span: 20, offset: 2 }}
            md={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
            xxl={{ span: 20, offset: 2 }}
          >
            <Card
              style={{
                justifyContent: "center",
                width: "100%",
                marginTop: "2vw",
              }}
            >
              <div style={{ padding: "20px", marginTop: "1vw" }}>
                <span className="content-priv">
                  MediaPiq ("Website") is governed by the following Privacy
                  Policy.{" "}
                </span>
                <span className="content-priv">
                  We respect your privacy and are committed to protecting it.
                  The purpose of this Privacy Policy is to inform you what
                  personally identifiable information we may collect and how it
                  may be used. This statement only applies to this Website.{" "}
                </span>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>What Information Do We Collect And How Is It Used?</b>
                </h1>
                <ul>
                  <li>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      Information You Voluntarily Submit To The Website:
                    </b>
                    <span className="content-priv">
                      We may collect personal information from you such as your
                      name or email address. For example, you may voluntarily
                      submit information to the Website by leaving a comment,
                      subscribing to a newsletter, or submitting a contact form.
                      In addition, we may ask you to create a user profile,
                      which would allow you to create a username and password.
                      We will store the username, but your password will not be
                      visible in our records.
                    </span>
                  </li>
                  <li>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      Information We Collect From Others:
                    </b>{" "}
                    <span className="content-priv">
                      We may receive information about you from other sources.
                      For example, if you use a third-party software through the
                      site, they may transfer information to us for fulfillment.{" "}
                    </span>
                  </li>
                  <li>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      Automatically-Collected Information:
                    </b>{" "}
                    <span className="content-priv">
                      We automatically collect certain information about you and
                      the device with which you access the Website. For example,
                      when you use the Website, we will log your IP address,
                      operating system type, browser type, referring website,
                      pages you viewed, and the dates/times when you accessed
                      the Website. We may also collect information about actions
                      you take when using the Website, such as links clicked
                    </span>
                  </li>
                  <li>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      Cookies:
                    </b>{" "}
                    <span className="content-priv">
                      We may log information using cookies, which are small data
                      files stored on your browser by the Website. We may use
                      both session cookies, which expire when you close your
                      browser, and persistent cookies, which stay on your
                      browser until deleted, to provide you with a more
                      personalized experience on the Website.
                    </span>
                  </li>
                </ul>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>How Your Inormation May Be Used</b>
                </h1>
                <h1 className="content-priv">
                  We may use the information collected in the following ways:{" "}
                </h1>
                <ul>
                  <li className="content-priv">
                    To operate and maintain the Website;{" "}
                  </li>
                  <li className="content-priv">
                    To create your account, identify you as a user of the
                    Website, and customize the Website for your account;{" "}
                  </li>
                  <li className="content-priv">
                    To send you promotional information, such as newsletters.
                    Each email promotion will provide information on how to
                    opt-out of future mailings;{" "}
                  </li>
                  <li className="content-priv">
                    To send you administrative communications, such as
                    administrative emails, confirmation emails, technical
                    notices, updates on policies, or security alerts;{" "}
                  </li>
                  <li className="content-priv">
                    To respond to your comments or inquiries;{" "}
                  </li>
                  <li className="content-priv">
                    To provide you with user support;{" "}
                  </li>
                  <li className="content-priv">
                    To track and measure advertising on the Website;{" "}
                  </li>
                  <li className="content-priv">
                    To process payment for purchases you make through the
                    Website; or,
                  </li>
                  <li className="content-priv">
                    To protect, investigate, and deter against unauthorized or
                    illegal activity.
                  </li>
                </ul>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Third-party Use Of Personal Information</b>
                </h1>
                <h1 className="content-priv">
                  We may share your information with third parties when you
                  explicitly authorize us to share your information.
                </h1>

                <h1 className="content-priv">
                  Additionally, the Website may use third-party service
                  providers to service various aspects of the Website. Each
                  third-party service provider’s use of your personal
                  information is dictated by their respective privacy policies.
                  The Website currently uses the following third-party service
                  providers:{" "}
                </h1>
                <ul>
                  <li>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      Google Analytics –{" "}
                    </b>
                    <span className="content-priv">
                      This service tracks Website usage and provides information
                      such as referring websites and user actions on the
                      Website. Google Analytics may capture your IP address, but
                      no other personal information is captured by Google
                      Analytics.
                    </span>
                  </li>
                  <li>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      Stripe –
                    </b>{" "}
                    <span className="content-priv">
                      This service is used to process payments for our products.
                      At no time is your banking information passed to the
                      Website. We receive only information used for order
                      fulfillment.
                    </span>
                  </li>
                  <li>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      Sendgrid–{" "}
                    </b>{" "}
                    <span className="content-priv">
                      This service is used for delivery of email updates and
                      newsletters. We store your name and email address for
                      purposes of delivering such communications. EMAIL SERVICE
                      PROVIDER’S NAME will also track information regarding your
                      interaction with our emails, such as whether they have
                      been opened or you have clicked on a particular link.
                    </span>
                  </li>
                </ul>
                <h1 className="content-priv">
                  At this time, your personal information is not shared with any
                  other third-party applications. This list may be amended from
                  time to time in our sole discretion.{" "}
                </h1>
                <h1 className="content-priv">
                  Except when required by law, we will not sell, distribute, or
                  reveal your email addresses or other personal information
                  without your consent; however, we may disclose or transfer
                  personal information collected through the Website to third
                  parties who acquire all or a portion of our business, which
                  may be the result of a merger, consolidation, or purchase of
                  all or a portion of our assets, or in connection with any
                  bankruptcy or reorganization proceeding brought by or against
                  us.
                </h1>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Anonymous Data</b>
                </h1>
                <p className="content-priv">
                  From time to time, we may use anonymous data, which does not
                  identify you alone, or when combined with data from other
                  parties. This type of anonymous data may be provided to other
                  parties for marketing, advertising, or other uses. Examples of
                  this anonymous data may include analytics or information
                  collected from cookies.{" "}
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Cookies</b>
                </h1>
                <p className="content-priv">
                  The Website uses cookies to store visitors’
                  preferences, record user-specific information on what pages
                  users access or visit, ensure that visitors are not repeatedly
                  sent the same banner ads, customize Website content based on
                  visitors’ browser type or other information that the visitor
                  sends. Cookies may also be used by third-party services, such
                  as Google Analytics, as described herein.{" "}
                </p>
                <p className="content-priv">
                  Users may, at any time, prevent the setting of cookies, by the
                  Website, by using a corresponding setting of your internet
                  browser and may thus permanently deny the setting of cookies.
                  Furthermore, already set cookies may be deleted at any time
                  via an Internet browser or other software programs. This is
                  possible in all popular Internet browsers. However, if users
                  deactivate the setting of cookies in your Internet browser,
                  not all functions of our Website may be entirely usable.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Advertising</b>
                </h1>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Retargeting Ads</b>
                </h1>
                <p className="content-priv">
                  From time to time, the Website may engage in remarketing
                  efforts with third-party companies, such as Google, Pinterest,
                  Facebook, or Instagram, in order to market the Website. These
                  companies use cookies to serve ads based on someone's past
                  visits to the Website. This means, after visiting the Website,
                  you may see an ad for our services. However, your personally
                  identifiable information is not used by any remarketing
                  service other than to present you offers from the Website. We
                  use the following third-party service providers for
                  remarketing:{" "}
                </p>
                <h3 className="content-priv">
                  Facebook: Opt-out of Facebook remarketing here
                </h3>
                <h3 className="content-priv">
                  Google: Opt-out of Google remarketing here
                </h3>
                <h3 className="content-priv">
                  Pinterest: Opt-out of Pinterest remarketing here
                </h3>

                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Newsletters</b>
                </h1>
                <p className="content-priv">
                  On the Website, you may subscribe to our newsletter, which may
                  be used for advertising purposes. All newsletters sent may
                  contain tracking pixels. The pixel is embedded in emails and
                  allows an analysis of the success of online marketing
                  campaigns. Because of these tracking pixels, we may see if and
                  when you open an email and which links within the email you
                  click. Also, this allows the Website to adapt the content of
                  future newsletters to the interests of the user. This behavior
                  will not be passed on to third parties.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b> Gdpr - Rights Related To Your Personal Information</b>.
                </h1>
                <p className="content-priv" style={{ fontSize: "20px" }}>
                  If you are within the European Union, you are entitled to
                  certain information and have certain rights under the General
                  Data Protection Regulation (“GDPR”). Throughout this policy,
                  we have disclosed what information we collect and how it is
                  used. Under GDPR, you also have the following rights:{" "}
                </p>
                <ul>
                  <li>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      Right To Opt-out –
                    </b>
                    <span className="content-priv">
                      You may opt-out of future email communications by
                      following the unsubscribe links in our emails. You may
                      also contact us at the email below to be removed from our
                      mailing list.
                    </span>
                  </li>
                  <li>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      Right To Access –
                    </b>{" "}
                    <span className="content-priv">
                      You may access the personal information we have about you
                      by submitting a request.
                    </span>
                  </li>
                  <li>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      Right To Amend –
                    </b>{" "}
                    <span className="content-priv">
                      You may contact us to amend or update your personal
                      information.
                    </span>
                  </li>
                  <li>
                    <b className="priv-main" style={{ fontSize: "20px" }}>
                      Right To Be Forgotten –
                    </b>{" "}
                    <span className="content-priv">
                      In certain situations, you may request that we erase or
                      forget your personal data.
                    </span>
                  </li>
                </ul>
                <h2 className="content-priv">
                  You may exercise any of these rights by emailing{" "}
                  <Link
                    to="/"
                    onClick={() =>
                      (window.location = "mailto:contact@mediapiq.com")
                    }
                  >
                    contact@mediapiq.com
                  </Link>
                </h2>
                <h2 className="content-priv">
                  Please note that we may need to retain certain information for
                  recordkeeping purposes or to complete transactions, or when
                  required by law. Unless you exercise such rights, we reserve
                  the right to retain your data.{" "}
                </h2>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>Sensitive Personal Information</b>
                </h1>
                <p className="content-priv">
                  At no time should you submit sensitive personal information to
                  the Website. This includes your social security number,
                  information regarding race or ethnic origin, political
                  opinions, religious beliefs, health information, criminal
                  background, or trade union memberships. If you elect to submit
                  such information to us, it will be subject to this Privacy
                  Policy.{" "}
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  {" "}
                  <b>Children's Information</b>
                </h1>
                <p className="content-priv">
                  The Website does not knowingly collect any personally
                  identifiable information from children under the age of 16. If
                  a parent or guardian believes that the Website has personally
                  identifiable information of a child under the age of 16 in its
                  database, please contact us immediately at{" "}
                  <Link
                    to="/"
                    onClick={() =>
                      (window.location = "mailto:contact@mediapiq.com")
                    }
                  >
                    contact@mediapiq.com
                  </Link>{" "}
                  and we will use our best efforts to promptly remove such
                  information from our records.
                </p>
                <h1 className="priv-hed" style={{ fontSize: "25px" }}>
                  <b>CONTACT INFORMATION</b>
                </h1>
                <p className="content-priv">
                  At any time, please contact us at{" "}
                  <Link
                    to="/"
                    onClick={() =>
                      (window.location = "mailto:contact@mediapiq.com")
                    }
                  >
                    contact@mediapiq.com
                  </Link>{" "}
                  for questions related to this Privacy Policy.{" "}
                </p>
                <p className="content-priv">Last updated: March 22, 2022</p>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Form.create({ name: "Privacypolicy" })(Privacypolicy);
